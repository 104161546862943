import styled from 'styled-components';

// COMPONENTS
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React from 'react';

export type InputProps = {
  maxLength?: number | null;
  typeInput?: string;
  minInput?: number;
  maxInput?: number;
  endAdornment?: React.ReactNode;
  inputLabelProps?: object;
};

export type TextFieldProps = MuiTextFieldProps &
  InputProps & {
    onValueChange?: (value: unknown) => void;
  };

const TextField = ({
  variant = 'outlined',
  maxLength = 100,
  typeInput = 'text',
  minInput,
  maxInput,
  endAdornment,
  inputLabelProps,
  onChange,
  onValueChange,
  ...restProps
}: TextFieldProps) => {
  const handleChange: MuiTextFieldProps['onChange'] =
    onChange || (onValueChange ? e => onValueChange(e.target.value) : undefined);

  return (
    <StyledTextField
      inputProps={{
        maxLength,
        type: typeInput,
        min: minInput,
        max: maxInput,
      }}
      InputProps={{
        endAdornment,
      }}
      InputLabelProps={inputLabelProps}
      variant={variant}
      onChange={handleChange}
      {...restProps}
    />
  );
};

const StyledTextField = styled(MuiTextField)`
  label {
    font-size: 13px;
    color: #aca8b7;
    &.MuiInputLabel-outlined {
      top: -3px;
    }
    &.MuiInputLabel-shrink {
      top: 0;
      color: #aca8b7;
    }
  }

  fieldset {
    border-color: #d4d2db !important;
    border-width: 1px !important;
    border-radius: 0 !important ;
  }

  .MuiOutlinedInput-input {
    padding: 10px 14px !important;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .Mui-disabled {
    background-color: rgb(220 220 220 / 20%) !important;
  }

  .Mui-error {
    color: #d32f2f !important;
    .MuiOutlinedInput-notchedOutline {
      border-color: #d32f2f !important;
    }
  }
`;

export default TextField;
