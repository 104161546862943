import React, { useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';

// COMPONENTS
import ColumnsForm, { ColumnsFormProps } from 'components/forms/ColumnsForm';
import Dialog, { DialogProps, DialogButtonProps } from './Dialog';

// HOOKS
import { usePrevious } from 'hooks';

// CONSTANTS
import { Obj } from 'constants/types';

const maxWidthMap: { [colNum: number]: DialogProps['maxWidth'] } = {
  1: 'sm',
  2: 'md',
  3: 'lg',
  4: 'xl',
};

type FormDialogButtonOption = Omit<DialogButtonProps, 'label'> & {
  label?: string;
  actionType?: 'submit' | 'cancel' | 'reset' | 'clearAll';
};

export type FormDialogProps = Omit<DialogProps, 'fullWidth' | 'maxWidth' | 'buttons'> &
  Omit<ColumnsFormProps, 'onValuesChanged'> & {
    buttons?: FormDialogButtonOption[];
    defaultValues?: Obj;
    onSubmit?: (values: Obj) => void;
    onValuesChanged?: (open: boolean, values: Obj) => void;
  };

const ColumnsFormDialog = ({
  open,
  fields,
  buttons,
  defaultValues,
  columnNumber = 2,
  onSubmit,
  onValuesChanged,
  onClose,
  ...restProps
}: FormDialogProps) => {
  const [formValues, setFormValues] = useState<Obj>({});

  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (open && !prevOpen && defaultValues) {
      setFormValues(defaultValues);
    }
  }, [open, prevOpen, defaultValues]);

  // Reset selected value if select options changed
  useEffect(() => {
    const resetValues: Obj = {};
    Object.keys(formValues).forEach(fieldName => {
      // Value is not set, ignore
      const value = formValues[fieldName];
      if (!value) {
        return;
      }

      // Type is not select, ignore
      const field = fields.find(({ name }) => name === fieldName);
      if (!field || field.type !== 'select') {
        return;
      }

      // Value existed in options, ignore
      if ((field.selectOptions || []).some(option => option.value === value)) {
        return;
      }

      // Reset for those not exist in options
      resetValues[fieldName] = null;
    });

    if (!isEmpty(resetValues)) {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        ...resetValues,
      }));
    }
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    if (prevOpen !== undefined) {
      if (onValuesChanged) onValuesChanged(open, formValues);
    }
    // eslint-disable-next-line
  }, [formValues, open]);

  const transformedButtons = useMemo<DialogButtonProps[] | undefined>(
    () =>
      buttons
        ? buttons.map(({ actionType, label = '', ...button }) => {
            switch (actionType) {
              case 'submit':
                return {
                  label: label || 'Submit',
                  color: 'accent',
                  onClick: () => {
                    if (onSubmit) onSubmit(formValues);
                    if (onClose) onClose();
                  },
                  ...button,
                };

              case 'cancel':
                return {
                  label: label || 'Cancel',
                  color: 'secondary',
                  onClick: () => onClose(),
                  ...button,
                };

              case 'reset':
                return {
                  label: label || 'Reset',
                  color: 'secondary',
                  onClick: () => setFormValues(defaultValues || {}),
                  ...button,
                };

              case 'clearAll':
                return {
                  label: label || 'Clear All',
                  color: 'secondary',
                  onClick: () => setFormValues({}),
                  ...button,
                };

              default:
                return { label, ...button };
            }
          })
        : undefined,
    // eslint-disable-next-line
    [buttons, defaultValues, formValues]
  );

  return (
    <Dialog
      open={open}
      maxWidth={maxWidthMap[columnNumber] || 'md'}
      fullWidth
      buttons={transformedButtons}
      onClose={onClose}
      {...restProps}
    >
      <ColumnsForm
        columnNumber={columnNumber}
        fields={fields}
        values={formValues}
        onValuesChanged={setFormValues}
      />
    </Dialog>
  );
};

export default ColumnsFormDialog;
