import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  value?: boolean;
};

const BooleanIconCell = ({ value }: Props) => {
  if (value === undefined) return null;

  if (value) return <CheckIcon color="info" />;

  return <ClearIcon color="error" />;
};

export default BooleanIconCell;
