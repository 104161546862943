import { DataProvider } from 'contexts/dataContext';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { allowed } from 'services/authService';

const MasterCollection = () => {
  const editable = useMemo(() => allowed('project', 'sale'), []);

  return (
    <DataProvider editable={editable}>
      <Outlet />
    </DataProvider>
  );
};

export default MasterCollection;
