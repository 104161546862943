import axios from 'axios';
import { pmsRequest } from './base';

// UTILS
import { convertDataUriToBinaryBlob } from 'utils/fileUtils';

import { DetailResponsePayload } from 'constants/requestConstants';

type SignUrlPayload = {
  fileName: string;
  uploadUrl: string;
};

export const uploadMediaApi = async (
  fileDataUri: string,
  fileName: string
): Promise<string | undefined> => {
  try {
    const { statusCode, data } = await pmsRequest.get<
      SignUrlPayload,
      DetailResponsePayload<SignUrlPayload>
    >('/upload/signed-url', { params: { fileName } });
    if (statusCode === 'OK') {
      const blob = convertDataUriToBinaryBlob(fileDataUri);

      let blobURL = URL.createObjectURL(blob);
      let element = document.createElement('img');
      element.src = blobURL;

      element.onload = function () {
        let width = element.width;
        let height = element.height;
        let size = blob.size;
        console.log('Image information', { width, height, size });
        URL.revokeObjectURL(blobURL);
      };

      const filePayload = await axios.put(data.uploadUrl, blob, {
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      console.log('filePayload', filePayload);
      return data.fileName;
    }
  } catch (e) {
    console.error('uploadMediaApi fail', e);
  }
};
