import React, { FocusEventHandler } from 'react';

// COMPONENTS
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Select from 'components/inputs/Select';
import Input from 'components/inputs/Input';

// UTILS
import { isNumber } from 'utils/numberUtils';

type Props = {
  page: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  rowsPerPageOptions?: number[];
  rowsLabel?: string;
  hideRowPerPageSelector?: boolean;
  onPageChange: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
};

const TablePagination = ({
  page = 0,
  pageCount,
  pageSize,
  rowCount,
  rowsPerPageOptions = [10, 20, 50],
  rowsLabel = 'records',
  hideRowPerPageSelector,
  onPageChange,
  onPageSizeChange,
}: Props) => {
  const handleChanePageInput: FocusEventHandler<HTMLInputElement> = e => {
    if (e.target.value && isNumber(e.target.value)) {
      onPageChange(+e.target.value - 1);
    }

    e.target.value = '';
  };

  return (
    <Grid container alignItems="center" width="100%" px={1}>
      <Grid item xs="auto" sx={{ display: 'flex', alignItems: 'center' }}>
        {!hideRowPerPageSelector && (
          <>
            Show&nbsp;&nbsp;&nbsp;
            <Select
              value={pageSize}
              size="medium"
              onChange={({ target: { value } }) => onPageSizeChange?.(value as number)}
              options={rowsPerPageOptions.map(num => ({ label: num, value: num }))}
              name="rowsPage"
              sx={{ width: 80 }}
            />
            &nbsp;&nbsp;&nbsp;of {rowCount} {rowsLabel}
          </>
        )}
      </Grid>

      <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
        Go to&nbsp;&nbsp;&nbsp;
        <Input onBlur={handleChanePageInput} sx={{ width: 60 }} />
        &nbsp;&nbsp;&nbsp;page
        <Pagination
          count={pageCount}
          page={page + 1}
          color="accentLight"
          onChange={(e, newPage) => onPageChange(newPage - 1)}
        />
      </Grid>
    </Grid>
  );
};

export default TablePagination;
