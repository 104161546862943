import mime from 'mime-types';

export const extractFileNameFromUrl = (mediaUrl: string) => {
  // make sure we remove any nasty GET params
  const url = mediaUrl.split('?')[0];

  // file name will be in last segment
  const segments = url.split('/');
  return decodeURIComponent(segments[segments.length - 1]);
};

const IMAGE_TYPES = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];

const VIDEO_TYPES = ['mov', 'avi', 'wmv', 'mp4', 'flv', 'm3u8', 'ts', '3gp'];

type FileType = 'image' | 'video' | '';

export const getFileType = (fileName: string): FileType => {
  const extension = fileName.split('.')[1];

  if (IMAGE_TYPES.includes(extension)) return 'image';
  if (VIDEO_TYPES.includes(extension)) return 'video';

  return '';
};

const BASE64_MARKER = ';base64,';

// Predefine some extensions to prevent some very old ones
const extensionMap: { [type: string]: string } = {
  'video/quicktime': 'mov',
};

export const extractMimeTypeFromDataUri = (uri: string) => {
  const [prev] = uri.split(BASE64_MARKER);
  if (!prev) return '';

  const [, mimeType] = prev.split(':');
  return mimeType;
};

export const extractFileExtensionFromDataUri = (uri: string) => {
  const mimeType = extractMimeTypeFromDataUri(uri);
  if (!mimeType) return '';

  return extensionMap[mimeType] || mime.extension(mimeType);
};

export const isDataUri = (uri: string) => uri.includes(BASE64_MARKER);
