import { createContext, useContext, PropsWithChildren } from 'react';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import { Any } from 'constants/types';

type FormState = {
  formControl?: UseFormReturn['control'];
  formDisabled?: boolean;
};

const FormContext = createContext<FormState>({});

export const useFormContext = () => useContext(FormContext);

type Props = PropsWithChildren<{
  id?: string;
  form: UseFormReturn;
  disabled?: boolean;
  onSubmit: SubmitHandler<Any>;
}>;

const Form = ({ onSubmit, form, disabled, children, ...props }: Props) => {
  return (
    <form onSubmit={form.handleSubmit(onSubmit)} noValidate {...props}>
      <FormContext.Provider value={{ formControl: form.control, formDisabled: disabled }}>
        {children}
      </FormContext.Provider>
    </form>
  );
};

const StyledForm = styled(Form)`
  height: 100%;
`;

export default StyledForm;
