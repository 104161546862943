const numberFormatter: Record<string, Intl.NumberFormat> = {};

type FormatNumberFunc = (
  number: number,
  options?: {
    fractionDigits?: number;
  }
) => string;

const genFormatName = (options: Parameters<FormatNumberFunc>[1]): string => {
  if (options?.fractionDigits) return `${options?.fractionDigits}`;
  return 'default';
};

export const formatNumber: FormatNumberFunc = (number, options) => {
  const formatName = genFormatName(options);

  if (!numberFormatter[formatName]) {
    if (options) {
      const numberOptions: Intl.NumberFormatOptions = {};
      if (options.fractionDigits) {
        numberOptions.minimumFractionDigits = options.fractionDigits;
        numberOptions.maximumFractionDigits = options.fractionDigits;
      }
      numberFormatter[formatName] = new Intl.NumberFormat('en-US', numberOptions);
    } else {
      numberFormatter[formatName] = new Intl.NumberFormat('en-US');
    }
  }

  return numberFormatter[formatName].format(number);
};

// eslint-disable-next-line
export const isNumber = (input: any) => !isNaN(input);
