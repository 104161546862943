// APIS

// HOOKS
import { useData } from 'contexts/dataContext';

// COMPONENTS
import ListView, { ListViewProps } from 'components/listViews/ListView';
import DesignCollection, { CollectionAttr } from 'models/designCollection';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDataTable, useDataTableSearchable } from 'hooks';
import { fetchMasterCollections } from 'actions/designCollectionActions';
import {
  fetchMasterCollectionApplyByApi,
  fetchMasterCollectionDefaultByApi,
  fetchMasterCollectionSoftDeleteByApi,
} from 'actions/apis/designCollectionApis';

// CONSTANTS

// MODELS

const MasterCollectionList = () => {
  const { editable } = useData();
  const navigate = useNavigate();
  const { metaValues, assignMetaData, dataTableProps } = useDataTable();
  const [collectionList, setCollectionList] = useState<DesignCollection[]>([]);

  const tableColumns: ListViewProps['tableColumns'] = [
    {
      field: CollectionAttr.ID,
      headerName: 'ID',
      filterField: 'id',
      type: 'id',
      width: 50,
    },
    {
      field: CollectionAttr.NAME,
      headerName: 'Collection Name',
      filterField: 'name',
      type: 'string',
      width: 150,
    },
    {
      field: CollectionAttr.DESCRIPTION,
      headerName: 'Description',
      filterField: 'name',
      type: 'string',
      width: 250,
    },
    {
      field: CollectionAttr.DESCRIPTION_TITLE,
      headerName: 'Description Title',
      width: 250,
      type: 'string',
    },
    {
      field: CollectionAttr.IS_APPLIED,
      headerName: 'Apply Status',
      width: 250,
      type: 'booleanIcon',
      align: 'center',
    },
    {
      field: CollectionAttr.IS_DEFAULT,
      headerName: 'Default Status',
      width: 250,
      type: 'booleanIcon',
      align: 'center',
    },
    {
      type: 'listAction',
      getActionOptions: () => [
        { type: 'status', label: 'Apply Collection' },
        { type: 'edit', label: 'Edit Collection' },
        { type: 'delete', label: 'Delete Collection' },
        { type: 'active', label: 'Default Collection' },
      ],
    },
  ];

  const { filterValues, dataTableFilterProps } = useDataTableSearchable({
    columns: tableColumns,
  });

  const loadCollectionLayout = async () => {
    const { list, metaData } = await fetchMasterCollections(filterValues, metaValues);
    if (metaData?.count !== undefined) {
      setCollectionList(list);
      assignMetaData(metaData);
    }
  };

  const handleSoftDeleteCollection = async (id: any) => {
    try {
      const { data } = await fetchMasterCollectionSoftDeleteByApi({ id });
      if (data) {
        const newCollectionList = collectionList.filter(item => item.id! !== id);
        setCollectionList(newCollectionList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApplyCollection = async (id: any) => {
    try {
      const { data } = await fetchMasterCollectionApplyByApi({ id });
      if (data) {
        const indexCollection = collectionList.findIndex(item => item.id === id);
        if (indexCollection > -1) {
          let newCollectionList = [...collectionList];
          newCollectionList[indexCollection].isApplied =
            !newCollectionList[indexCollection].isApplied;
          if (
            !newCollectionList[indexCollection].isApplied &&
            newCollectionList[indexCollection].isDefault
          ) {
            newCollectionList[indexCollection].isDefault =
              !newCollectionList[indexCollection].isDefault;
          }
          setCollectionList(newCollectionList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDefaultCollection = async (id: any) => {
    try {
      const { data } = await fetchMasterCollectionDefaultByApi({ id });
      if (data) {
        const indexCollection = collectionList.findIndex(item => item.id === id);
        if (indexCollection > -1) {
          let newCollectionList = [...collectionList];
          newCollectionList[indexCollection].isDefault =
            !newCollectionList[indexCollection].isDefault;
          setCollectionList(newCollectionList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadCollectionLayout();
  }, [filterValues, metaValues]);

  return (
    <ListView
      editable={editable}
      tableColumns={tableColumns}
      itemList={collectionList}
      onClickCreate={editable ? () => navigate('./new') : undefined}
      onClickEdit={id => navigate(`./${id}`)}
      onClickDelete={id => handleSoftDeleteCollection(id)}
      textStatusUpdate="Apply Status"
      onClickStatusUpdate={collection => handleApplyCollection(collection?.id)}
      textActiveUpdate="Default Status"
      onClickActiveUpdate={id => handleDefaultCollection(id)}
      enableToolbar
      {...dataTableProps}
      {...dataTableFilterProps}
    />
  );
};

export default MasterCollectionList;
