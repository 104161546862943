import { useState } from 'react';

// COMPONENTS
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import SearchMultiSelect, { SearchMultiSelectProps } from 'components/inputs/SearchMultiSelect';

// CONSTANTS
import { TableColFilter } from '../constants/dataTableConstants';

export type FilterSearchMultiSelectProps = Pick<
  TableColFilter,
  'options' | 'groupBy' | 'filterField'
>;

type Props = GridFilterInputValueProps & FilterSearchMultiSelectProps;

const FilterSearchMultiSelect = ({ item, filterField, applyValue, ...restProps }: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<SearchMultiSelectProps['value']>(
    () => item?.value || undefined
  );

  const handleBlur: SearchMultiSelectProps['onBlur'] = () => {
    const nextItem: typeof item = { ...item, value };
    if (filterField) nextItem.filterField = filterField;

    applyValue(nextItem);
    setIsFocused(false);
  };

  const handleChange: SearchMultiSelectProps['onChange'] = (event, newValue) => {
    if (!isFocused) {
      const nextItem: typeof item = { ...item, value };
      if (filterField) nextItem.filterField = filterField;

      applyValue(nextItem);
    }

    setValue(newValue);
  };

  return (
    <Box display="inline-flex" alignItems="center" height={65}>
      <SearchMultiSelect
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setIsFocused(true)}
        variant="standard"
        fullWidth
        autoWidth
        {...restProps}
      />
    </Box>
  );
};

export default FilterSearchMultiSelect;
