import { useState, useMemo, useCallback } from 'react';

// COMPONENTS
import { GridSortModel } from '@mui/x-data-grid-pro';
import { DataTableProps } from 'components/dataTables/DataTable';

// HOOKS
import { useDebounce } from 'hooks';

// UTILS
import { toApiSortQuery } from 'utils/dataMappers';

// CONSTANTS
import { MetaParams } from 'constants/requestConstants';
import { FIRST_PAGE, PAGE_SIZE, SORT_BY_ID } from 'constants/tableConstants';

const RELOAD_DATA_DEBOUNCE_TIME = 1500;

export type DataTableHookReturn = {
  metaValues: MetaParams;
  dataTableProps: Pick<
    DataTableProps,
    | 'page'
    | 'onPageChange'
    | 'pageSize'
    | 'onPageSizeChange'
    | 'rowCount'
    | 'sortModel'
    | 'onSortModelChange'
    | 'initialState'
    | 'loading'
  >;
  tableLoading: boolean;
  assignMetaData: (
    metaData: { count?: number; size?: number; pageNum?: number } | undefined
  ) => void;
};

type Props = {
  defaultSortModel?: GridSortModel | false;
  serverFilterFields?: string[];
};

const useDataTable = ({ defaultSortModel = SORT_BY_ID }: Props = {}): DataTableHookReturn => {
  const [state, setState] = useState(() => ({
    loadReady: false,
    page: FIRST_PAGE,
    pageSize: PAGE_SIZE,
    sortModel: defaultSortModel ? defaultSortModel : [],
    dataCount: 0,
    loading: true,
  }));

  const updateState = useCallback(
    (newState: Partial<typeof state>) =>
      setState(prevState => ({
        ...prevState,
        ...newState,
      })),
    []
  );

  const metaValues = useMemo(
    () => ({ page: state.page, size: state.pageSize, sort: toApiSortQuery(state.sortModel) }),
    [state.page, state.pageSize, state.sortModel]
  );

  const debouncedMetaValues = useDebounce(
    metaValues,
    state.loadReady ? RELOAD_DATA_DEBOUNCE_TIME : 0
  );

  const assignMetaData = useCallback<DataTableHookReturn['assignMetaData']>(
    ({ count, size, pageNum } = {}) => {
      const newState: Partial<typeof state> = {
        loadReady: true,
        loading: false,
      };

      if (count !== undefined) newState.dataCount = count;
      if (size !== undefined) newState.pageSize = size;
      if (pageNum !== undefined) newState.page = pageNum;

      updateState(newState);
    },
    [updateState]
  );

  return {
    metaValues: debouncedMetaValues,
    assignMetaData,
    dataTableProps: {
      initialState: defaultSortModel
        ? {
            sorting: { sortModel: defaultSortModel },
          }
        : undefined,
      page: state.page,
      onPageChange: newPage => updateState({ page: newPage, loading: true }),
      pageSize: state.pageSize,
      onPageSizeChange: newPageSize => updateState({ pageSize: newPageSize, loading: true }),
      sortModel: state.sortModel,
      rowCount: state.dataCount,
      onSortModelChange: newSortModel => updateState({ sortModel: newSortModel, loading: true }),
      loading: state.loading,
    },
    tableLoading: state.loading,
  };
};

export default useDataTable;
