// COMPONENTS
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from 'components/buttons/Button';

type Props = {
  selectAll?: boolean;
  clickSelectAll?: () => void;
};

const DataTableToolbar = ({ selectAll, clickSelectAll }: Props) => {
  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
      <Stack flexDirection="row" gap={1}>
        <GridToolbarColumnsButton color="accent" />
        <Divider orientation="vertical" flexItem />
        <GridToolbarFilterButton
          color="accent"
          componentsProps={{ button: { sx: { color: 'accent' } } }}
        />
        <Divider orientation="vertical" flexItem />
        <GridToolbarDensitySelector color="accent" />
        {selectAll && (
          <>
            <Divider orientation="vertical" flexItem />
            <Button type="submit" color="accent" onClick={clickSelectAll}>
              Select All
            </Button>
          </>
        )}
      </Stack>
    </GridToolbarContainer>
  );
};

export default DataTableToolbar;
