import { cloneDeep, pick } from 'lodash';
import { Obj } from 'constants/types';

// M: current model
// P: payload format, default payload format is same as model attributes
abstract class BaseModel<M> {
  clone(): this {
    return cloneDeep(this);
  }

  // EXTENDABLE
  protected _getAttrNames(): string[] {
    return [];
  }

  // EXTENDABLE: massive assign data
  assign(attrs: Partial<Omit<M, BaseModelAttrNames>>) {
    if (this._getAttrNames().length === 0) {
      Object.assign(this, attrs);
    } else {
      Object.assign(this, pick(attrs, this._getAttrNames()));
    }
    return this;
  }

  toObject(): Obj {
    return pick(this, this._getAttrNames());
  }

  // EXTENDABLE: map current model to endpoint payload
  // Override this method if payload format is different from model attributes
  toPayload(): Obj {
    return Object.assign({}, this);
  }

  // EXTENDABLE: map payload from endpoint to current model
  // Override this method if payload format is different from model attributes
  fromPayload(payload: Obj): this {
    Object.assign(this, payload);

    return this;
  }
}

export type BaseModelAttrNames =
  | 'clone'
  | 'assign'
  | 'toObject'
  | 'toPayload'
  | 'fromPayload'
  | '_getAttrNames';

export default BaseModel;
