import { UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// MODELS
import { DesignCollectionValues } from 'models/designCollection';

// HOOKS
import { useYup, useForm } from 'hooks';

// CONSTANTS
import { Any, Obj } from 'constants/types';

type Props = {
  defaultValues?: Obj;
};

const useProductCollectionForm = ({ defaultValues }: Props): UseFormReturn => {
  const { string, object } = useYup();

  const validationSchema = object().shape<{ [name in keyof DesignCollectionValues]?: Any }>({
    name: string().nullable().required(),
  });

  return useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
};

export default useProductCollectionForm;
