import { useCallback, useState } from 'react';

const useUpdateState = <S = Record<string, never>>(
  initialState: S | (() => S)
): [S, (state: Partial<S>) => void, () => void] => {
  const [state, setState] = useState<S>(initialState);

  const updateState = useCallback(
    (newState: Partial<S>) =>
      setState(prevState => ({
        ...prevState,
        ...newState,
      })),
    []
  );

  const resetState = useCallback(() => setState(initialState), [initialState]);

  return [state, updateState, resetState];
};

export default useUpdateState;
