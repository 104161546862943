import BaseModel, { BaseModelAttrNames } from './baseModel';
import SegmentGroup from './segmentGroup';
import { pick } from 'lodash';

// CONSTANTS
import { PartialValues, Obj } from 'constants/types';
import { Coordinates } from 'components/segmentsImages/constants/types';

export enum SegmentType {
  Normal = 'NORMAL',
  Combination = 'COMBINATION',
}

export enum ResidentialType {
  Residential = 'RESIDENTIAL',
  Hospitality = 'HOSPITALITY',
}

export enum ActiveType {
  Active = 'Active',
  Inactive = 'Inactive',
}

class Segment extends BaseModel<Segment> {
  id?: number;

  // segment number
  name?: string;

  viewStar?: number;

  // area m2
  acreage?: number;

  type?: SegmentType;

  residentialType?: ResidentialType;

  coordinates?: Coordinates;

  planId?: number;

  segmentGroup?: SegmentGroup;

  connectedSegmentIds?: number[];

  combinedSegmentIds?: number[];

  imageUrl?: string;

  imageUploadToUrl?: string;

  activeSellBareshell?: ActiveType;

  static _attrNames: SegmentFields[] = [
    'id',
    'name',
    'viewStar',
    'acreage',
    'type',
    'residentialType',
    'coordinates',
    'planId',
    'segmentGroup',
    'connectedSegmentIds',
    'combinedSegmentIds',
    'imageUrl',
    'activeSellBareshell',
  ];

  protected _getAttrNames(): SegmentFields[] {
    return Segment._attrNames;
  }

  constructor(attrs?: PartialValues<SegmentValues>) {
    super();
    if (attrs) {
      this.assign(attrs);
    }
  }

  get coordinatesString() {
    return JSON.stringify(this.coordinates);
  }

  get displayName() {
    return `${this.name} (${this.id})`;
  }

  assign({ coordinates, ...attrs }: PartialValues<SegmentValues>): this {
    if (coordinates !== undefined) {
      this.coordinates = typeof coordinates === 'string' ? JSON.parse(coordinates) : coordinates;
    }
    Object.assign(this, pick(attrs, this._getAttrNames()));
    return this;
  }

  fromPayload({
    id,
    name,
    viewStar,
    acreage,
    type,
    residentialType,
    coordinates,
    planId,
    segmentGroup,
    connectedSegmentIds,
    combinedSegmentIds,
    imageUrl,
    activeSellBareshell,
    imageUploadToUrl,
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (viewStar !== undefined) this.viewStar = viewStar;
    if (acreage !== undefined) this.acreage = acreage;
    if (type !== undefined) this.type = type;
    if (residentialType !== undefined) this.residentialType = residentialType;
    if (coordinates !== undefined) this.coordinates = JSON.parse(coordinates);
    if (planId !== undefined) this.planId = planId;
    if (segmentGroup) this.segmentGroup = new SegmentGroup().fromPayload(segmentGroup);
    if (connectedSegmentIds !== undefined) this.connectedSegmentIds = connectedSegmentIds;
    if (combinedSegmentIds !== undefined) this.combinedSegmentIds = combinedSegmentIds;
    if (imageUrl !== undefined) this.imageUrl = imageUrl;
    if (activeSellBareshell != undefined) {
      if (activeSellBareshell) {
        this.activeSellBareshell = ActiveType.Active;
      } else {
        this.activeSellBareshell = ActiveType.Inactive;
      }
    }
    if (imageUploadToUrl !== undefined) this.imageUploadToUrl = imageUploadToUrl;

    return this;
  }

  toPayload(): Obj {
    return {
      name: this.name,
      viewStar: this.viewStar ?? 0,
      acreage: this.acreage ?? 0,
      coordinates: this.coordinatesString,
      planId: this.planId,
      segmentGroup: this.segmentGroup?.id
        ? {
            id: this.segmentGroup?.id,
          }
        : null,
      type: this.type || SegmentType.Normal,
      residentialType: this.residentialType || ResidentialType.Residential,
      connectedSegmentIds: this.connectedSegmentIds || [],
      combinedSegmentIds: this.combinedSegmentIds || [],
      imageUrl: this.imageUrl,
      activeSellBareshell: this.activeSellBareshell === 'Active' ? true : false,
    };
  }
}

export type SegmentValues = Omit<
  Segment,
  BaseModelAttrNames | 'coordinatesString' | 'displayName'
> & {
  segmentGroupId?: number;
};

export type SegmentFields = keyof Omit<Segment, BaseModelAttrNames>;

export default Segment;
