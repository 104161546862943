import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { GlobalBreadcrumb, GlobalBreadcrumbProps } from 'contexts/globalContext';

export enum ProjectTabKey {
  GeneralInfo = 'general-information',
  Layout = 'layouts',
  Block = 'blocks',
  FloorPlan = 'floor-plans',
  Segment = 'segments',
  PriceView = 'price-view',
  ProductList = 'product-lists',
  Order = 'orders',
  HomeNumber = 'home-numbers',
  Translation = 'translations',
  Agent = 'agents',
  BoQ = 'BoQ',
  BillOfQuantity = 'bill-of-quantity',
}

type BreadcrumbRoute = BreadcrumbsRoute & {
  props?: GlobalBreadcrumbProps;
};

export const routes: BreadcrumbRoute[] = [
  {
    path: '/projects',
    breadcrumb: 'Manage Projects',
  },
  {
    path: '/projects/new',
    breadcrumb: 'Create New Project',
  },
  {
    path: '/projects/:id',
    breadcrumb: GlobalBreadcrumb,
    props: { breadcrumbKey: 'resourceName' },
    children: [
      {
        path: ProjectTabKey.Block,
        breadcrumb: 'Block Management',
      },
      {
        path: ProjectTabKey.FloorPlan,
        breadcrumb: 'Floor Plan',
      },
      {
        path: ProjectTabKey.PriceView,
        breadcrumb: 'Price & View',
      },
      {
        path: ProjectTabKey.ProductList,
        breadcrumb: 'Product List',
      },
      {
        path: ':any/:subResourceId',
        breadcrumb: GlobalBreadcrumb,
        props: {
          breadcrumbKey: 'subResourceName',
        },
      },
    ],
  },
  {
    path: '/design-hub',
    breadcrumb: 'Design Hub Management',
  },
  {
    path: '/design-hub/:id',
    breadcrumb: GlobalBreadcrumb,
    props: {
      breadcrumbKey: 'resourceName',
    },
  },
  {
    path: '/languages/:id',
    breadcrumb: GlobalBreadcrumb,
    props: {
      breadcrumbKey: 'resourceName',
    },
  },
  {
    path: '/agents/:id',
    breadcrumb: GlobalBreadcrumb,
    props: {
      breadcrumbKey: 'resourceName',
    },
  },
  {
    path: '/users/new',
    breadcrumb: 'New User',
  },
  {
    path: '/users/:id',
    breadcrumb: GlobalBreadcrumb,
    props: { breadcrumbKey: 'resourceName' },
  },
  {
    path: '/countries',
    breadcrumb: 'Countries Management',
  },
];
