import { GridSortModel } from '@mui/x-data-grid-pro';
import { Obj, SelectOption, Any } from 'constants/types';
import ProductQuantityMetric from 'models/productQuantityMetric';
// import { ListResponsePayload } from 'constants/requestConstants';
// import BaseModel from 'models/baseModel';

type ToOptionsFunc = (
  items: Obj[],
  keys?: { labelKey?: string; valueKey?: string; setData?: boolean }
) => SelectOption[];

const DEFAULT_LABEL_KEY = 'name';
const DEFAULT_VALUE_KEY = 'id';

export const toOptions: ToOptionsFunc = (items, keys) =>
  items.map(item => ({
    label: item[keys?.labelKey || DEFAULT_LABEL_KEY],
    value: item[keys?.valueKey || DEFAULT_VALUE_KEY],
    data: keys?.setData ? item : undefined,
  }));

export const toApiSortQuery = (sortModel: GridSortModel): string =>
  sortModel.map(({ field, sort }) => `${field},${sort}`)[0]; // 'sort=id,desc'

export const deleteFalsyProps = (obj: Obj) => {
  const newObj: Obj = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const flattenDeepData = (data: Any, dynamicKey: string) => {
  let exportDataArray: Any[] = [];
  data.forEach((metric: Record<string, Any>) => {
    if (metric[dynamicKey]?.length > 0) {
      metric[dynamicKey].forEach((mt: Record<string, Any>) => {
        exportDataArray.push({
          ...metric,
          ...mt,
          layoutName: mt.layoutName,
          productOrderQuantity: mt.productOrderQuantity,
        });
      });
    } else {
      exportDataArray.push(new ProductQuantityMetric().fromPayload(metric));
    }
  });

  return exportDataArray;
};

// export const mapPayloadDataToModel<M extends BaseModel>: (paylaod: ListResponsePayload<Obj>, Model: M) = (payload, Model) => {
//   payload.data = (payload.data || []).map(dataPayload => new Model().fromPayload(dataPayload))
//   return payload;
// }
