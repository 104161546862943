import { SessionStorageKey } from 'constants/storageConstants';
import { TableParams } from 'components/dataTables/DataTable';

export const getTableParams = (tableId: string): TableParams | {} => {
  const tableParamsById = JSON.parse(sessionStorage.getItem(SessionStorageKey.TableParams) || '{}');

  return tableParamsById[tableId] || {};
};

export const setTableParams = (tableId: string, tableParams: TableParams) => {
  const tableParamsById = JSON.parse(sessionStorage.getItem(SessionStorageKey.TableParams) || '{}');
  tableParamsById[tableId] = tableParams;

  sessionStorage.setItem(SessionStorageKey.TableParams, JSON.stringify(tableParamsById));
};
