export enum LocalStorageKey {
  AccessToken = 'token',
  RememberMe = 'remember_me',
  HasLiteProject = 'has_lite_project',
}

export enum SessionStorageKey {
  AccessToken = 'token',
  TableParams = 'table_params',
}
