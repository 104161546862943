import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

// SERVICES
import { getAccessToken, logoutSuccess } from 'services/authService';

// CONSTANTS
import { PMS_API_HOSTNAME, PMS_API_HOSTNAME_V3 } from 'constants/environments';
// const PMS_API_HOSTNAME_V3 = 'https://pms-backend-staging.levitate.me/api/v3';
// const PMS_API_HOSTNAME = 'https://pms-backend-staging.levitate.me/api/v2';
// const PMS_API_HOSTNAME_V3 = 'http://192.168.1.20:9090/api/v3';
// const PMS_API_HOSTNAME = 'http://192.168.1.20:9090/api/v2';

declare module 'axios' {
  interface AxiosRequestConfig {
    options?: {
      silenceSuccess?: boolean; // don't display notification when success
      silence?: boolean; // don't display notification when success and failure
      newAbortSignal?: () => AbortSignal;
    };
  }
}

type Interceptor = {
  onRequest?: (requestConfig: AxiosRequestConfig) => AxiosRequestConfig;
  onRequestError?: (error: AxiosError) => Promise<AxiosError>;
  onResponse?: (response: AxiosResponse) => AxiosResponse;
  onResponseError?: (error: AxiosError) => Promise<AxiosError>;
};

/**
 * ABORT INTERCEPTORS
 */
export const abortInterceptor: Interceptor = {
  onRequest: config => {
    if (config?.options?.newAbortSignal) {
      config.signal = config?.options?.newAbortSignal();
    }
    return config;
  },
  onResponseError: error => {
    if (error.code === 'ERR_CANCELED') {
      console.error('Cancelled request', error);
      return Promise.reject('Request was aborted!');
    }

    return Promise.reject(error);
  },
};

/**
 * AUTH INTERCEPTORS
 */
const authInterceptor: Interceptor = {
  onRequest: ({ headers, ...restConfigs }) => ({
    ...restConfigs,
    headers: {
      ...headers,
      Authorization: 'Bearer ' + getAccessToken(),
    },
  }),
  onResponseError: error => {
    if (error?.response?.status === 401) {
      logoutSuccess();
      window.location.href = `/login?from=${encodeURIComponent(window.location.pathname)}`;
    }

    return Promise.reject(error);
  },
};

/**
 * NOTIFICATION INTERCEPTORS
 */
const notificationInterceptor: Interceptor = {
  onResponse: response => {
    const {
      data,
      config: { method, options },
    } = response;
    const requestMethod = method?.toLocaleUpperCase() || 'GET';

    if (requestMethod !== 'GET' && !(options?.silenceSuccess || options?.silence)) {
      toast.success(data.message);
    }

    return response;
  },
  onResponseError: error => {
    // timeout, request cancelled

    if (error.code === 'ECONNABORTED') {
      toast.error('Request timeout. Please try it again');
    } else if (error?.response?.data) {
      const { errors, message } = error.response.data;
      const {
        config: { options },
      } = error.response;
      if (!isEmpty(errors)) {
        errors.forEach((e: { message: string }) => {
          if (e.message && !options?.silence) {
            toast.error(e.message);
          }
        });
      } else if (message && !options?.silence) {
        toast.error(message);
      }
    }

    return Promise.reject(error);
  },
};

/**
 * EXPORT INTERCEPTORS
 */
const exportInterceptor: Interceptor = {
  onRequest: ({ params, ...restConfigs }) => ({
    ...restConfigs,
    responseType: 'blob',
    params: {
      ...params,
      access_token: getAccessToken(),
    },
  }),
  // notify when error only
  onResponseError: async error => {
    // timeout, request cancelled
    if (error.code === 'ECONNABORTED') {
      toast.error('Request timeout. Please try it again');
    } else if (error?.response?.data) {
      const errorResponseString = await error?.response?.data.text();

      if (errorResponseString) {
        const errorResponse = JSON.parse(errorResponseString);
        const { errors, message } = errorResponse;
        if (!isEmpty(errors)) {
          errors.forEach((e: { message: string }) => {
            if (e.message) {
              toast.error(e.message);
            }
          });
        } else if (message) {
          toast.error(message);
        }

        return Promise.reject(errorResponse);
      }
    }

    return Promise.reject(error);
  },
};

/**
 * GENERAL INTERCEPTORS
 */
// Transform response, return data from response only
const dataInterceptor: Interceptor = {
  onResponse: ({ data }) => data, // transform data
  onResponseError: error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response fail', error.response);
      if (error.response.status < 511) {
        return Promise.reject(error.response.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('Request fail', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }

    return Promise.reject(error);
  },
};

type RequestConfigs = {
  baseURL?: string;
  timeout?: number;
};

const createRequestInstance = (
  { baseURL = PMS_API_HOSTNAME || '', timeout = 180000 }: RequestConfigs = {},
  interceptors?: Interceptor[]
) => {
  const config: AxiosRequestConfig = {
    baseURL,
    timeout,
    headers: {},
    paramsSerializer: function paramsSerializer(params) {
      return Object.entries(Object.assign({}, params))
        .filter(([, value]) => value != null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
        .join('&');
    },
  };

  const request = axios.create(config);

  (interceptors || []).forEach(interceptor => {
    if (interceptor.onRequest || interceptor.onRequestError) {
      request.interceptors.request.use(interceptor.onRequest, interceptor.onRequestError);
    }

    if (interceptor.onResponse || interceptor.onResponseError) {
      request.interceptors.response.use(interceptor.onResponse, interceptor.onResponseError);
    }
  });

  request.interceptors.response.use(dataInterceptor.onResponse, dataInterceptor.onResponseError);

  return request;
};

const createRequestInstanceV3 = (
  { baseURL = PMS_API_HOSTNAME_V3 || '', timeout = 180000 }: RequestConfigs = {},
  interceptors?: Interceptor[]
) => {
  const config: AxiosRequestConfig = {
    baseURL,
    timeout,
    headers: {},
    paramsSerializer: function paramsSerializer(params) {
      return Object.entries(Object.assign({}, params))
        .filter(([, value]) => value != null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
        .join('&');
    },
  };

  const request = axios.create(config);

  (interceptors || []).forEach(interceptor => {
    if (interceptor.onRequest || interceptor.onRequestError) {
      request.interceptors.request.use(interceptor.onRequest, interceptor.onRequestError);
    }

    if (interceptor.onResponse || interceptor.onResponseError) {
      request.interceptors.response.use(interceptor.onResponse, interceptor.onResponseError);
    }
  });

  request.interceptors.response.use(dataInterceptor.onResponse, dataInterceptor.onResponseError);

  return request;
};

export const authRequest = createRequestInstance();

export const pmsRequest = createRequestInstance({}, [
  authInterceptor,
  notificationInterceptor,
  abortInterceptor,
]);

export const pmsRequestOrigin = createRequestInstance({}, [authInterceptor, abortInterceptor]);

export const exportRequest = createRequestInstance({}, [exportInterceptor]);

export const pmsRequestV3 = createRequestInstanceV3({}, [
  authInterceptor,
  notificationInterceptor,
  abortInterceptor,
]);

export const pmsRequestV3Origin = createRequestInstanceV3({}, [authInterceptor, abortInterceptor]);
