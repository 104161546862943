import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// COMPONENTS
import Typography from '@mui/material/Typography';
import Button from 'components/buttons/Button';

// CONSTANTS
import { FormMode, FormId } from 'constants/formConstants';

export type FormViewProps = PropsWithChildren<
  StyledProps & {
    title: string | JSX.Element;
    mode: FormMode;
    editable?: boolean;
    cancelText?: string;
    submitText?: string;
    cancelUrl?: string;
    submitFormId?: string | false;
    submitting?: boolean;
    onCancel?: () => void;
    headerComponent?: ReactNode;
  }
>;

const FormView = ({
  title,
  mode,
  editable = true,
  cancelText,
  submitText,
  cancelUrl,
  submitFormId = FormId.DataForm,
  noHeaderBorder,
  submitting,
  children,
  onCancel,
  headerComponent,
}: FormViewProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper noHeaderBorder={noHeaderBorder}>
      <header>
        <Typography variant="h5">{title}</Typography>
        {headerComponent}
      </header>

      <section>{children}</section>

      <footer>
        <Button
          type="button"
          color="secondary"
          onClick={onCancel ? onCancel : () => navigate(cancelUrl || '..')}
        >
          {cancelText || 'Back'}
        </Button>
        {editable && !!submitFormId && (
          <Button
            type="submit"
            color="accent"
            form={submitFormId}
            loading={submitting}
            disabled={!editable}
          >
            {submitText || (mode === 'create' ? 'Create' : 'Save')}
          </Button>
        )}
      </footer>
    </Wrapper>
  );
};

type StyledProps = {
  noHeaderBorder?: boolean;
};

const Wrapper = styled.div<StyledProps>`
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  & > header {
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
  }

  & > section {
    overflow: auto;
    align-self: stretch;
    height: 100%;
    ${p => !p.noHeaderBorder && 'border-top: 1px solid #d4d2db;'}
  }

  & > footer {
    padding: 0.75rem 1rem;
    border-top: 1px solid #d4d2db;
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    .MuiButton-root {
      width: 10rem;
      margin-left: 0.75rem;
    }
  }
`;

export default FormView;
