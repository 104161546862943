import moment from 'moment';
import { round, get } from 'lodash';

// COMPONENTS
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import Status from 'components/chips/Status';
import Link from 'components/navigation/Link';
import ImageCell from '../../cells/ImageCell';
import ColorCell from '../../cells/ColorCell';

// UTILS
import { formatNumber } from 'utils/numberUtils';

// CONSTANTS
import {
  TableColDef,
  TableEnrichedColDef,
  TableActionColDef,
  TableStatusColDef,
  TableTooltipColDef,
  TableIdColDef,
} from '../../constants/dataTableConstants';
import BooleanIconCell from '../../cells/BooleanIconCell';
import { ValueOption } from 'constants/types';

type DateType = 'date' | 'dateTime';

const DATE_FORMAT_MAP: { [key in DateType]: string } = {
  date: 'DD/MM/yyyy',
  dateTime: 'DD/MM/yyyy HH:mm',
};

export const transformColumnType = (
  column: TableEnrichedColDef
): TableColDef | TableActionColDef => {
  const { type, ...columnParams } = column;

  switch (type) {
    case 'id': {
      const { link, onClick, ...restColumnParams } = columnParams as TableIdColDef;
      return {
        ...restColumnParams,
        renderCell: ({ value, row }) =>
          link !== false ? (
            <Link
              to={link ? link.replace(':id', value) : `./${value}`}
              color="accent"
              underline="none"
              onClick={onClick ? () => onClick(row) : undefined}
            >
              {value}
            </Link>
          ) : (
            value
          ),
      };
    }

    case 'tooltip': {
      const { tooltipContent, ...restColumnParams } = columnParams as TableTooltipColDef;

      return {
        ...restColumnParams,
        renderCell: ({ row, value }) => {
          const content = get(row, tooltipContent);
          if (!content) return value;

          return (
            <Tooltip title={content}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      };
    }

    case 'date':
    case 'dateTime':
      return {
        ...columnParams,
        valueFormatter: ({ value }) =>
          value && moment(value as string).format(DATE_FORMAT_MAP[type as DateType]),
      };

    case 'percent':
      return {
        ...columnParams,
        align: 'right',
        valueFormatter: ({ value }) =>
          value !== undefined ? `${round(+(value ?? 0), 2)} %` : undefined,
      };

    case 'number':
      return {
        ...columnParams,
        align: 'right',
        type: 'number',
        valueFormatter: ({ value }) =>
          value !== undefined ? formatNumber((value as number) || 0) : undefined,
      };

    case 'currency':
      return {
        ...columnParams,
        align: 'right',
        valueFormatter: ({ value }) =>
          value !== undefined
            ? formatNumber((value as number) || 0, { fractionDigits: 2 })
            : undefined,
      };

    case 'booleanIcon':
      return {
        ...columnParams,
        renderCell: ({ value }) => <BooleanIconCell value={value} />,
      };

    case 'image':
      return {
        ...columnParams,
        renderCell: ({ value }) => <ImageCell value={value} />,
      };

    case 'imageUrl':
      return {
        ...columnParams,
        renderCell: ({ value }) => <ImageCell value={value} displayType={'url'} />,
      };

    case 'status': {
      const { statusMap, statusTranslationMap, ...restColumnParams } =
        columnParams as TableStatusColDef;
      return {
        ...restColumnParams,
        renderCell: ({ value }) => {
          const display = statusTranslationMap?.[value] || value;
          return <Status label={display} color={statusMap?.[value]} />;
        },
      };
    }

    case 'color':
      return {
        ...columnParams,
        renderCell: ({ value }) => <ColorCell value={value} />,
      };

    case 'rating':
      return {
        ...columnParams,
        renderCell: ({ value }) => <Rating name="simple-controlled" readOnly value={value} />,
      };

    case 'singleSelect': {
      if (columnParams.renderCell) return columnParams;

      const optionMap: Record<ValueOption['value'], ValueOption['label']> = (
        (columnParams.valueOptions as ValueOption[]) || []
      ).reduce(
        (prev, option) => ({
          ...prev,
          [option.value]: option.label,
        }),
        {}
      );

      return {
        ...columnParams,
        renderCell: ({ value }) => optionMap[value] || value,
      };
    }

    case 'multiSelect': {
      if (columnParams.renderCell) return columnParams;

      const optionMap: Record<ValueOption['value'], ValueOption['label']> = (
        (columnParams.valueOptions as ValueOption[]) || []
      ).reduce(
        (prev, option) => ({
          ...prev,
          [option.value]: option.label,
        }),
        {}
      );

      return {
        ...columnParams,
        renderCell: ({ value }) =>
          (value || [])
            .map(
              // @ts-ignore
              (val: any) => optionMap[columnParams.valueField ? val[columnParams.valueField] : val]
            )
            .join(', '),
      };
    }

    case 'actions':
      const { getActions, getActionOptions, ...restColumnParams } =
        columnParams as TableActionColDef;

      return {
        ...restColumnParams,
        type: 'actions',
        getActions: (
          params: Parameters<TableActionColDef['getActions']>[0]
        ): ReturnType<TableActionColDef['getActions']> => {
          if (getActionOptions) {
            const actionOptions = getActionOptions(params);
            const shouldShowInMenu = actionOptions.length > 3;

            return actionOptions.map(({ showInMenu, ...option }, index) => (
              // @ts-ignore
              <GridActionsCellItem
                key={index}
                showInMenu={showInMenu !== undefined ? showInMenu : shouldShowInMenu}
                {...option}
              />
            ));
          }

          if (getActions) {
            return getActions(params);
          }

          return [];
        },
      };

    default:
      return column;
  }
};
