// APIS
import { uploadMediaApi } from 'apis/uploadApis';

// COMPONENTS
import { Box, Stack } from '@mui/material';
import TextField from 'components/formControls/TextField';
import Image from 'components/images/Image';
import TextInput from 'components/inputs/TextField';
import { FormId } from 'constants/formConstants';

// HOOKS
import useDesignCollectionForm from 'pages/projects/productLists/hooks/useDesignCollectionForm';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// CONSTANTS
import DesignCollection, { CollectionUploadId } from 'models/designCollection';
import { now } from 'utils/dateTimeUtils';

// FILES
import {
  fetchDesignCollectionUpdateByIdApi,
  fetchMasterCollectionCreateByApi,
} from 'actions/apis/designCollectionApis';
import AspectBox from 'components/boxes/AspectBox';
import UploadMediaDropzone from 'components/inputs/UploadMediaDropzone';
import CropImageDialog from 'components/uploadFiles/CropImageDialog';
import UploadFilesState, {
  bindCropImageDialog,
  bindUploadDropzone,
  bindViewImageDialog,
  bindViewImageTrigger,
} from 'components/uploadFiles/UploadFilesState';
import ViewMediaDialog from 'components/uploadFiles/ViewMediaDialog';
import { extractFileExtensionFromDataUri } from 'utils/uriUtils';

enum FormCollectionControl {
  Name = 'name',
  Tag = 'tag',
  Code = 'code',
  DescriptionTitle = 'descriptionTitle',
  Description = 'description',
}

type Props = {
  isDisabled?: boolean;
  collection?: DesignCollection;
};

const MasterCollectionForm = ({ collection }: Props) => {
  const navigate = useNavigate();
  const form = useDesignCollectionForm({ defaultValues: {} });
  const { control, handleSubmit, setValue } = form;
  const [imageUriBackground, setImageUriBackground] = useState('');
  const [imageUriThumbnail, setImageUriThumbnail] = useState('');
  const checkUriBackground = useRef<boolean>(false);
  const checkUriThumbnail = useRef<boolean>(false);

  useEffect(() => {
    if (collection?.id) {
      setValue(FormCollectionControl.Code, collection.code);
      setValue(FormCollectionControl.Name, collection.name);
      setValue(FormCollectionControl.Tag, collection.tag);
      setValue(FormCollectionControl.Description, collection.description);
      setValue(FormCollectionControl.DescriptionTitle, collection.descriptionTitle);
      setImageUriBackground(collection.backgroundModuleImageUri! || '');
      setImageUriThumbnail(collection.thumbnailImageUri! || '');
    }
  }, [collection]);

  const handleSubmitForm: SubmitHandler<DesignCollection> = async values => {
    values.isSignature = false;

    if (imageUriBackground) {
      const fileExt = extractFileExtensionFromDataUri(imageUriBackground);
      const fileName = `Background-${now()}.${fileExt}`;
      const fileNameUrl = checkUriBackground.current
        ? await uploadMediaApi(imageUriBackground, fileName)
        : imageUriBackground;

      if (fileNameUrl) {
        values.backgroundModuleImageUri = fileNameUrl;
      }
    }

    if (imageUriThumbnail) {
      const fileExt = extractFileExtensionFromDataUri(imageUriThumbnail);
      const fileName = `Thumbnail-${now()}.${fileExt}`;
      const fileNameUrl = checkUriThumbnail.current
        ? await uploadMediaApi(imageUriThumbnail, fileName)
        : imageUriThumbnail;

      if (fileNameUrl) {
        values.thumbnailImageUri = fileNameUrl;
      }
    }

    const { data } = collection?.id
      ? await fetchDesignCollectionUpdateByIdApi({ id: collection.id, ...values } as any)
      : await fetchMasterCollectionCreateByApi(values as any);
    if (data?.id) {
      setTimeout(() => {
        setImageUriBackground(data?.backgroundModuleImageUri || '');
        setImageUriThumbnail(data?.thumbnailImageUri || '');
        checkUriBackground.current = false;
        checkUriThumbnail.current = false;
        navigate(location.pathname.replace('new', `${data.id}`));
      }, 1000);
    }
  };

  const handleUploadMediaFile = async (fileDataUri: string, uploadId: CollectionUploadId) => {
    switch (uploadId) {
      case CollectionUploadId.BackgroundImage:
        setImageUriBackground(fileDataUri);
        checkUriBackground.current = true;
        break;

      case CollectionUploadId.ThumbnailImage:
        setImageUriThumbnail(fileDataUri);
        checkUriThumbnail.current = true;
        break;

      default:
        break;
    }
  };

  return (
    <form id={FormId.DataForm} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack columnGap={5} flexDirection="row">
        <Box flex={1}>
          <TextField
            control={control}
            label="Code"
            name={FormCollectionControl.Code || ''}
            defaultValue={collection?.code || ''}
            fullWidth
            maxLength={4}
          />
        </Box>
        <Box flex={1}>
          {collection?.id && (
            <TextInput label="ID" defaultValue={collection?.id} fullWidth disabled />
          )}
        </Box>
      </Stack>

      <Stack columnGap={5} flexDirection="row" marginTop={4}>
        <Box flex={1}>
          <TextField
            control={control}
            label="Name"
            name={FormCollectionControl.Name}
            defaultValue={collection?.name || ''}
            fullWidth
            maxLength={30}
          />
        </Box>
        <Box flex={1}>
          <TextField
            control={control}
            label="Tag"
            name={FormCollectionControl.Tag || ''}
            defaultValue={collection?.tag || ''}
            disabled={collection?.isSignature}
            fullWidth
          />
        </Box>
      </Stack>
      <UploadFilesState>
        {uploadState => (
          <>
            <Stack flexDirection="row" columnGap={5} marginTop={4}>
              <Stack flex={1} flexDirection="row" columnGap={2}>
                <Box flex={1.5}>
                  <UploadMediaDropzone
                    label="Collection Background (16:6.2)"
                    height="3.75rem"
                    acceptedFiles={['image/*']}
                    {...bindUploadDropzone(uploadState, {
                      uploadId: CollectionUploadId.BackgroundImage,
                      aspect: 16 / 6.2,
                    })}
                  />
                </Box>
                {(imageUriBackground || collection?.imageUrl) && (
                  <Box flex={0.5}>
                    <AspectBox aspect={13 / 10}>
                      <Image
                        src={imageUriBackground || collection?.imageUrl!}
                        height="120px"
                        width="120px"
                        {...bindViewImageTrigger(
                          uploadState,
                          imageUriBackground || collection?.imageUrl!
                        )}
                      />
                    </AspectBox>
                  </Box>
                )}
              </Stack>
              <Stack flex={1} flexDirection="row" columnGap={2}>
                <Box flex={1.5}>
                  <UploadMediaDropzone
                    label="Collection Thumbnail (6:11)"
                    height="3.75rem"
                    acceptedFiles={['image/*']}
                    {...bindUploadDropzone(uploadState, {
                      uploadId: CollectionUploadId.ThumbnailImage,
                      aspect: 6 / 11,
                    })}
                  />
                </Box>
                {(imageUriThumbnail || collection?.thumbnailImageUri) && (
                  <Box flex={0.5}>
                    <AspectBox aspect={13 / 10}>
                      <Image
                        src={imageUriThumbnail || collection?.thumbnailImageUri!}
                        height="120px"
                        width="120px"
                        {...bindViewImageTrigger(
                          uploadState,
                          imageUriThumbnail || collection?.thumbnailImageUri!
                        )}
                      />
                    </AspectBox>
                  </Box>
                )}
              </Stack>
            </Stack>

            <Stack flexDirection="row" columnGap={5} marginTop={4}>
              <Box flex={1}>
                <TextField
                  control={control}
                  label="Collection Background Description (700 chars)"
                  name={FormCollectionControl.DescriptionTitle}
                  defaultValue={collection?.descriptionTitle || ''}
                  fullWidth
                  disabled={collection?.isSignature}
                  maxLength={700}
                  multiline
                />
              </Box>

              <Box flex={1}>
                <TextField
                  control={control}
                  label="Collection Thumbnail Description (700 chars)"
                  name={FormCollectionControl.Description}
                  defaultValue={collection?.description || ''}
                  fullWidth
                  disabled={collection?.isSignature}
                  maxLength={700}
                  multiline
                />
              </Box>
            </Stack>

            <CropImageDialog
              onComplete={(imageDataUri, uploadId) =>
                handleUploadMediaFile(imageDataUri, uploadId as CollectionUploadId)
              }
              aspect={13 / 10}
              {...bindCropImageDialog(uploadState)}
            />

            <ViewMediaDialog {...bindViewImageDialog(uploadState)} />
          </>
        )}
      </UploadFilesState>
    </form>
  );
};

export default MasterCollectionForm;
