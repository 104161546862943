// COMPONENTS
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Select, { SelectProps } from 'components/inputs/Select';

// CONSTANTS
import { TableColFilter } from '../constants/dataTableConstants';

export type FilterSelectProps = Pick<TableColFilter, 'options' | 'groupBy' | 'filterField'> &
  Pick<SelectProps, 'noneLabel' | 'displayEmpty'>;

type Props = GridFilterInputValueProps & FilterSelectProps;

const FilterSelect = ({ item, filterField, applyValue, ...restProps }: Props) => {
  const handleChange: SelectProps['onChange'] = ({ target: { value } }) => {
    const nextItem: typeof item = { ...item, value };
    if (filterField) nextItem.filterField = filterField;

    applyValue(nextItem);
  };

  return (
    <Box display="inline-flex" alignItems="center" height={36}>
      <Select onChange={handleChange} variant="standard" fullWidth size="small" {...restProps} />
    </Box>
  );
};

FilterSelect.defaultProps = {
  placeholder: 'Select value',
};

export default FilterSelect;
