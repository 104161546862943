import React from 'react';

import Image from 'components/images/Image';
import UploadFilesState, {
  bindViewImageTrigger,
  bindViewImageDialog,
} from 'components/uploadFiles/UploadFilesState';
import ViewMediaDialog from 'components/uploadFiles/ViewMediaDialog';

type Props = {
  value: string;
  displayType?: 'url' | 'image';
};

const ImageCell = ({ value, displayType = 'image' }: Props) => {
  if (!value) return null;

  return (
    <UploadFilesState>
      {fileState => (
        <>
          {displayType === 'image' && (
            <Image src={value} width={50} height={50} {...bindViewImageTrigger(fileState, value)} />
          )}
          {displayType === 'url' && <div {...bindViewImageTrigger(fileState, value)}>{value}</div>}
          <ViewMediaDialog {...bindViewImageDialog(fileState)} />
        </>
      )}
    </UploadFilesState>
  );
};

export default ImageCell;
