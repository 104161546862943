import BaseModel, { BaseModelAttrNames } from './baseModel';

import { Obj } from 'constants/types';
import { ResidentialType } from './segment';

export type GalleryPhotos = {
  id?: number | null;
  photoUri?: string;
};

export type Gallery = {
  id?: number | null;
  photos?: GalleryPhotos[];
};

export enum CollectionAttr {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  DESCRIPTION_TITLE = 'descriptionTitle',
  COMPLETED = 'completed',
  IS_DEFAULT = 'isDefault',
  IS_APPLIED = 'isApplied',
}

export enum CollectionUploadId {
  BackgroundImage = 'background-image',
  SignatureImage = 'signature-image',
  ThumbnailImage = 'thumbnail-image',
}

class DesignCollection extends BaseModel<DesignCollection> {
  id?: number;

  name?: string;

  completed?: boolean;

  tag?: string;

  code?: string;

  layoutId?: number;

  imageUrl?: string;

  imageUriToUpload: string = ''; // field to store image base64 to upload and save to imageUrl

  thumbnailImageUri?: string;

  thumbnailToUpload: string = ''; // field to store image base64 to upload and save to thumbnailImageUri

  type?: ResidentialType = ResidentialType.Hospitality; // todo: make it able to change type

  isSignature?: boolean;

  gallery?: Gallery | Obj;

  description?: string;

  descriptionTitle?: string;

  backgroundModuleImageUri?: string;

  isApplied?: boolean;

  isDefault?: boolean;

  static _attrNames: DesignCollectionFields[] = [
    'id',
    'name',
    'tag',
    'code',
    'layoutId',
    'imageUrl',
    'imageUriToUpload',
    'type',
    'isSignature',
    'description',
    'descriptionTitle',
    'backgroundModuleImageUri',
  ];

  protected _getAttrNames(): DesignCollectionFields[] {
    return DesignCollection._attrNames;
  }

  constructor(attrs?: Partial<DesignCollectionValues>) {
    super();
    if (attrs) {
      this.assign(attrs);
    }
  }

  initData({
    id,
    name,
    completed,
    tag,
    code,
    layoutId,
    imageUrl,
    imageUriToUpload,
    type,
    isSignature,
    gallery,
    thumbnailImageUri,
    description,
    descriptionTitle,
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (completed !== undefined) this.completed = completed;
    if (tag !== undefined) this.tag = tag;
    if (code !== undefined) this.code = code;
    if (layoutId !== undefined) this.layoutId = layoutId;
    if (imageUrl !== undefined) this.imageUrl = imageUrl;
    if (imageUriToUpload !== undefined) this.imageUriToUpload = imageUriToUpload;
    if (thumbnailImageUri !== undefined) this.thumbnailImageUri = thumbnailImageUri;
    if (type !== undefined) this.type = type;
    if (isSignature !== undefined) this.isSignature = isSignature;
    if (description !== undefined) this.description = description;
    if (descriptionTitle !== undefined) this.descriptionTitle = descriptionTitle;
    this.gallery = gallery || {
      id: null,
      photos: [],
    };
    return this;
  }

  fromPayload({
    id,
    name,
    completed,
    tag,
    code,
    layoutId,
    backgroundModuleImageUri,
    thumbnailImageUri,
    type,
    isSignature,
    gallery,
    description,
    descriptionTitle,
    isApplied,
    isDefault,
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (completed !== undefined) this.completed = completed;
    if (tag !== undefined) this.tag = tag;
    if (code !== undefined) this.code = code;
    if (layoutId !== undefined) this.layoutId = layoutId;
    if (backgroundModuleImageUri !== undefined) {
      this.imageUrl = backgroundModuleImageUri;
      this.backgroundModuleImageUri = backgroundModuleImageUri;
    }
    if (thumbnailImageUri !== undefined) this.thumbnailImageUri = thumbnailImageUri;
    if (type !== undefined) this.type = type;
    if (isSignature !== undefined) this.isSignature = isSignature;
    if (gallery !== undefined) this.gallery = gallery || [];
    if (description !== undefined) this.description = description;
    if (descriptionTitle !== undefined) this.descriptionTitle = descriptionTitle;
    if (isApplied !== undefined) this.isApplied = isApplied;
    if (isDefault !== undefined) this.isDefault = isDefault;
    this.gallery = gallery || {
      id: null,
      photos: [],
    };
    return this;
  }

  toPayload(): Obj {
    return {
      name: this.name,
      tag: this.tag,
      code: this.code,
      layoutId: this.layoutId,
      backgroundModuleImageUri: this.imageUrl,
      thumbnailImageUri: this.thumbnailImageUri,
      type: this.type,
      isSignature: this.isSignature,
      gallery: this.gallery,
      description: this.description,
      descriptionTitle: this.descriptionTitle,
    };
  }
}

export type DesignCollectionValues = Omit<DesignCollection, BaseModelAttrNames>;

export type DesignCollectionFields = keyof Omit<DesignCollection, BaseModelAttrNames>;

export default DesignCollection;
