const {
  REACT_APP_API_PMS_HOSTNAME = '',
  REACT_APP_API_PMS_HOSTNAME_V3 = '',
  REACT_APP_GOOGLE_MAP_API_KEY = '',
  REACT_APP_MUI_DATAGRID_PRO_LICENSE_KEY = '',
} = process.env;

export const GOOGLE_MAP_API_KEY = REACT_APP_GOOGLE_MAP_API_KEY;

export const MUI_DATAGRID_PRO_LICENSE_KEY = REACT_APP_MUI_DATAGRID_PRO_LICENSE_KEY;

export const PMS_API_HOSTNAME = REACT_APP_API_PMS_HOSTNAME;

export const PMS_API_HOSTNAME_V3 = REACT_APP_API_PMS_HOSTNAME_V3;
