import { ReactElement } from 'react';
import styled from 'styled-components';

// COMPONENTS
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

type Props = MuiCheckboxProps & {
  label?: string | ReactElement;
  error?: boolean;
};

const Checkbox = ({ label, error, color, ...checkboxProps }: Props) => {
  const checkboxColor = error ? 'error' : color;

  if (!label) {
    return <MuiCheckbox color={checkboxColor} {...checkboxProps} />;
  }

  return (
    <StyleFormGroup color={checkboxColor}>
      <FormControlLabel
        control={<MuiCheckbox color={checkboxColor} {...checkboxProps} />}
        label={label || ''}
      />
    </StyleFormGroup>
  );
};

const StyleFormGroup = styled(FormGroup)`
  && {
    &.Mui-error {
      color: ${p => p.theme.palette.error.main};
    }
  }

  .MuiCheckbox-color {
    &Error {
      color: ${p => p.theme.palette.error.main};
    }
    &Info {
      color: ${p => p.theme.palette.info.main};
    }
    &Warning {
      color: ${p => p.theme.palette.warning.main};
    }
    &Success {
      color: ${p => p.theme.palette.success.main};
    }
  }
`;

export default Checkbox;
