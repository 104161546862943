// COMPONENTS
import {
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { MouseEvent } from 'react';
import IconButton from 'components/buttons/IconButton';
import { TableColDef } from '../constants/dataTableConstants';

// ICONS
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

// UTILS
import { displayCell } from '../helpers/columnHelpers';

type Props = GridRenderCellParams & {
  column: TableColDef;
};

const GroupingCell = ({ column, ...props }: Props) => {
  const { id, field, rowNode } = props;

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event: MouseEvent) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 && (
          <IconButton onClick={handleClick} sx={{ ml: -2 }} size="small">
            {!rowNode.childrenExpanded ? <AddOutlinedIcon /> : <RemoveOutlinedIcon />}
          </IconButton>
        )}
        {displayCell(column, props)}
      </div>
    </Box>
  );
};

export default GroupingCell;
