import { useEffect } from 'react';

// COMPONENTS
import ConfirmDialog, { ConfirmDialogProps } from 'components/dialogs/ConfirmDialog';

// HOOKS
import { useDialog } from 'hooks';

type DialogInputProps = {
  dialogType: DialogType;
} & Omit<ConfirmDialogProps, 'open' | 'onClose'>;

// eslint-disable-next-line
export let openDialogContainer = (props: DialogInputProps) => {};

type DialogType = 'confirm';

const DialogContainer = () => {
  const { openDialog, dialogProps, dialogState } = useDialog<DialogInputProps>();

  useEffect(() => {
    openDialogContainer = (props: DialogInputProps) => openDialog(props);
    // eslint-disable-next-line
  }, []);

  if (!dialogState) return null;

  const { dialogType, ...restInputProps } = dialogState;

  if (dialogType === 'confirm') {
    return <ConfirmDialog {...restInputProps} {...dialogProps} />;
  }

  return null;
};

export default DialogContainer;
