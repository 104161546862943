import BaseModel, { BaseModelAttrNames } from './baseModel';

// CONSTANTS
import { PartialValues, Obj } from 'constants/types';

type LayoutQuantityMetric = {
  layoutId: number;
  layoutName: string;
  quantity: number;
  productOrderQuantity: number;
};

class ProductQuantityMetric extends BaseModel<ProductQuantityMetric> {
  id?: number;

  imsId?: number;

  productIms?: number;

  productId?: number;

  productName?: string;

  hsCode?: string;

  description?: string;

  category?: string;

  unit?: string;

  manufacture?: string;

  countryOfOrigin?: string;

  allocationForWastage?: number | null;

  totalNumberOfOrder?: number;

  totalOrderQuantity?: number;

  listLayoutQuantity?: LayoutQuantityMetric[];

  layoutName?: string;

  placeholderName?: string;

  roomName?: string;

  purchaseContractPrice?: number;

  materialUnitRate?: number;

  basePriceForBuyers?: number;

  unitPriceForBuyers?: number;

  finalPrice?: number;

  productOrderQuantity?: string;

  static _attrNames: ProductQuantityMetricFields[] = [
    'id',
    'imsId',
    'productIms',
    'productId',
    'productName',
    'hsCode',
    'description',
    'category',
    'unit',
    'manufacture',
    'countryOfOrigin',
    'allocationForWastage',
    'totalNumberOfOrder',
    'totalOrderQuantity',
    'listLayoutQuantity',
    'layoutName',
    'placeholderName',
    'roomName',
    'purchaseContractPrice',
    'materialUnitRate',
    'basePriceForBuyers',
    'unitPriceForBuyers',
    'finalPrice',
    'productOrderQuantity',
  ];

  protected _getAttrNames(): ProductQuantityMetricFields[] {
    return ProductQuantityMetric._attrNames;
  }

  constructor(attrs?: PartialValues<ProductQuantityMetricValues>) {
    super();
    if (attrs) {
      this.assign(attrs);
    }
  }

  fromPayload({
    id,
    productId,
    productIms,
    imsId,
    productName,
    hsCode,
    description,
    category,
    unit,
    manufacture,
    countryOfOrigin,
    allocationForWastage,
    totalNumberOfOrder,
    totalOrderQuantity,
    listLayoutQuantity,
    layout,
    layoutName,
    placeholderName,
    roomName,
    purchaseContractPrice,
    materialUnitRate,
    basePriceForBuyers,
    unitPriceForBuyers,
    finalPrice,
    productOrderQuantity,
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (productId !== undefined) this.productId = productId;
    if (imsId !== undefined) this.imsId = imsId;
    if (productIms !== undefined) this.productIms = productIms;
    if (productName !== undefined) this.productName = productName;
    if (hsCode !== undefined) this.hsCode = hsCode;
    if (description !== undefined) this.description = description;
    if (category !== undefined) this.category = category;
    if (unit !== undefined) this.unit = unit;
    if (manufacture !== undefined) this.manufacture = manufacture;
    if (countryOfOrigin !== undefined) this.countryOfOrigin = countryOfOrigin;
    if (allocationForWastage !== undefined) this.allocationForWastage = allocationForWastage;
    if (totalNumberOfOrder !== undefined) this.totalNumberOfOrder = totalNumberOfOrder;
    if (totalOrderQuantity !== undefined) this.totalOrderQuantity = totalOrderQuantity;
    if (listLayoutQuantity !== undefined) this.listLayoutQuantity = listLayoutQuantity;
    if (layout !== undefined || layoutName !== undefined) {
      this.layoutName = layout || layoutName;
    }
    if (placeholderName !== undefined) this.placeholderName = placeholderName;
    if (roomName !== undefined) this.roomName = roomName;
    if (purchaseContractPrice !== undefined) this.purchaseContractPrice = purchaseContractPrice;
    if (materialUnitRate !== undefined) this.materialUnitRate = materialUnitRate;
    if (basePriceForBuyers !== undefined) this.basePriceForBuyers = basePriceForBuyers;
    if (unitPriceForBuyers !== undefined) this.unitPriceForBuyers = unitPriceForBuyers;
    if (finalPrice !== undefined) this.finalPrice = finalPrice;
    if (productOrderQuantity !== undefined) this.productOrderQuantity = productOrderQuantity;

    return this;
  }
}

export type ProductQuantityMetricValues = Omit<ProductQuantityMetric, BaseModelAttrNames> & {
  segmentGroupId?: number;
};

export type ProductQuantityMetricFields = keyof Omit<ProductQuantityMetric, BaseModelAttrNames>;

export default ProductQuantityMetric;
