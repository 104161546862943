import { useState } from 'react';

// COMPONENTS
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from 'components/inputs/TextField';

// CONSTANTS
import { TableColFilter } from '../constants/dataTableConstants';

export type FilterTextProps = Pick<TableColFilter, 'filterField'>;

type Props = GridFilterInputValueProps & FilterTextProps;

const FilterText = ({ item, filterField, applyValue, ...restProps }: Props) => {
  const [value, setValue] = useState<TextFieldProps['value']>(() => item?.value || undefined);

  const handleBlur: TextFieldProps['onBlur'] = () => {
    const nextItem: typeof item = { ...item, value };
    if (filterField) nextItem.filterField = filterField;

    applyValue(nextItem);
  };

  return (
    <Box display="inline-flex" alignItems="center" height={65}>
      <TextField
        value={value}
        onChange={event => setValue(event.target.value)}
        onBlur={handleBlur}
        variant="standard"
        fullWidth
        {...restProps}
      />
    </Box>
  );
};

FilterText.defaultProps = {
  placeholder: 'Filter value',
};

export default FilterText;
