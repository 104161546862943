import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

type Props = PropsWithChildren<{
  aspect: number;
}>;

const AspectBox = ({ aspect, children }: Props) => (
  <Box sx={{ position: 'relative', overflow: 'hidden' }}>
    <Box sx={{ width: '100%', height: 0, paddingBottom: 100 / aspect + '%' }}>
      <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>{children}</Box>
    </Box>
  </Box>
);

export default AspectBox;
