import { Controller, UseControllerProps } from 'react-hook-form';

// COMPONENTS
import InputSelect, { SelectProps as InputSelectProps } from 'components/inputs/Select';
import { useFormContext } from './Form';

export type SelectFieldProps = InputSelectProps & {
  name: string;
  control?: UseControllerProps['control'];
  defaultValue?: string | number | boolean;
  messageErr?: string;
};

const Select = ({ control, required, defaultValue, name, disabled, ...rest }: SelectFieldProps) => {
  const { formControl, formDisabled } = useFormContext();

  return (
    <Controller
      name={name}
      control={control || formControl}
      defaultValue={defaultValue}
      render={({ field: { onChange, value = '' }, fieldState: { error } }) => {
        return (
          <InputSelect
            value={value || ''}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
            required={required}
            disabled={disabled || formDisabled}
            {...rest}
          />
        );
      }}
    />
  );
};

Select.defaultProps = {
  fullWidth: true,
};

export default Select;
