import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';

type Props = Omit<RouterLinkProps, 'component'> &
  Omit<MuiLinkProps, 'href' | 'color'> & {
    color?: 'accent' | string;
  };

const Link = ({ color, to, sx = {}, ...props }: Props) => {
  if (color) {
    // @ts-ignore
    sx.color = color === 'accent' ? `${color}.main` : color;
  }

  return (
    <MuiLink
      to={!props.onClick ? to : 'javascript:void(0)'}
      component={RouterLink}
      sx={sx}
      {...props}
    />
  );
};

export default Link;
