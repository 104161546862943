import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

// COMPONENTS
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button, { ButtonProps } from 'components/buttons/Button';
import ExportButton, { ExportButtonProps } from 'components/buttons/ExportButton';

// ICONS
import CloseIcon from '@mui/icons-material/Close';

type DialogButtonActionType = 'close' | 'export' | string;

type DialogBasicButtonProps = ButtonProps & {
  label?: string;
  actionType?: DialogButtonActionType;
};

type DialogExportButtonProps = DialogBasicButtonProps & {
  exportFileName?: ExportButtonProps['exportFileName'];
  onLoadExportData?: ExportButtonProps['onLoadExportData'];
};

export type DialogButtonProps = DialogBasicButtonProps | DialogExportButtonProps;

export type DialogProps = MuiDialogProps & {
  fullHeight?: boolean;
  title?: string;
  buttons?: DialogButtonProps[];
  onClose: () => void;
};

const Dialog = ({ title, buttons, onClose, children, ...restProps }: DialogProps) => {
  const genPreDefinedButton = (
    actionType: DialogButtonActionType,
    label: string | undefined,
    buttonProps: DialogButtonProps,
    key: number
  ): ReactNode => {
    switch (actionType) {
      case 'close':
        return (
          <Button key={key} color="secondary" onClick={() => onClose()} {...buttonProps}>
            {label || 'Close'}
          </Button>
        );

      case 'export':
        return <ExportButton key={key} label={label} {...buttonProps} />;

      default:
        return null;
    }
  };

  return (
    <MuiDialog {...restProps}>
      {title && (
        <DialogTitle>
          {title}
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose as IconButtonProps['onClick']}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent className="dialog-body">{children}</DialogContent>

      {buttons && (
        <DialogContent className="dialog-footer">
          <DialogActions>
            {buttons.map(({ label, actionType, ...buttonProps }, key) => {
              if (actionType) {
                return genPreDefinedButton(actionType, label, buttonProps, key);
              }

              return (
                <Button key={key} {...buttonProps}>
                  {label}
                </Button>
              );
            })}
          </DialogActions>
        </DialogContent>
      )}
    </MuiDialog>
  );
};

const StyledDialog = styled(Dialog)`
  ${p =>
    p.fullHeight &&
    css`
      .dialog-body {
        height: 100vh;
      }
    `}

  .MuiDialog-paper {
    background-image: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .MuiDialogTitle-root {
    border-bottom: 1px solid #d4d2db;
  }

  .MuiDialogContent-root {
    padding-top: 1rem;
  }

  .dialog-footer {
    border-top: solid 1px #d4d2db;
    padding: 0 1rem;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .MuiDialogActions-root {
      padding: 0;
    }

    .MuiButton-root {
      min-width: 10rem;
    }
  }
`;

export default StyledDialog;
