// COMPONENTS
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/buttons/Button';
import TextField from 'components/formControls/TextField';
import Select from 'components/formControls/Select';

import { IconButton } from '@mui/material';

// HOOKS
import { useForm, UseFormReturn } from 'react-hook-form';

// CONSTANTS
import { Obj, SelectOption } from 'constants/types';

// ICONS
import RefreshIcon from '@mui/icons-material/Refresh';

// STYLES
import styled from 'styled-components';

type TInputType = 'text' | 'select';

export type TInputList = {
  type: TInputType;
  label: string;
  defaultValue: string;
  name: string;
};

interface NavSearchProps {
  inputList: TInputList[];
  selectOptions?: SelectOption[];
  onApply?: (values: Obj) => void;
  onClear?(): void;
}
const NavSearch = ({ inputList, selectOptions, onApply }: NavSearchProps) => {
  const form: UseFormReturn = useForm();
  const { control, getValues, setValue } = form;

  const handleApply = (): void => {
    const formValue = getValues();
    if (onApply) {
      onApply(formValue);
    }
  };

  const handleClear = (): void => {
    inputList.forEach(item => {
      setValue(item.name, item.defaultValue);
    });
    handleApply();
  };

  const renderInput = () => {
    return inputList.map(input => {
      switch (input.type) {
        case 'text':
          return (
            <TextField
              label={input.label}
              variant="outlined"
              control={control}
              name={input.name}
              sx={{ mr: '20px' }}
              defaultValue={input.defaultValue}
            />
          );
        case 'select':
          return (
            <Select
              name={input.name}
              label={input.label}
              sx={{ width: 300 }}
              control={control}
              options={selectOptions}
              defaultValue={input.defaultValue}
              noneLabel=""
            />
          );
      }
    });
  };

  return (
    <>
      <form id={'placeholder-form'} noValidate>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '1rem 0' }}>
          {renderInput()}
          <ResetButton onClick={() => handleClear()}>
            <RefreshIcon />
            <Typography color="accent" sx={{ mr: '10px', fontSize: '12px' }}>
              Clear
            </Typography>
          </ResetButton>

          {
            <Button color="accent" onClick={() => handleApply()}>
              Apply
            </Button>
          }
        </Box>
      </form>
    </>
  );
};

const ResetButton = styled(IconButton)`
  font-size: 0.8rem;
`;

export default NavSearch;
