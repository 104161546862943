import { useCallback, useState } from 'react';

// UTILS
import { readFileToDataUri } from 'utils/fileUtils';

type BindUploadParams = {
  uploadId?: string;
  aspect?: number;
};

type State = {
  uploadingDataUri?: string;
  viewingMediaUrl?: string;
  multipleUploadingDataUri?: string[];
} & BindUploadParams;

type Actions = {
  loadFiles: (files: File[], uploadParams?: BindUploadParams) => void;
  resetState: () => void;
  onClickViewImage: (url: string) => void;
};

type UploadFilesState = State & Actions;

type Props = {
  children: (uploadFilesState: UploadFilesState) => JSX.Element;
};

const UploadFilesStateWrapper = ({ children }: Props) => {
  const [state, setState] = useState<State>({});

  const resetState = useCallback(() => {
    setState({});
  }, []);

  const loadFiles = useCallback(async (files: File[], uploadParams?: BindUploadParams) => {
    if (files && files.length > 0) {
      const uploadingDataUri = await readFileToDataUri(files[0]);

      let multipleUploadingDataUri: string[] = [];
      for (let i = 0; i < files.length; i++) {
        const uploadingDataUriItem = await readFileToDataUri(files[i]);
        multipleUploadingDataUri.push(uploadingDataUriItem);
      }

      setState({
        uploadingDataUri,
        multipleUploadingDataUri,
        ...uploadParams,
      });
    }
  }, []);

  const onClickViewImage = useCallback((imageUrl: string) => {
    setState({ viewingMediaUrl: imageUrl });
  }, []);

  const uploadFileState = {
    ...state,
    loadFiles,
    resetState,
    onClickViewImage,
  };

  return children(uploadFileState);
};

export const bindUploadDropzone = (
  { loadFiles }: UploadFilesState,
  uploadParams: BindUploadParams = {}
) => ({
  onChange: (files: File[]) => loadFiles(files, uploadParams),
});

export const bindCropImageDialog = ({
  uploadingDataUri,
  multipleUploadingDataUri,
  resetState,
  uploadId,
  aspect,
}: UploadFilesState) => {
  // eslint-disable-next-line
  const cropProps: Partial<Record<'uploadId' | 'aspect', any>> = {};

  if (uploadId) cropProps.uploadId = uploadId;
  if (aspect) cropProps.aspect = aspect;

  console.log('updateFileState', {
    src: uploadingDataUri,
    multipleFiles: multipleUploadingDataUri,
    onClose: resetState,
    ...cropProps,
  });
  return {
    src: uploadingDataUri,
    multipleFiles: multipleUploadingDataUri,
    onClose: resetState,
    ...cropProps,
  };
};

export const bindViewImageTrigger = ({ onClickViewImage }: UploadFilesState, url: string) => ({
  onClick: (event: { stopPropagation: Function }) => {
    event.stopPropagation();
    onClickViewImage(url);
  },
});

export const bindViewImageDialog = ({ viewingMediaUrl, resetState }: UploadFilesState) => ({
  src: viewingMediaUrl,
  onClose: resetState,
});

export default UploadFilesStateWrapper;
