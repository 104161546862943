import { ReactCropProps } from 'react-image-crop';
// import { extractMimeTypeFromDataUri } from './uriUtils';

const genCroppedImgCanvas = (
  image: HTMLImageElement,
  crop: ReactCropProps['crop']
): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const pixelRatio = 1;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  if (ctx && crop.width && crop.height) {
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x || 0,
      crop.y || 0,
      canvas.width,
      canvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
  }

  return canvas;
};

export const getCroppedImgContent = (image: HTMLImageElement, crop: ReactCropProps['crop']) => {
  // const mimeType = extractMimeTypeFromDataUri(image.src);
  const mimeType = 'image/webp';
  const canvas = genCroppedImgCanvas(image, crop);

  // As Base64 string
  return canvas.toDataURL(mimeType);
};

let fileUrl: string;

// todo: undone
export const getCroppedImgFile = (
  image: HTMLImageElement,
  crop: ReactCropProps['crop'],
  fileName: string
) => {
  const canvas = genCroppedImgCanvas(image, crop);

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        console.log('blob', blob);
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        // blob.name = fileName;
        console.log('fileName', fileName);
        if (fileUrl) {
          window.URL.revokeObjectURL(fileUrl);
        }
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      },
      'image/jpeg',
      1
    );
  });
};
