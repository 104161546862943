import TablePagination from 'components/paginations/TablePagination';

import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

type Props = {
  hideRowPerPageSelector?: boolean;
  rowsLabel?: string;
};

const DataTablePagination = ({ hideRowPerPageSelector, rowsLabel }: Props) => {
  const apiRef = useGridApiContext();
  const paginationState = useGridSelector(apiRef, gridPaginationSelector);

  return (
    <TablePagination
      {...paginationState}
      hideRowPerPageSelector={hideRowPerPageSelector}
      rowsLabel={rowsLabel}
      onPageChange={newPage => apiRef.current.setPage(newPage)}
      onPageSizeChange={newPageSize => apiRef.current.setPageSize(newPageSize)}
    />
  );
};

export default DataTablePagination;
