// ICONS
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
// import CategoryIcon from '@mui/icons-material/Category';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';

export enum MenuFunctionKey {
  LogOut = 'logout',
}

export type TMenu = {
  label: string;
  icon?: JSX.Element;
  path?: string;
  functionKey?: MenuFunctionKey;
  subMenu?: {
    label: string;
    path?: string;
  }[];
};

export const MAIN_MENU: TMenu[] = [
  {
    label: 'Projects',
    icon: <HomeOutlinedIcon />,
    subMenu: [
      {
        label: 'Manage Projects',
        path: '/projects',
      },
      {
        label: 'Architects',
        path: '/architects',
      },
    ],
  },
  {
    label: 'Banks',
    icon: <AccountBalanceOutlinedIcon />,
    path: '/banks',
  },
  {
    label: 'Mortgages',
    icon: <MonetizationOnOutlinedIcon />,
    path: '/mortgages',
  },
  {
    label: 'Design Hub Management',
    icon: <AutoFixHighIcon />,
    path: '/design-hub',
  },
  {
    label: 'Languages',
    icon: <TranslateIcon />,
    path: '/languages',
  },
  {
    label: 'Sale Agent Management',
    icon: <AccountCircle />,
    path: '/agents',
  },
  {
    label: 'Users',
    icon: <AccountCircle />,
    path: '/users',
  },
  {
    label: 'Countries',
    icon: <LanguageIcon />,
    path: '/countries',
  },
  {
    label: 'White Label',
    icon: <AutoFixHighIcon />,
    path: '/white-label',
  },
  {
    label: 'Master Management',
    icon: <ViewComfyIcon />,
    subMenu: [
      {
        label: 'Master Layout',
        path: '/master-layout',
      },
      {
        label: 'Master Collection',
        path: '/master-collection',
      },
    ],
  },
  // {
  //   label: 'Master Product List',
  //   icon: <CategoryIcon />,
  //   path: '/master-product',
  // },
  {
    label: 'Landing Page',
    icon: <HomeOutlinedIcon />,
    subMenu: [
      {
        label: 'Our Story',
        path: '/our-story',
      },
      {
        label: 'In The Spotlight',
        path: '/in-the-spotlight',
      },
    ],
  },
  {
    label: 'Logging Report',
    icon: <ViewComfyIcon />,
    subMenu: [
      {
        label: 'Odoo Sync Log',
        path: '/sync-log',
      },
      {
        label: 'Boq Calculation Log',
        path: '/boq-log',
      },
    ],
  },
];

export const EXCEPT_MENU: string[] = ['/users'];

export const PROFILE_MENU: TMenu[] = [
  // {
  //   label: 'Profile',
  //   icon: <PersonOutlineIcon />,
  // },
  {
    label: 'Log Out',
    icon: <LogoutIcon />,
    functionKey: MenuFunctionKey.LogOut,
  },
];
