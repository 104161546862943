import React, { useState } from 'react';

// COMPONENTS
import Grid from '@mui/material/Grid';
import TextField from 'components/inputs/TextField';
import Select, { SelectProps } from 'components/inputs/Select';

// CONSTANTS
import { Obj, Any } from 'constants/types';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

export type FormField<N extends string = string> = {
  type: 'text' | 'select';
  label: string;
  name: N;
  selectOptions?: SelectProps['options'];
};

type ColumnNumber = 1 | 2 | 3 | 4;

export type ColumnsFormProps = {
  columnNumber?: ColumnNumber;
  fields: FormField[];
  values?: Obj;
  onValuesChanged?: (values: Obj) => void;
};

const ColumnsForm = ({ columnNumber = 2, fields, values, onValuesChanged }: ColumnsFormProps) => {
  const [formValues, setFormValues] = useState<Obj>(() =>
    fields.reduce((initValues, { name }) => ({ ...initValues, [name]: null }), {})
  );

  const updateValues = (name: string, value: Any) => {
    if (values) {
      const newValues = { ...values };
      newValues[name] = value;
      if (onValuesChanged) onValuesChanged(newValues);
    } else {
      const newFormValues = { ...formValues };
      newFormValues[name] = value;
      setFormValues(newFormValues);
      if (onValuesChanged) onValuesChanged(newFormValues);
    }
  };

  const fieldValues = values || formValues;

  const handleChangeInput: StandardInputProps['onChange'] = e =>
    updateValues(e.target.name, e.target.value);

  const handleChangeSelect: SelectProps['onChange'] = e =>
    updateValues(e.target.name, e.target.value);

  return (
    <Grid container spacing={2.5}>
      {fields.map(({ type, selectOptions, ...field }) => (
        <Grid key={field.name} item xs={12 / columnNumber}>
          {type === 'text' && (
            <TextField
              fullWidth
              value={fieldValues[field.name] || ''}
              onChange={handleChangeInput}
              {...field}
            />
          )}
          {type === 'select' && (
            <Select
              fullWidth
              options={selectOptions}
              value={fieldValues[field.name] || ''}
              onChange={handleChangeSelect}
              {...field}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ColumnsForm;
