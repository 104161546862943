import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

// COMPONENTS
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
// import Tooltip from '@mui/material/Tooltip';
import TextField, { TextFieldProps } from './TextField';

// ICONS
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// UTILS
import { maxLengthInCollection } from 'utils/stringUtils';

// CONSTANTS
import { SelectOption } from 'constants/types';

const PIXEL_PER_CHAR = 10;

type SearchSelectAutocompleteProps = AutocompleteProps<SelectOption, false, boolean, undefined>;

type OnChangeParams = Parameters<Required<SearchSelectAutocompleteProps>['onChange']>;

type Value = SelectOption['value'] | undefined;

type OnChange = (
  event: OnChangeParams[0],
  value: Value,
  reason: OnChangeParams[2],
  details?: OnChangeParams[3]
) => void;

const filterOptionsFunc: SearchSelectAutocompleteProps['filterOptions'] = (options, state) => {
  if (!state.inputValue) return options;

  const inputValue = state.inputValue.toLowerCase();

  if (options[0]?.searchText !== undefined) {
    return options.filter(option => option.searchText!.toLowerCase().includes(inputValue));
  }

  if (typeof options[0]?.label === 'string') {
    return options.filter(option => (option.label as string).toLowerCase().includes(inputValue));
  }

  return options;
};

export type SearchSelectProps = Omit<
  SearchSelectAutocompleteProps,
  | 'renderInput'
  | 'renderTags'
  | 'renderGroup'
  | 'freeSolo'
  | 'disableCloseOnSelect'
  | 'filterSelectedOptions'
  | 'onChange'
  | 'value'
  | 'defaultValue'
  | 'multiple'
  | 'options'
> &
  Pick<TextFieldProps, 'label' | 'placeholder' | 'variant' | 'error' | 'helperText'> & {
    options?: SelectOption[];
    onChange?: OnChange;
    value?: Value;
    defaultValue?: Value;
    displayEmpty?: boolean;
    noneLabel?: string;
    required?: boolean;
    autoWidth?: boolean;
    getValueDisabled?: (value: SelectOption['value']) => boolean;
    onValueChange?: (value: Value) => void;
  };

const SearchSelect = ({
  label,
  placeholder,
  variant,
  error,
  helperText,
  value: propsValue,
  defaultValue,
  noneLabel = 'None',
  displayEmpty,
  options,
  required,
  onChange,
  getValueDisabled,
  filterOptions,
  onValueChange,
  autoWidth,
  ...restProps
}: SearchSelectProps) => {
  const [value, setValue] = useState<Value>(defaultValue);

  const paperWidth = useMemo(() => {
    if (!autoWidth || !options?.length) return undefined;

    const maxLength = maxLengthInCollection(options!, ['label', 'groupedTitle']);
    return maxLength * PIXEL_PER_CHAR;
  }, [autoWidth, options?.length]);

  const handleChange: SearchSelectAutocompleteProps['onChange'] = (
    event,
    changeValue,
    reason,
    details
  ) => {
    const newValue = changeValue?.value;

    if (onChange) {
      onChange(event, newValue, reason, details);
    }

    if (onValueChange) {
      onValueChange(newValue);
    }

    if (propsValue !== undefined) return;

    setValue(newValue);
  };

  const selectValue: SearchSelectAutocompleteProps['value'] = useMemo(() => {
    const val = propsValue !== undefined ? propsValue : value;

    if (val !== undefined) {
      return (options || []).find(option => option.value === val);
    }
  }, [propsValue, value, options]);

  return (
    <Autocomplete
      options={options || []}
      value={selectValue || null}
      renderInput={({ ...renderInputProps }) => {
        return (
          <TextField
            required={required}
            focused={displayEmpty}
            label={label}
            variant={variant}
            placeholder={
              placeholder || (displayEmpty && selectValue === undefined) ? noneLabel : undefined
            }
            error={error}
            helperText={helperText}
            {...renderInputProps}
          />
        );
      }}
      filterOptions={filterOptions || filterOptionsFunc}
      popupIcon={!restProps.disabled ? <ExpandMoreOutlinedIcon /> : null}
      filterSelectedOptions
      onChange={handleChange}
      componentsProps={{
        paper: {
          sx: {
            width: paperWidth,
          },
        },
      }}
      {...restProps}
    />
  );
};

const StyledSearchSelect = styled(SearchSelect)`
  .MuiAutocomplete-inputRoot {
    padding: 0;
  }

  .MuiAutocomplete-groupLabel {
    color: red;
  }

  .MuiAutocomplete-tag {
    margin: 2px;
  }
`;

export default StyledSearchSelect;
