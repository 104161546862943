import { useMemo } from 'react';
import { useForm as useReactForm, UseFormProps, UseFormReturn, FieldValues } from 'react-hook-form';
import { unstable_usePrompt } from 'react-router-dom';
import { cloneDeep } from 'lodash';

// Add-on for "react-hook-form"
const useForm = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
  props?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext> => {
  // Clone to make sure change value in inputs not modify value of original object
  const safeDefaultValues = useMemo(
    () => (props?.defaultValues ? cloneDeep(props.defaultValues) : undefined),
    // eslint-disable-next-line
    []
  );

  const form = useReactForm(
    props
      ? {
          ...props,
          defaultValues: safeDefaultValues,
        }
      : undefined
  );

  // Show message when leave without saving
  unstable_usePrompt({
    message: 'Changes you made may not be saved. Are you sure?',
    when: form.formState.isDirty && !form.formState.isSubmitted,
  });

  return form;
};

export default useForm;
