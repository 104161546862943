import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { GridRowIdGetter } from '@mui/x-data-grid-pro';
import DataTable, {
  TableEnrichedColDef,
  TableActionColDef,
  DataTableProps,
} from 'components/dataTables/DataTable';
import Button from 'components/buttons/Button';
import ColumnsFormDialog, { FormDialogProps } from 'components/dialogs/ColumnsFormDialog';
import { TInputList } from 'components/navSearch/NavSearch';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { TKeySearchByType } from 'pages/designHubs/DesignHubList';
import NavSearch from 'components/navSearch/NavSearch';
// import Popconfirm from 'components/popovers/Popconfirm';

// ICONS
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RepeatIcon from '@mui/icons-material/Repeat';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

// CONSTANTS
import { Obj, Any, ID } from 'constants/types';
import { SelectOption } from 'constants/types';
import { StatusLayout } from 'models/layout';
import Select from 'components/inputs/Select';
import { Stack } from '@mui/material';

type PredefinedActionType = 'edit' | 'view' | 'delete' | 'status' | 'active' | 'viewColumnDetail';
// | 'viewColumnError'

type ActionParams = Parameters<TableActionColDef['getActions']>[0];

type ActionOption = {
  type?: PredefinedActionType;
  icon?: JSX.Element;
  label?: string;
  columnName?: string;
  onClick?: (params: ActionParams) => void;
};

type ListActionsColDef = {
  type: 'listAction';
  getActionOptions: (params: ActionParams) => ActionOption[];
};

const LIST_ACTION_ICON_MAP: {
  [key in PredefinedActionType | '']?: { label: string; icon: JSX.Element };
} = {
  edit: {
    label: 'Edit',
    icon: <EditIcon fontSize="medium" />,
  },
  view: {
    label: 'View',
    icon: <VisibilityIcon fontSize="medium" />,
  },
  status: {
    label: 'Status',
    icon: <RepeatIcon fontSize="medium" />,
  },
  delete: {
    label: 'Delete',
    icon: <DeleteIcon fontSize="medium" />,
  },
  active: {
    label: 'Active',
    icon: <LightbulbIcon fontSize="medium" />,
  },
  viewColumnDetail: {
    label: 'View Column',
    icon: <VisibilityIcon fontSize="medium" />,
  },
  // viewColumnError: {
  //   label: 'View Column',
  //   icon: <VisibilityIcon fontSize="medium" />,
  // },
};

export type ListViewTableColumn<F extends string = string> =
  | TableEnrichedColDef<F>
  | ListActionsColDef;

export type ListViewProps = Pick<
  DataTableProps,
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'rowCount'
  | 'sortModel'
  | 'onSortModelChange'
  | 'enableToolbar'
  | 'hideFooter'
  | 'filterMode'
  | 'onFilterModelChange'
> & {
  title?: string | ReactElement;
  editable?: boolean;
  itemList?: Obj[];
  tableColumns: ListViewTableColumn[];
  tableClientMode?: boolean;
  searchFormColumnNumber?: FormDialogProps['columnNumber'];
  searchParams?: Obj;
  advancedSearch?: FormDialogProps['fields'];
  navSearch?: TKeySearchByType;
  navSearchOptions?: SelectOption[];
  masterCreateOptions?: SelectOption[];
  textStatusUpdate?: string;
  textActiveUpdate?: string;
  onSearchValueChanged?: FormDialogProps['onValuesChanged'];
  onSearchChanged?: FormDialogProps['onSubmit'];
  onClickCreate?: () => void;
  onClickEdit?: (id: ID) => void;
  onClickViewColumn?: (id: ID) => void;
  onClickDelete?: (id: ID) => void;
  onClickStatusUpdate?: (id: Obj) => void;
  onClickActiveUpdate?: (id: ID) => void;
  onClickExport?: () => void;
  onClickImport?: () => void;
  onClickMasterCreate?: (id: ID) => void;
  onNavSearchValueChanged?: (values: Obj<{ [key: string]: string }>) => void;
  onNavSearchClear?: () => void;
  getRowId?: GridRowIdGetter;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  customHeader?: ReactNode;
  children?: Any;
  inputList?: TInputList[];
  selectOptions?: SelectOption[];
};

const searchDialogButtons: FormDialogProps['buttons'] = [
  { actionType: 'clearAll' },
  { actionType: 'submit', label: 'Apply' },
];

const ListView = ({
  title,
  editable = true,
  itemList = [],
  sortModel,
  tableColumns,
  tableClientMode = false,
  searchFormColumnNumber,
  searchParams = {},
  advancedSearch,
  navSearch,
  selectOptions = [],
  masterCreateOptions = [],
  textStatusUpdate = 'status',
  textActiveUpdate = 'status',
  onSearchValueChanged,
  onSearchChanged,
  onClickCreate,
  onClickEdit,
  onClickDelete,
  onClickStatusUpdate,
  onClickActiveUpdate,
  onClickExport,
  onClickImport,
  onClickMasterCreate,
  onClickViewColumn,
  customHeader = null,
  inputList = [],
  ...tableProps
}: ListViewProps) => {
  const navigate = useNavigate();

  const [confirmDelete, setConfirmDelete] = useState<{
    anchorEl?: HTMLElement;
    id?: number | string;
  }>({});

  const [confirmStatusUpdate, setConfirmStatusUpdate] = useState<{
    anchorEl?: HTMLElement;
    status?: StatusLayout;
    id?: number | string;
  }>({});

  const [confirmActiveUpdate, setConfirmActiveUpdate] = useState<{
    anchorEl?: HTMLElement;
    id?: number | string;
  }>({});

  const [viewColumnDetail, setViewColumnDetail] = useState<{
    anchorEl?: HTMLElement;
    id?: number | string;
    columnName?: string;
  }>({});

  // const [viewColumnError, setViewColumnError] = useState<{
  //   anchorEl?: HTMLElement;
  //   id?: number | string;
  //   columnName?: string;
  // }>({});

  const [confirmMasterCreate, setConfirmMasterCreate] = useState(false);
  const [selectedMasterCreate, setSelectedMasterCreate] = useState<ID>(0);

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);

  const transformedColumns = useMemo<TableEnrichedColDef[]>(() => {
    const actionMap = {
      edit: onClickEdit,
      view: onClickEdit,
      delete: onClickDelete,
      status: onClickStatusUpdate,
      active: onClickActiveUpdate,
      viewColumnDetail: onClickViewColumn,
      // viewColumnError: onClickViewColumn,
    };

    return tableColumns.map(column => {
      if (column.type !== 'listAction') return column;

      const { getActionOptions } = column as ListActionsColDef;
      return {
        field: 'actions',
        type: 'actions',
        width: 250,
        getActionOptions: params => {
          let actionOptions = getActionOptions(params);

          if (!editable) {
            actionOptions = actionOptions
              .filter(option => option.type !== 'delete')
              .map(option => (option.type === 'edit' ? { ...option, type: 'view' } : option));
          }

          return actionOptions.map(option => ({
            icon: option.icon || LIST_ACTION_ICON_MAP[option.type!]?.icon,
            label: option.label || LIST_ACTION_ICON_MAP[option.type!]?.label || '',
            onClick: event => {
              if (option.onClick) {
                return option.onClick(params);
              }

              if (option.type && actionMap[option.type]) {
                if (option.type === 'delete') {
                  setConfirmDelete({
                    anchorEl: event.currentTarget,
                    id: params.id,
                  });
                } else if (option.type === 'status') {
                  setConfirmStatusUpdate({
                    anchorEl: event.currentTarget,
                    status: params.row?.status,
                    id: params.id,
                  });
                } else if (option.type === 'active') {
                  setConfirmActiveUpdate({
                    anchorEl: event.currentTarget,
                    id: params.id,
                  });
                } else if (option.type === 'viewColumnDetail') {
                  setViewColumnDetail({
                    columnName: 'detail',
                    anchorEl: event.currentTarget,
                    id: params.id,
                  });
                }
                // else if (option.type === 'viewColumnError') {
                //   setViewColumnError({
                //     columnName: 'error',
                //     anchorEl: event.currentTarget,
                //     id: params.id,
                //   });
                // }
                else {
                  actionMap[option.type]?.(params.id);
                }
              }
            },
          }));
        },
      };
    });
    // eslint-disable-next-line
  }, [navigate, tableColumns]);

  return (
    <Wrapper hasSearch={!!advancedSearch || !!customHeader || !!navSearch}>
      <Header>
        <Typography variant="h5">{title}</Typography>
        <div>
          {editable && onClickMasterCreate && (
            <Button color="accent" onClick={() => setConfirmMasterCreate(true)}>
              Master Assign
            </Button>
          )}
          {onClickExport && (
            <Button color="secondary" onClick={onClickExport}>
              Export All
            </Button>
          )}
          {editable && onClickImport && (
            <Button color="secondary" onClick={onClickImport}>
              Import
            </Button>
          )}
          {editable && onClickCreate && (
            <Button color="accent" onClick={onClickCreate}>
              Create New
            </Button>
          )}
        </div>
      </Header>
      <Divider sx={{ mx: -2, my: 1.5 }} />
      {customHeader}
      {advancedSearch && (
        <>
          <section>
            <Button
              color="accentLight"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setAdvancedSearchOpen(true)}
              sx={{ mb: 1 }}
            >
              Filter
            </Button>
          </section>
          <ColumnsFormDialog
            open={advancedSearchOpen}
            fields={advancedSearch}
            title="Filter"
            columnNumber={searchFormColumnNumber}
            defaultValues={searchParams}
            buttons={searchDialogButtons}
            onValuesChanged={onSearchValueChanged}
            onClose={() => setAdvancedSearchOpen(false)}
            onSubmit={onSearchChanged}
          />
        </>
      )}
      {navSearch && (
        <>
          <section>
            <NavSearch
              selectOptions={selectOptions}
              inputList={inputList}
              onApply={onSearchChanged}
            />
          </section>
        </>
      )}
      <section>
        <DataTable
          rows={itemList}
          columns={transformedColumns}
          rowsPerPageOptions={[10, 20, 50]}
          disableColumnMenu
          serverMode={!tableClientMode}
          pagination={!tableProps.hideFooter}
          sortModel={sortModel || (!tableClientMode ? [] : undefined)}
          // loading={!itemList.length}
          {...tableProps}
        />
        <ConfirmDialog
          title="Master Assign"
          open={confirmMasterCreate}
          cancelText="Cancel"
          confirmText="Create New"
          onClose={() => setConfirmMasterCreate(false)}
          message={
            <Select
              fullWidth
              label="Master Assign"
              value={selectedMasterCreate}
              onChange={e => setSelectedMasterCreate(e.target.value as ID)}
              options={masterCreateOptions}
              required
              vertical={true}
            />
          }
          onConfirm={() => {
            if (onClickMasterCreate && confirmMasterCreate) {
              onClickMasterCreate(selectedMasterCreate);
              setSelectedMasterCreate(0);
            }
          }}
        />
        <ConfirmDialog
          open={confirmDelete.id ? true : false}
          cancelText="No"
          onClose={() => setConfirmDelete({})}
          message="Are you sure to delete this item?"
          onConfirm={() => onClickDelete && confirmDelete.id && onClickDelete(confirmDelete.id)}
        />
        <ConfirmDialog
          open={confirmStatusUpdate.id ? true : false}
          cancelText="No"
          onClose={() => setConfirmStatusUpdate({})}
          message={`Are you sure to update ${textStatusUpdate} for this item?`}
          onConfirm={() =>
            onClickStatusUpdate &&
            confirmStatusUpdate.id &&
            onClickStatusUpdate(confirmStatusUpdate)
          }
        />
        <ConfirmDialog
          open={confirmActiveUpdate.id ? true : false}
          cancelText="No"
          onClose={() => setConfirmActiveUpdate({})}
          message={`Are you sure to update ${textActiveUpdate} for this item?`}
          onConfirm={() =>
            onClickActiveUpdate &&
            confirmActiveUpdate.id &&
            onClickActiveUpdate(confirmActiveUpdate.id)
          }
        />

        <ConfirmDialog
          hideCancel={true}
          confirmText="Close"
          open={viewColumnDetail.id ? true : false}
          onClose={() => {}}
          message={((): any => {
            const foundItem = itemList.find(item => item.id === viewColumnDetail.id);

            const columnValue = foundItem?.detail != '' ? foundItem?.detail : '';
            // const columnValueError = foundItem?.errors != '' ? foundItem?.errors : '';

            if (!columnValue) {
              return '';
            }

            const idsArray = columnValue
              .split(': ')[1]
              .split(',')
              .map((id: string) => id.trim());

            const formattedIds = idsArray.map((id: string) => (
              <span
                key={id}
                style={{
                  border: '1px solid black',
                  padding: '5px',
                  margin: '5px',
                  background: '#c5cfbc',
                  borderRadius: '20px',
                }}
              >
                {id}
              </span>
            ));

            const formattedElement = (
              <Stack>
                Synced IMS IDs:{' '}
                <Stack flexDirection={'row'} width={'100%'} flexWrap={'wrap'}>
                  {formattedIds}
                </Stack>
              </Stack>
            );

            return formattedElement;
          })()}
          onConfirm={() => setViewColumnDetail({})}
        />
      </section>
    </Wrapper>
  );
};

ListView.defaultProps = {
  page: 1,
  pageSize: 20,
};

type WrapperProps = {
  hasSearch?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  height: 100%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${p => (p.hasSearch ? 'auto auto auto 1fr' : 'auto auto 1fr')};
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiButton-root {
    width: 10rem;
    margin-left: 0.75rem;
  }
`;

export default ListView;
