// Note: use for countable only
export function getUnitString(quantity: number, unit: string) {
  return `${quantity} ${unit}${quantity > 1 ? 's' : ''}`;
}

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toSnakeCase = (string: string): string =>
  string
    .split(' ')
    .map(val => val.toLowerCase())
    .join('_');

export const maxLengthInCollection = (
  //  eslint-disable-next-line @typescript-eslint/no-explicit-any
  collection: { [name: string]: any }[],
  fields: string[]
): number => {
  let maxLength = 0;

  collection.forEach(el => {
    fields.forEach(field => {
      if (el[field]) {
        const length = `${el[field]}`.length;
        if (length > maxLength) {
          maxLength = length;
        }
      }
    });
  });

  return maxLength;
};
