import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

// COMPONENTS
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Chip, { ChipProps } from '@mui/material/Chip';
import TextField, { TextFieldProps } from './TextField';

// ICONS
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// UTILS
import { maxLengthInCollection } from 'utils/stringUtils';

// CONSTANTS
import { SelectOption } from 'constants/types';

const PIXEL_PER_CHAR = 10;

type SearchSelectAutocompleteProps = AutocompleteProps<SelectOption, true, boolean, undefined>;

type OnChangeParams = Parameters<Required<SearchSelectAutocompleteProps>['onChange']>;

type Value = Array<SelectOption['value']>;

type OnChange = (
  event: OnChangeParams[0],
  value: Value,
  reason: OnChangeParams[2],
  details?: OnChangeParams[3]
) => void;

const filterOptionsFunc: SearchSelectAutocompleteProps['filterOptions'] = (options, state) => {
  if (!state.inputValue) return options;

  const inputValue = state.inputValue.toLowerCase();

  if (options[0]?.searchText !== undefined) {
    return options.filter(option => option.searchText!.toLowerCase().includes(inputValue));
  }

  if (typeof options[0]?.label === 'string') {
    return options.filter(option => (option.label as string).toLowerCase().includes(inputValue));
  }

  return options;
};

export type SearchMultiSelectProps = Omit<
  SearchSelectAutocompleteProps,
  | 'renderInput'
  | 'renderTags'
  | 'renderGroup'
  | 'freeSolo'
  | 'disableCloseOnSelect'
  | 'filterSelectedOptions'
  | 'onChange'
  | 'value'
  | 'defaultValue'
  | 'multiple'
  | 'options'
> &
  Pick<TextFieldProps, 'label' | 'placeholder' | 'variant' | 'error' | 'helperText'> & {
    options?: SelectOption[];
    onChange?: OnChange;
    value?: Value;
    defaultValue?: Value;
    displayEmpty?: boolean;
    noneLabel?: string;
    required?: boolean;
    autoWidth?: boolean;
    getValueDisabled?: (value: SelectOption['value']) => boolean;
    onValueChange?: (value: Value) => void;
  };

const SearchMultiSelect = ({
  label,
  placeholder,
  variant,
  error,
  helperText,
  value: propsValue,
  defaultValue,
  noneLabel = 'None',
  displayEmpty,
  options,
  onChange,
  getValueDisabled,
  filterOptions,
  onValueChange,
  required,
  autoWidth,
  ...restProps
}: SearchMultiSelectProps) => {
  const [value, setValue] = useState<Value>(() => defaultValue || []);

  const paperWidth = useMemo(() => {
    if (!autoWidth || !options?.length) return undefined;

    const maxLength = maxLengthInCollection(options!, ['label', 'groupedTitle']);
    return maxLength * PIXEL_PER_CHAR;
  }, [autoWidth, options?.length]);

  const handleChange: SearchSelectAutocompleteProps['onChange'] = (
    event,
    changeValue,
    reason,
    details
  ) => {
    const newValue = (changeValue || []).map(option => option.value);

    if (onChange) {
      onChange(event, newValue, reason, details);
    }

    if (onValueChange) {
      onValueChange(newValue);
    }

    if (propsValue !== undefined) return;

    setValue(newValue);
  };

  const selectValue: SearchSelectAutocompleteProps['value'] = useMemo(() => {
    const val = propsValue !== undefined ? propsValue : value;
    if (!Array.isArray(val) || val.length === 0) return [];

    return (options || []).filter(option => val?.includes(option.value));
  }, [propsValue, value, options]);

  return (
    <Autocomplete
      options={options || []}
      value={selectValue}
      // renderGroup={({ group, children }) => (
      //   <>
      //     <Typography variant="h6">{group}</Typography>
      //     {children}
      //   </>
      // )}
      renderInput={({ ...inputProps }) => (
        <TextField
          focused={displayEmpty}
          label={label}
          variant={variant}
          placeholder={
            placeholder || (displayEmpty && selectValue?.length === 0) ? noneLabel : undefined
          }
          error={error}
          helperText={helperText}
          required={required}
          {...inputProps}
        />
      )}
      filterOptions={filterOptions || filterOptionsFunc}
      renderTags={(tagValues: SelectOption[], getTagProps) =>
        tagValues.map((tagValue, index: number) => {
          const chipProps: Partial<ChipProps> = getTagProps({ index });
          chipProps.label = tagValue.label;

          if (getValueDisabled) {
            chipProps.disabled = getValueDisabled(tagValue.value);
            chipProps.sx = {
              border: 'solid 1px rgba(0, 0, 0, 0.08)',
            };
          }

          if (tagValue.tooltipTitle) {
            return (
              <Tooltip title={tagValue.tooltipTitle}>
                <Chip size="small" {...chipProps} />
              </Tooltip>
            );
          }

          return <Chip size="small" {...chipProps} />;
        })
      }
      popupIcon={!restProps.disabled ? <ExpandMoreOutlinedIcon /> : null}
      multiple
      disableCloseOnSelect
      filterSelectedOptions
      onChange={handleChange}
      componentsProps={{
        paper: {
          sx: {
            width: paperWidth,
          },
        },
      }}
      {...restProps}
    />
  );
};

const StyledSearchSelect = styled(SearchMultiSelect)`
  && {
    width: 100%;

    .MuiAutocomplete-inputRoot {
      padding: 0;
    }

    .MuiAutocomplete-groupLabel {
      color: red;
    }

    .MuiAutocomplete-tag {
      margin: 2px;
    }
  }
`;

export default StyledSearchSelect;
