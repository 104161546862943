import styled, { css } from 'styled-components';

import {
  DropzoneArea as MuiDropzoneArea,
  DropzoneAreaProps as MuiDropzoneAreaProps,
} from 'react-mui-dropzone';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Box } from '@mui/material';

const MB_TO_B = 1024 * 1024;

// todo: handle drop multiple files "filesLimit"
export type DropzoneAreaProps = Omit<MuiDropzoneAreaProps, 'filesLimit' | 'maxFileSize'> &
  StyledProps & {
    uploadId?: string;
    maxFileSizeMb?: number;
    isLoading?: boolean;
    filesLimit?: number;
  };

const DropzoneArea = ({
  height,
  onChange,
  dropzoneText = 'Browse',
  maxFileSizeMb = 5,
  uploadId,
  isLoading,
  filesLimit = 1,
  ...props
}: DropzoneAreaProps) => {
  return (
    <Section height={height}>
      <UploadWrapper>
        <MuiDropzoneArea
          dropzoneText={dropzoneText}
          showPreviewsInDropzone={false}
          onChange={onChange}
          maxFileSize={maxFileSizeMb * MB_TO_B}
          filesLimit={filesLimit}
          clearOnUnmount={true}
          {...props}
        />
        {isLoading && <ProgressIcon color="secondary" size={68} />}
      </UploadWrapper>
    </Section>
  );
};

DropzoneArea.defaultProps = {
  showAlerts: ['error'],
};

type StyledProps = {
  height?: string;
};

const Section = styled.section<StyledProps>`
  display: flex;
  justify-content: center;
  .MuiDropzoneArea-root {
    ${p =>
      p.height &&
      css`
        height: ${p.height};
        min-height: ${p.height};
      `}
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;

    &:hover {
      background-color: ${p => p.theme.palette.action.hover};
      border-color: ${p => p.theme.palette.action.active};
    }
  }

  .MuiDropzoneArea-textContainer {
    .MuiDropzoneArea-text {
      color: ${p => p.theme.palette.action.active};
      font-size: ${p => p.theme.typography.h6.fontSize};
    }

    .MuiDropzoneArea-icon {
      display: none;
    }
  }
`;

const ProgressIcon = styled(CircularProgress)`
  position: absolute;
  top: 30%;
  left: 45%;
  z-index: 1;
`;

const UploadWrapper = styled(Box)`
  position: relative;
  width: 100%;
`;

export default DropzoneArea;
