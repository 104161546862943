import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// COMPONENTS
import { ListViewProps } from 'components/listViews/ListView';

// CONTEXTS
import { useData } from 'contexts/dataContext';

// CONSTANTS
import { FormQueryParam } from 'constants/formConstants';

type ReturnProps = Pick<
  ListViewProps,
  | 'editable'
  | 'itemList'
  | 'rowCount'
  | 'pageSize'
  | 'page'
  | 'sortModel'
  | 'onPageChange'
  | 'onPageSizeChange'
  | 'onSortModelChange'
  | 'searchParams'
  | 'onSearchChanged'
  | 'onClickCreate'
  | 'onClickEdit'
  | 'onClickDelete'
>;

const useDialogListView = (): ReturnProps => {
  const [, setQueryParams] = useSearchParams();

  const {
    editable,
    itemList,
    searchParams,
    setSearchParams,
    itemCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortModel,
    setSortModel,
    loadItemList,
    deleteItem,
  } = useData();

  useEffect(() => {
    loadItemList();
  }, [loadItemList]);

  const onClickCreate = useCallback(
    () => setQueryParams({ [FormQueryParam.FormMode]: 'create' }),
    [setQueryParams]
  );

  const onClickEdit = useCallback(
    (id: number | string) =>
      setQueryParams({ [FormQueryParam.FormMode]: 'edit', [FormQueryParam.ResourceId]: `${id}` }),
    [setQueryParams]
  );

  const onClickDelete = useCallback(
    async (id: number | string) => {
      if (deleteItem) {
        try {
          await deleteItem(id);
          loadItemList();
        } catch (e) {
          console.error(e);
        }
      }
    },
    [deleteItem, loadItemList]
  );

  return {
    editable,
    itemList,
    rowCount: itemCount,
    page,
    onPageChange: setPage,
    pageSize,
    onPageSizeChange: setPageSize,
    sortModel,
    onSortModelChange: setSortModel,
    searchParams,
    onSearchChanged: setSearchParams,
    onClickCreate,
    onClickEdit,
    onClickDelete,
  };
};

export default useDialogListView;
