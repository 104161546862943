import { useCallback, useRef } from 'react';

const useAbortRequest = () => {
  const abortRef = useRef<AbortController>();

  const newAbortSignal = useCallback(() => {
    const abortController = new AbortController();
    abortRef.current = abortController;
    return abortController.signal;
  }, []);

  const abortRequest = async () => {
    if (abortRef.current) {
      await abortRef.current?.abort();
      abortRef.current = undefined;
    }
  };

  return {
    newAbortSignal,
    abortRequest,
  };
};

export default useAbortRequest;
