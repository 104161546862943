import { uploadMediaApi } from 'apis/uploadApis';
import { extractFileExtensionFromDataUri } from './uriUtils';

// Read file and return base64 string
export const readFileToDataUri = async (file: File): Promise<string> =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(file);
  });

const BASE64_MARKER = ';base64,';

export const convertDataUriToBinaryBlob = (dataURI: string): Blob => {
  // Detach content
  const [prefix, base64] = dataURI.split(BASE64_MARKER);
  const contentType = prefix.split(':')[1];
  const raw = window.atob(base64);

  // Convert content to binary
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(new ArrayBuffer(rawLength));
  for (let i = 0; i < rawLength; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Return binary as Blob
  return new Blob([uInt8Array], { type: contentType });
};

export const downloadFile = (blob: Blob | string, fileName: string) => {
  if (!(blob instanceof Blob)) {
    blob = new Blob([blob]);
  }

  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const onUploadImageFile = async (
  fileDataUri: string,
  uploadId?: string
): Promise<string | undefined> => {
  let fileName = undefined;
  try {
    const fileExt = extractFileExtensionFromDataUri(fileDataUri);
    fileName = await uploadMediaApi(fileDataUri, `${uploadId || 'test'}.${fileExt}`);
  } catch (e) {
    console.trace('🚀 ~ handleUploadMediaFile ~ e', e);
  }
  return fileName;
};
