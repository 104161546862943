import {
  fetchDesignCollectionByIdApi,
  createDesignCollectionApi,
  updateDesignCollectionByIdApi,
  fetchMasterCollectionsByApi,
} from './apis/designCollectionApis';
import { uploadMediaApi } from '../apis/uploadApis';

// MODELS
import DesignCollection, { GalleryPhotos } from 'models/designCollection';

// UTILS
import { toSnakeCase } from 'utils/stringUtils';
import { now } from 'utils/dateTimeUtils';

// CONSTANTS
import { ItemPromise, ListPromise, MetaParams, SearchableParams } from 'constants/requestConstants';
import { extractFileExtensionFromDataUri } from 'utils/uriUtils';

type FetchDesignCollectionByIdFunc = (id: string | number) => ItemPromise<DesignCollection>;

type FetchMasterCollectionFunc = (
  searchableParams?: SearchableParams,
  meta?: MetaParams
) => ListPromise<DesignCollection>;

export const fetchDesignCollectionById: FetchDesignCollectionByIdFunc = async id => {
  const { data, ...payload } = await fetchDesignCollectionByIdApi(id);

  return {
    ...payload,
    item: new DesignCollection().fromPayload(data),
  };
};

export const saveDesignCollection = async (designCollection: DesignCollection) => {
  try {
    if (designCollection.imageUriToUpload) {
      let fileName = toSnakeCase(designCollection.name!);
      if (designCollection.layoutId) {
        fileName += `-${designCollection.layoutId}`;
      }
      fileName += `-${now()}`;

      const fileNameUrl = await uploadMediaApi(designCollection.imageUriToUpload, fileName);

      if (fileNameUrl) {
        designCollection.imageUrl = fileNameUrl;
        designCollection.imageUriToUpload = '';
      }
    }

    if (designCollection.thumbnailToUpload) {
      let fileName = toSnakeCase(designCollection.name!);
      if (designCollection.layoutId) {
        fileName += `-${designCollection.layoutId}`;
      }
      fileName += `-${now()}`;

      const fileNameUrl = await uploadMediaApi(designCollection.thumbnailToUpload, fileName);

      if (fileNameUrl) {
        designCollection.thumbnailImageUri = fileNameUrl;
        designCollection.thumbnailToUpload = '';
      }
    }

    let result: GalleryPhotos[] = [];
    for (let photo of designCollection.gallery?.photos!) {
      if (photo.id) {
        result.push(photo);
      } else {
        const fileType = extractFileExtensionFromDataUri(photo.photoUri!);
        const fileNameUrl = await uploadMediaApi(
          photo.photoUri!,
          `${designCollection.tag! || Math.random().toString(36).substring(3, 9)}.${fileType}`
        );
        result.push({ photoUri: fileNameUrl });
      }
    }
    designCollection.gallery = {
      ...designCollection.gallery,
      photos: result!,
    };

    const body = designCollection.toPayload();

    if (designCollection.id) {
      await updateDesignCollectionByIdApi(designCollection.id, body);
    } else {
      await createDesignCollectionApi(body);
    }
  } catch (e) {
    console.error('Failed to save design collection');
  }
};

export const fetchMasterCollections: FetchMasterCollectionFunc = async (
  searchableParams = {},
  meta = undefined
) => {
  const { data, ...payload } = await fetchMasterCollectionsByApi(searchableParams, meta);
  const collections = (data || []).map(item => new DesignCollection().fromPayload(item));

  return {
    ...payload,
    list: collections,
  };
};
