import { Obj } from './types';

export enum FormQueryParam {
  FormMode = 'formMode',
  ResourceId = 'id',
}

export enum FormId {
  DataForm = 'data-form',
}

export const DEFAULT_INPUT_LENGTH = 100;
export const DEFAULT_INPUT_MIN_LENGTH = 4;
export const USER_NAME_MAX_LENGTH = 20;
export const CRM_ID_MAX_LENGTH = 10;

export type FormMode = 'create' | 'edit' | 'view';

export type DataFormProps<V extends Obj = Obj, M = V> = {
  mode?: FormMode;
  itemDetail?: M;
  loading?: boolean;
  onCreate?: (params: V) => void;
  onUpdate?: (id: string | number, params: V) => void;
  onSave?: (params: V) => void; // onSave for both onCreate and onUpdate
  onAfterSaved?: () => void;
};

export enum ColorPickerType {
  '#FFFFFF' = '#FFFFFF',
  '#D4D2DB' = '#D4D2DB',
  '#C9C3B7' = '#C9C3B7',
  '#C5B49A' = '#C5B49A',
  '#B7B6B4' = '#B7B6B4',
  '#D0B082' = '#D0B082',
  '#C7B0A1' = '#C7B0A1',
  '#B1836D' = '#B1836D',
  '#B97A51' = '#B97A51',
  '#A44900' = '#A44900',
  '#8B536E' = '#8B536E',
  '#763F41' = '#763F41',
  '#75693F' = '#75693F',
  '#6F735C' = '#6F735C',
  '#617674' = '#617674',
  '#4C8177' = '#4C8177',
  '#988780' = '#988780',
  '#4B6F8A' = '#4B6F8A',
  '#666874' = '#666874',
  '#66555B' = '#66555B',
  '#714D69' = '#714D69',
  '#494568' = '#494568',
  '#544659' = '#544659',
  '#000000' = '#000000',
}
