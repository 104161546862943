import styled from 'styled-components';
import Chip, { ChipProps } from '@mui/material/Chip';

type Props = {
  label: string | number;
  color?: ChipProps['color'];
};

const Status = ({ label, color }: Props) => {
  return <StyledChip size="small" variant="outlined" label={label} color={color} />;
};

const StyledChip = styled(Chip)`
  && {
    font-weight: 600;

    &.MuiChip-outlinedWarning {
      color: #eec90b;
      border-color: #eec90b;
      background-color: #fcf7da;
    }

    &.MuiChip-outlinedSuccess {
      color: #7cd986;
      border-color: #7cd986;
      background-color: #ebfeed;
    }
  }
`;

export default Status;
