import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

const AuthLayout = () => (
  <Box
    height="100vh"
    width="100vw"
    bgcolor="#e5e5e5"
    display="flex"
    justifyContent="center"
    alignItems="flex-start"
    paddingTop="5rem"
    boxSizing="border-box"
  >
    <Box
      width="100%"
      maxWidth="410px"
      bgcolor="#ffffff"
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.25)"
      paddingBottom="2.5rem"
    >
      <Outlet />
    </Box>
  </Box>
);

export default AuthLayout;
