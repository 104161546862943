import { useEffect, useState } from 'react';

// COMPONENTS
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from 'components/dialogs/Dialog';
import Checkbox from 'components/inputs/Checkbox';
import Button, { ButtonProps } from './Button';

// HOOKS
import { useDialog } from 'hooks';

// UTILS
import { downloadFile } from 'utils/fileUtils';

// CONSTANTS
import { ExportOption, Any } from 'constants/types';

export type ExportButtonProps<F = Any> = Omit<ButtonProps, 'onClick' | 'loading' | 'children'> & {
  label?: string;
  fieldOptions?: ExportOption<F>[];
  dialogTitle?: string;
  exportFileName?: string | (() => string); // string or function to get name
  onLoadExportData?: (fields: F[]) => Promise<Any>;
};

const ExportButton = ({
  label,
  dialogTitle,
  fieldOptions,
  onLoadExportData,
  exportFileName = 'my_data.xlsx',
  ...restProps
}: ExportButtonProps) => {
  const { dialogProps, openDialog, closeDialog } = useDialog();

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (!dialogProps.open && checkedList.length > 0) {
      setCheckedList([]);
    }

    if (dialogProps.open && fieldOptions) {
      const defaultChecked = fieldOptions.reduce(
        (finalDefaultChecked: string[], { value }) =>
          Array.isArray(value)
            ? [...finalDefaultChecked, ...value]
            : [...finalDefaultChecked, value],
        []
      );
      setCheckedList(defaultChecked);
    }
    // eslint-disable-next-line
  }, [dialogProps.open]);

  const handleToggleChecked = (value: string | string[]) => () => {
    const isArray = Array.isArray(value);
    const checkedValue = isArray ? value[0] : value;
    const checkedIndex = checkedList.findIndex(checked => checked === checkedValue);
    const nextCheckedList = [...checkedList];

    if (checkedIndex === -1) {
      if (isArray) {
        nextCheckedList.push(...value);
      } else {
        nextCheckedList.push(value);
      }
    } else {
      nextCheckedList.splice(checkedIndex, isArray ? value.length : 1);
    }

    setCheckedList(nextCheckedList);
  };

  const handleExport = async () => {
    if (onLoadExportData) {
      setExporting(true);
      try {
        const data = await onLoadExportData(checkedList);
        if (data) {
          const fileName = typeof exportFileName === 'function' ? exportFileName() : exportFileName;
          downloadFile(new Blob([data]), fileName);
        }
        setExporting(false);
      } catch (e) {
        console.error('Export fail', e);
        setExporting(false);
      }
    }

    if (dialogProps.open) {
      closeDialog();
    }
  };

  const handleClick = () => {
    if (fieldOptions) {
      openDialog();
    } else {
      handleExport();
    }
  };

  return (
    <>
      <Button {...restProps} onClick={handleClick} loading={exporting}>
        {label}
      </Button>
      <Dialog
        title={dialogTitle}
        fullWidth
        maxWidth="xs"
        buttons={[
          { label: 'Cancel', color: 'secondary', onClick: closeDialog },
          { label: 'Export', color: 'accent', onClick: handleExport, loading: exporting },
        ]}
        {...dialogProps}
      >
        <List>
          {(fieldOptions || []).map((option, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={handleToggleChecked(option.value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      Array.isArray(option.value)
                        ? checkedList.includes(option.value[0])
                        : checkedList.includes(option.value)
                    }
                    inputProps={{ 'aria-labelledby': `checked-${option.value}` }}
                  />
                </ListItemIcon>
                <ListItemText id={`checked-${option.value}`}>{option.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

ExportButton.defaultProps = {
  label: 'Export',
  dialogTitle: 'Select data to export',
  color: 'accent',
};

export default ExportButton;
