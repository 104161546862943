import jwt from 'jwt-decode';
import { LocalStorageKey, SessionStorageKey } from 'constants/storageConstants';
import { UserRole } from 'models/admin';

export type AuthState = {
  token: string;
  rememberMe: boolean;
  role: UserRole;
};

// undefined is unloaded
// null is not login
let authState: AuthState | undefined | null;

export const checkLogin = () => {
  const token = sessionStorage.getItem(SessionStorageKey.AccessToken);
  if (token) return true;

  const rememberMe = JSON.parse(localStorage.getItem(LocalStorageKey.RememberMe) || 'false');

  if (!rememberMe) return false;

  const localStorageToken = localStorage.getItem(LocalStorageKey.AccessToken);
  if (!localStorageToken) return false;

  sessionStorage.setItem(SessionStorageKey.AccessToken, localStorageToken);
  return true;
};

export const loginSuccess = ({ token, rememberMe }: Pick<AuthState, 'token' | 'rememberMe'>) => {
  sessionStorage.setItem(SessionStorageKey.AccessToken, token);

  if (rememberMe) {
    localStorage.setItem(LocalStorageKey.AccessToken, token);
  }

  localStorage.setItem(LocalStorageKey.RememberMe, JSON.stringify(rememberMe));

  const userInfo = jwt(token) as UserInfo;

  authState = {
    token,
    rememberMe,
    role: userInfo.auth,
  };
};

const loadAuthState = () => {
  const token = sessionStorage.getItem(SessionStorageKey.AccessToken);
  if (token) {
    const rememberMe = JSON.parse(localStorage.getItem(LocalStorageKey.RememberMe) || 'false');

    const userInfo = jwt(token) as UserInfo;

    authState = {
      token,
      rememberMe,
      role: userInfo.auth,
    };
  }
};

export const logoutSuccess = () => {
  sessionStorage.clear();
  localStorage.clear();
  authState = null;
};

export const getAccessToken = () => {
  if (authState === undefined) loadAuthState();
  return authState?.token;
};

type UserInfo = {
  auth: UserRole; // user role
  sub: string; // login email
  exp: number; // timestamp in seconds
};

export const getPermission = (): UserRole => {
  if (!authState?.role) {
    loadAuthState();
  }

  return authState?.role!;
};

type RoleString = 'sale' | 'project';

const ROLE_MAP: Record<RoleString, UserRole> = {
  project: UserRole.Project,
  sale: UserRole.Sale,
};

// Role admin have full permission, therefor no need to input "admin"
export const allowed = (...roles: RoleString[]) => {
  const authRole = getPermission();
  if (authRole === UserRole.Admin) return true;
  return roles.some(role => ROLE_MAP[role] === authRole);
};
