import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// COMPONENTS
import ReactCrop, { ReactCropProps } from 'react-image-crop';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from 'components/dialogs/Dialog';
// import NumberField from 'components/inputs/NumberField';
import Slider from '@mui/material/Slider';
import Button from 'components/buttons/Button';
import SwipeIcon from '@mui/icons-material/Swipe';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';

// UTILS
import { getCroppedImgContent } from 'utils/cropImageHelpers';

const BACKGROUND_COLORS = ['#fff', '#f5f5f5', '#a885a2', '#fcf7da', '#585036'];

type Props = {
  title?: string;
  aspect?: number;
  uploadId?: string;
  src?: string;
  multipleFiles?: string[];
  onClose: DialogProps['onClose'];
  onComplete?: (imageDataUri: string, uploadId?: string) => void;
  onReset?: () => void;
};

// example: https://codesandbox.io/s/72py4jlll6?file=/src/index.js

const CropImageDialog = ({
  title = 'Upload Image',
  aspect,
  uploadId,
  src,
  multipleFiles,
  onClose,
  onComplete,
  onReset,
}: Props) => {
  const [crop, setCrop] = useState<ReactCropProps['crop']>({});
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [flipClassName, setFlipClassName] = useState('');
  const [backgroundColor, setBackgroundColor] = useState(BACKGROUND_COLORS[0]);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [multipleImageSrc, setMultipleImageSrc] = useState<string[]>([]);
  const [multipleImageLoading, setMultipleImageLoading] = useState<boolean>(false);

  const imageRef = useRef<HTMLImageElement>();

  // Initial Images
  useEffect(() => {
    setImageSrc(src || '');
    setMultipleImageSrc(multipleFiles || []);
  }, [multipleFiles]);

  const updateCrop = useCallback(
    (newCrop: ReactCropProps['crop']) =>
      setCrop(prevCrop => ({
        ...prevCrop,
        ...newCrop,
      })),
    []
  );

  const handleImageLoaded: ReactCropProps['onImageLoaded'] = useCallback(
    image => {
      imageRef.current = image;
      // set default crop width
      let wCrop: number = image.width;
      if (imageRef.current && aspect && imageRef.current?.width > imageRef.current?.height) {
        wCrop = imageRef.current?.height * aspect;
      }
      updateCrop({
        width: wCrop,
        height: !aspect ? imageRef.current?.height : 0,
        x: 0,
        y: 0,
        aspect,
      });
      return false; // Return false when setting crop state in here.
    },
    [aspect, updateCrop]
  );

  // const handleSetWidth = useCallback(
  //   width => {
  //     // check outbound image crop
  //     if (imageRef.current && aspect && width > imageRef.current?.width) {
  //       // re-set max width, prevent crop selection outbound
  //       if (imageRef.current?.width > imageRef.current?.height) {
  //         width = imageRef.current?.height * aspect;
  //       } else {
  //         width = imageRef.current?.width;
  //       }
  //     }
  //     const newCrop: ReactCropProps['crop'] = { width };
  //     if (aspect) {
  //       newCrop.height = width / aspect;
  //     }
  //     updateCrop(newCrop);
  //   },
  //   [aspect, updateCrop]
  // );

  // const handleSetHeight = useCallback(
  //   height => {
  //     // check outbound image crop
  //     if (imageRef.current && aspect && height > imageRef.current?.width / aspect) {
  //       // re-set max height, prevent crop selection outbound
  //       if (imageRef.current?.width > imageRef.current?.height) {
  //         height = imageRef.current?.height;
  //       } else {
  //         height = imageRef.current?.width / aspect;
  //       }
  //     }
  //     const newCrop: ReactCropProps['crop'] = { height };
  //     if (aspect) {
  //       newCrop.width = height * aspect;
  //     }
  //     updateCrop(newCrop);
  //   },
  //   [aspect, updateCrop]
  // );

  const handleChangeScale = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number);
  };

  const handleFlipLeftRight = () => {
    if (flipClassName === 'ImageCrop__flip--leftrigth') {
      setFlipClassName('');
    } else {
      setFlipClassName('ImageCrop__flip--leftrigth');
    }
  };

  const handleFlipUpDown = () => {
    if (flipClassName === 'ImageCrop__flip--updown') {
      setFlipClassName('');
    } else {
      setFlipClassName('ImageCrop__flip--updown');
    }
  };

  const handleComplete = async () => {
    if (multipleImageLoading) return;
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = getCroppedImgContent(imageRef.current, crop);
      if (onComplete) {
        onComplete(croppedImageUrl, uploadId);
      } else {
        console.debug('croppedImageUrl', croppedImageUrl);
      }
    }
    let newMultipleImageSrc = [...multipleImageSrc];
    newMultipleImageSrc.shift();
    if (newMultipleImageSrc.length === 0) {
      handleClose();
    } else {
      setMultipleImageLoading(true);
      setMultipleImageSrc(newMultipleImageSrc);
      setImageSrc(newMultipleImageSrc[0]);
      setTimeout(() => {
        setMultipleImageLoading(false);
      }, 3000);
    }
  };

  const handleClose = () => {
    setRotate(0);
    setScale(1);
    setFlipClassName('');
    setCrop({});
    onClose();
    if (onReset) onReset();
  };

  return (
    <Dialog
      title={title}
      open={!!imageSrc}
      maxWidth="lg"
      fullWidth
      buttons={[
        {
          label: 'Cancel',
          color: 'secondary',
          onClick: handleClose,
        },
        {
          label: 'Save',
          color: 'accent',
          onClick: handleComplete,
        },
      ]}
      onClose={handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            {/*<Grid item xs={12}>*/}
            {/*  <Typography variant="h6">RESIZE</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6}>*/}
            {/*  <NumberField*/}
            {/*    label="Width"*/}
            {/*    value={crop.width}*/}
            {/*    onChange={e => handleSetWidth(+e.target.value)}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6}>*/}
            {/*  <NumberField*/}
            {/*    label="Height"*/}
            {/*    value={crop.height}*/}
            {/*    onChange={e => handleSetHeight(+e.target.value)}*/}
            {/*  />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <Typography variant="h6">ROTATE & FLIP</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                type="button"
                color="secondary"
                onClick={() => setRotate(Math.min(90, Math.max(-90, Number(rotate - 90))))}
              >
                <RotateLeftIcon />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                type="button"
                color="secondary"
                onClick={() => setRotate(Math.min(90, Math.max(-90, Number(rotate + 90))))}
              >
                <RotateRightIcon />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button type="button" color="secondary" onClick={handleFlipLeftRight}>
                <SwipeIcon />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button type="button" color="secondary" onClick={handleFlipUpDown}>
                <SwipeVerticalIcon />
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">BACKGROUND</Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              {BACKGROUND_COLORS.map((color, index) => (
                <Box
                  key={index}
                  bgcolor={color}
                  sx={{
                    width: 40,
                    height: 40,
                    mr: 1,
                    cursor: 'pointer',
                    border: '1px dashed #000',
                  }}
                  onClick={() => setBackgroundColor(color)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container spacing={0}>
            <Grid item xs={12} bgcolor={backgroundColor}>
              {imageSrc && (
                <StyledReactCrop
                  src={imageSrc}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={handleImageLoaded}
                  onChange={setCrop}
                  rotate={rotate}
                  className={flipClassName}
                  scale={scale}
                />
              )}
            </Grid>

            <Grid item xs={1}>
              <IconButton
                aria-label="remove"
                disabled={isEqual(scale, 1)}
                onClick={() => setScale(Number(scale - 0.5))}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>

            <Grid item xs={10} sx={{ p: 0.5 }}>
              <Slider
                onChange={handleChangeScale}
                value={typeof scale === 'number' ? scale : 1}
                min={1}
                max={10}
                step={0.5}
                defaultValue={1}
                color="secondary"
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton
                aria-label="add"
                disabled={isEqual(scale, 10)}
                onClick={() => setScale(Number(scale + 0.5))}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const StyledReactCrop = styled(ReactCrop)`
  &&.ImageCrop__flip--leftrigth {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  &&.ImageCrop__flip--updown {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
`;

export default CropImageDialog;
