import { ReactNode } from 'react';
import styled from 'styled-components';

// COMPONENTS
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ICONS
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

// CONSTANTS
import { SelectOption } from 'constants/types';

export type SelectProps = Omit<MuiSelectProps, 'multiple'> & {
  options?: SelectOption[];
  fullWidth?: FormControlProps['fullWidth'];
  helperText?: ReactNode;
  noneLabel?: string;
  noneValue?: '' | 0;
  required?: boolean;
  onValueChange?: (value: unknown) => void;
  vertical?: boolean;
};

const Select = ({
  id,
  label,
  options,
  fullWidth,
  helperText,
  noneLabel = 'None',
  noneValue,
  displayEmpty,
  error,
  disabled,
  required,
  onChange,
  onValueChange,
  vertical = false,
  ...restProps
}: SelectProps) => {
  const handleChange: SelectProps['onChange'] =
    onChange || (onValueChange ? e => onValueChange(e.target.value) : undefined);

  return (
    <FormControlStyled fullWidth={fullWidth} focused={displayEmpty ? true : undefined}>
      <InputLabel id={id} error={error} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        id={id}
        label={label}
        disabled={disabled || !options?.length}
        IconComponent={ExpandMoreOutlinedIcon}
        error={error}
        displayEmpty={displayEmpty}
        onChange={handleChange}
        required={required}
        MenuProps={{
          PaperProps: {
            style: vertical ? { height: '250px', overflowY: 'auto' } : {},
          },
        }}
        {...restProps}
      >
        {!required && (
          <MenuItem value={noneValue}>
            <em>{noneLabel}</em>
          </MenuItem>
        )}
        {options
          ? options.map(({ value, label: optionLabel, disabled: itemDisabled }) => (
              <MenuItem key={value} value={value} disabled={itemDisabled}>
                {optionLabel ?? value}
              </MenuItem>
            ))
          : null}
      </MuiSelect>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  && {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        padding: 10px 14px;
      }
    }

    label {
      font-size: 13px;
      color: #aca8b7;

      &.MuiInputLabel-outlined {
        top: -3px;
      }

      &.MuiInputLabel-shrink {
        top: 0;
        color: #aca8b7;
      }
    }

    fieldset {
      border-color: #d4d2db !important;
      border-width: 1px !important;
      border-radius: 0 !important ;
    }

    .Mui-disabled {
      background-color: rgb(220 220 220 / 20%) !important;
    }

    .Mui-error {
      color: ${p => p.theme.palette.error.main} !important;
      .MuiOutlinedInput-notchedOutline {
        border-color: ${p => p.theme.palette.error.main} !important;
      }
    }

    .MuiFormHelperText-root {
      margin-left: 0;
    }
  }
`;

export default Select;
