import { ReactNode, useState } from 'react';
import styled from 'styled-components';

// COMPONENTS
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DropzoneArea, { DropzoneAreaProps } from 'components/uploadFiles/DropzoneArea';
import { AlertType } from 'react-mui-dropzone';

const MEDIA_TYPE_TEXT_MAP: Record<string, string> = {
  'image/*': 'jpg, png, gif',
  'video/*': 'avi, mov, wmv, mp4',
};

export type UploadMediaDropzoneProps = Omit<DropzoneAreaProps, 'showAlerts' | 'onAlert'> & {
  label?: string;
  helperText?: ReactNode;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
};

const UploadMediaDropzone = ({
  label,
  helperText,
  error,
  required,
  disabled,
  ...restProps
}: UploadMediaDropzoneProps) => {
  const [loadAlert, setLoadAlert] = useState<{ message?: string; variant?: AlertType }>({});

  const isError = loadAlert.variant === 'error' || error;
  const text = (() => {
    if (loadAlert.variant === 'error') return loadAlert.message;
    if (helperText) return helperText;

    return `Supported: ${restProps.acceptedFiles
      ?.map(media => MEDIA_TYPE_TEXT_MAP[media] || '')
      .join(', ')}. Maximum ${restProps.maxFileSizeMb} MB`;
  })();

  return (
    <StyledFormControl className={isError ? 'has-error' : undefined} fullWidth>
      {label && (
        <FormLabel error={isError} required={required}>
          {label}
        </FormLabel>
      )}
      {!disabled && (
        <DropzoneArea
          showAlerts={false}
          onAlert={(message, variant) => setLoadAlert({ message, variant })}
          {...restProps}
        />
      )}
      {text && !disabled && (
        <FormHelperText sx={{ mx: 1 }} error={isError}>
          {text}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

UploadMediaDropzone.defaultProps = {
  height: '3.75rem',
  maxFileSizeMb: 5,
};

const StyledFormControl = styled(FormControl)`
  &.has-error .MuiDropzoneArea-root {
    border-color: ${p => p.theme.palette.error.main} !important;
  }
`;

export default UploadMediaDropzone;
