import {
  setLocale,
  mixed,
  string,
  number as yupNumber,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from 'yup';
import { DECIMAL_2_PRECISION_PATTERN } from '../constants/patterns';

setLocale({
  mixed: {
    required: 'This field is required',
  },
});

const number = () => yupNumber().typeError('Invalid number');

export enum ValidationMessage {
  DECIMAL_PRECISION = 'Allow only 2 decimal places',
  MIN_MAX = 'Min < Max',
  DATE_FORMAT = 'This field must be valid date',
}

const commonFunc = {
  validate2Precision: (val?: number) => {
    return DECIMAL_2_PRECISION_PATTERN.test(`${val}`);
  },
};

const useYup = () => {
  return { mixed, string, number, boolean, bool, date, object, array, ref, lazy, commonFunc };
};

export default useYup;
