import { GridSortModel } from '@mui/x-data-grid-pro';

export enum TableId {
  Segments = 'segment-table',
  Placeholders = 'placeholder-table',
}

export const PAGE_SIZE = 50;
export const FIRST_PAGE = 1;
export const SORT_BY_ID: GridSortModel = [{ field: 'id', sort: 'desc' }];

export type TableFields = {
  rowId?: string;
  treeDataPath?: string[];
};
