import { ReactNode } from 'react';
import { get } from 'lodash';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableColDef, TableEnrichedColDef } from '../constants/dataTableConstants';
import { transformColumnFilter } from './columnTransformers/transformColumnFilters';
import { transformColumnType } from './columnTransformers/transformColumnTypes';

export const transformColumns = (columns: TableEnrichedColDef[]): TableColDef[] =>
  columns.map(column => {
    column = transformNestedValue(column);

    if (column.filterable !== false) {
      column = transformColumnFilter(column);
    }

    if (column.type) {
      column = transformColumnType(column);
    }

    return column;
  });

export const transformColumn = (column: TableEnrichedColDef): TableColDef => {
  column = transformNestedValue(column);

  if (column.filterable !== false) {
    column = transformColumnFilter(column);
  }

  if (column.type) {
    column = transformColumnType(column);
  }

  return column;
};

export const transformNestedValue = (column: TableEnrichedColDef): TableEnrichedColDef => {
  if (column.field.includes('.') && !column.valueGetter) {
    column.valueGetter = ({ row, field }) => get(row, field);
  }

  return column;
};

export const displayCell = (colDef: TableColDef, params: GridRenderCellParams): ReactNode => {
  if (colDef.renderCell) return colDef.renderCell(params);
  if (colDef.valueGetter) {
    // @ts-ignore
    return colDef.valueGetter(params);
  }
  return get(params.row, colDef.field);
};
