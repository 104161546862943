import React, { createContext, useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

import { loginSuccess, logoutSuccess, checkLogin, AuthState } from 'services/authService';

type AuthContextState = {
  loggedIn: boolean;
};

type AuthActions = {
  handleLogin: Function;
  handleLogout: Function;
};

const AuthContext = createContext<Partial<AuthContextState & AuthActions>>({});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [loggedIn, setLoggedIn] = useState(() => checkLogin());

  useEffect(() => {
    if (!loggedIn) {
      navigate({
        pathname: '/login',
        search:
          pathname !== '/'
            ? createSearchParams({
                from: `${pathname}${search}`,
              }).toString()
            : undefined,
      });
    }
  }, [loggedIn, navigate, pathname, search]);

  const handleLogin = (loginParams: AuthState) => {
    loginSuccess(loginParams);
    setLoggedIn(true);
  };

  const handleLogout = () => {
    logoutSuccess();
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ loggedIn, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
