import { useMemo, useState } from 'react';

// COMPONENTS
import { DataTableProps } from 'components/dataTables/DataTable';

// CONSTANTS
import { FilterColumn, SearchableObject } from 'constants/requestConstants';
import {
  FilterOperatorValue,
  NumberFilterOperatorValue,
  TableColDef,
} from 'components/dataTables/constants/dataTableConstants';

type DataTableFilterHookReturn = {
  filterValues: SearchableObject | {};
  dataTableFilterProps: Pick<
    DataTableProps,
    'filterMode' | 'onFilterModelChange' | 'sortModel' | 'onSortModelChange'
  >;
};

type Props = {
  columns: Pick<TableColDef, 'filterable' | 'sortable'> &
    {
      field?: TableColDef['field'];
      filterField?: TableColDef['filterField'];
      [name: string]: any;
    }[];
  defaultSortField?: string;
  defaultSortDirection?: 'asc' | 'desc';
  transformFilterColumns?: (filterColumns: FilterColumn[]) => FilterColumn[];
};

const useDataTableSearchable = ({
  columns,
  defaultSortField,
  defaultSortDirection,
  transformFilterColumns,
}: Props): DataTableFilterHookReturn => {
  const [sortModel, setSortModel] = useState<DataTableProps['sortModel']>(() =>
    defaultSortField ? [{ field: defaultSortField, sort: defaultSortDirection || 'desc' }] : []
  );
  const [filterModel, setFilterModel] = useState<DataTableProps['filterModel']>();

  const filterValues: SearchableObject | {} = useMemo(() => {
    if (!filterModel?.items?.length && !sortModel?.length) return {};

    let filterColumns: FilterColumn[] = columns
      .filter(
        column =>
          column.field &&
          column.field !== 'actions' &&
          (column.filterable !== false || column.sortable !== false)
      )
      .map(column => {
        const filter: SearchableObject['columns'][0] = {
          name: column.field!,
          data: column.filterField || column.field!,
          searchable: column.filterable ?? true,
          orderable: column.sortable ?? true,
        };

        const filterItem = (filterModel?.items || []).find(
          ({ columnField }) => column.field === columnField
        );

        if (filterItem?.value) {
          switch (column.type) {
            case 'number':
            case 'currency':
            case 'percent':
              if (filterItem.operatorValue === NumberFilterOperatorValue.Equals) {
                filter.search = { value: `${(+filterItem.value).toFixed(2)}+-1`, regex: true };
              }
              break;

            case 'rating':
              if (filterItem.operatorValue === NumberFilterOperatorValue.Equals) {
                filter.search = { value: `${filterItem.value}+-1`, regex: true };
              }
              break;

            case 'boolean':
            case 'booleanIcon':
              filter.search = { value: filterItem.value, regex: true };
              break;

            default:
              const isArray = [FilterOperatorValue.OneOf].includes(
                filterItem.operatorValue as FilterOperatorValue
              );
              if (isArray) {
                filter.search = { value: (filterItem.value || []).join('+'), regex: true };
                break;
              }

              const isExact = [FilterOperatorValue.Equals, FilterOperatorValue.Is].includes(
                filterItem.operatorValue as FilterOperatorValue
              );
              if (isExact) {
                filter.search = { value: `${filterItem.value}+-1`, regex: true };
                break;
              }

              filter.search = {
                value: filterItem.value,
                regex: true,
              };
          }
        }

        return filter;
      });

    if (transformFilterColumns) {
      filterColumns = transformFilterColumns(filterColumns);
    }

    const nextFilterValues: SearchableObject = {
      draw: filterColumns.length,
      columns: filterColumns,
    };

    if (sortModel?.length) {
      nextFilterValues.order = sortModel.map(item => {
        const columnIndex = filterColumns.findIndex(({ name }) => name === item.field);

        return {
          column: columnIndex,
          dir: item.sort!,
        };
      });
    }

    return nextFilterValues;
  }, [filterModel?.items, sortModel]);

  // useEffect(() => {
  //   console.debug('useDataTableSearchable', filterValues);
  // }, [filterValues]);

  return {
    filterValues,
    dataTableFilterProps: {
      onFilterModelChange: nextFilterModel => setFilterModel(nextFilterModel),
      sortModel,
      onSortModelChange: nextSortModel => setSortModel(nextSortModel),
      filterMode: 'server',
    },
  };
};

export default useDataTableSearchable;
