import { pick } from 'lodash';
import { Obj } from 'constants/types';

import BaseModel, { BaseModelAttrNames } from './baseModel';

export enum UserRole {
  Admin = 'ROLE_ADMIN',
  Project = 'ROLE_PROJECT',
  Sale = 'ROLE_SALE',
}

class Admin extends BaseModel<Admin> {
  id?: number;

  username?: string;

  email?: string;

  phoneNumber?: string | null;

  firstName?: string;

  lastName?: string;

  avatarUrl?: string | null;

  activated?: boolean;

  role?: UserRole;

  newPassword?: string;

  static _attrNames: (keyof AdminValues)[] = [
    'id',
    'username',
    'email',
    'phoneNumber',
    'newPassword',
    'firstName',
    'lastName',
    'avatarUrl',
    'activated',
    'role',
  ];

  protected _getAttrNames(): (keyof AdminValues)[] {
    return Admin._attrNames;
  }

  constructor(attrs?: Partial<AdminValues>) {
    super();
    if (attrs) {
      this.assign(attrs);
    }
  }

  get displayName() {
    return `${this.firstName} ${this.lastName}`;
  }

  toObject(): Obj {
    return pick(this, Admin._attrNames);
  }

  // "langKey": "en",
  // "createdBy": null,
  // "createdDate": null,
  // "lastModifiedBy": null,
  // "lastModifiedDate": "2023-02-27T04:02:57.870325Z",
  // "authority": null
  fromPayload({
    id,
    login,
    firstName,
    lastName,
    email,
    phoneNumber,
    imageUrl,
    activated,
    authorities,
  }: Obj) {
    if (id !== undefined) this.id = id;
    if (login !== undefined) this.username = login;
    if (firstName !== undefined) this.firstName = firstName;
    if (lastName !== undefined) this.lastName = lastName;
    if (email !== undefined) this.email = email;
    if (phoneNumber !== undefined) this.phoneNumber = phoneNumber;
    if (imageUrl !== undefined) this.avatarUrl = imageUrl;
    if (activated !== undefined) this.activated = activated;
    if (authorities !== undefined) this.role = authorities[0];

    return this;
  }

  toPayload(): Obj {
    return {
      login: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      imageUrl: this.avatarUrl,
      // langKey: 'en',
      authority: this.role,
      newPassword: this.newPassword || undefined,
    };
  }
}

export type AdminValues = Omit<Admin, BaseModelAttrNames | 'displayName'>;

export type AdminFields = keyof Omit<Admin, BaseModelAttrNames | 'newPassword'>;

export default Admin;
