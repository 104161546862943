import BaseModel, { BaseModelAttrNames } from './baseModel';

// CONSTANTS
import { Obj } from 'constants/types';

class SegmentGroup extends BaseModel<SegmentGroup> {
  id?: number;

  name?: string;

  color?: string;

  planId?: number;

  fromPayload({ id, name, color, planId }: Obj) {
    if (id !== undefined) this.id = id;
    if (name !== undefined) this.name = name;
    if (color !== undefined) this.color = color;
    if (planId !== undefined) this.planId = planId;

    return this;
  }
}

export type SegmentGroupValues = Omit<SegmentGroup, BaseModelAttrNames | 'id'>;

export default SegmentGroup;
