import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
    accentLight: Palette['primary'];
  }

  interface PaletteOptions {
    accent: PaletteOptions['primary'];
    accentLight: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    accentLight: true;
  }
}

declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    accent: true;
    accentLight: true;
  }
}

const theme = createTheme({
  palette: {
    text: {
      primary: '#000',
    },
    primary: {
      main: '#fff',
      light: '#f5f5f5',
    },
    secondary: {
      main: '#d4d2db',
      light: '#f5f5f5',
      contrastText: '#000',
    },
    accent: {
      main: '#a885a2',
      contrastText: '#fff',
    },
    accentLight: {
      main: '#eee7ec',
      contrastText: '#a885a2',
    },
    action: {
      active: '#a885a2',
      hover: '#eee7ec',
      // disabled: '#f5f5f5',
      disabled: 'rgba(220, 220, 220, 0.8)',
    },
    success: {
      main: '#7cd986',
      contrastText: '#fff',
    },
    warning: {
      main: '#eec90b',
      light: '#fcf7da',
      dark: '#585036',
      contrastText: '#fff',
    },
    error: {
      main: '#e86565',
    },
  },

  shape: {
    borderRadius: 0,
  },

  typography: {
    h1: { fontSize: '3rem', lineHeight: 1.25 },
    h2: { fontSize: '2.5rem', lineHeight: 1.25 },
    h3: { fontSize: '2rem', lineHeight: 1.25 },
    h4: { fontSize: '1.5625rem', lineHeight: 1.28 },
    h5: { fontSize: '1.25rem', lineHeight: 1.4 },
    h6: { fontSize: '1rem', lineHeight: 1.5 },
    fontFamily: '"Montserrat", sans-serif',
  },

  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.08)',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#000',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          color: '#a885a2',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-groupLabel': {
            fontWeight: 600,
            textDecoration: 'underline',
          },
        },
      },
    },
  },
});

export default theme;
