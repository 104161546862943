import MuiInput from '@mui/material/Input';
import styled from 'styled-components';

const StyledInput = styled(MuiInput)`
  && {
    border: 1px solid ${p => p.theme.palette.secondary.main};
  }
`;

StyledInput.defaultProps = {
  disableUnderline: true,
};

export default StyledInput;
