import { mapValues, isEmpty } from 'lodash';
import { MetaParams } from 'constants/requestConstants';
import { Obj } from 'constants/types';
import { AxiosRequestConfig } from 'axios';

export const arrayParamToString = (arr: (number | string)[] | undefined) =>
  (arr || []).length > 0 ? (arr || []).join(',') : undefined;

export const parseRequestParams = (
  dataParams?: Obj,
  metaParams?: MetaParams,
  fetchMultiple = false
): AxiosRequestConfig => {
  const configs: AxiosRequestConfig = { params: {}, options: {} };

  // Set query params
  if (dataParams) {
    Object.keys(dataParams).forEach(field => {
      const value = dataParams[field];
      if (value == null) return;

      if (Array.isArray(value)) {
        configs.params[field] = arrayParamToString(value);
      } else {
        configs.params[field] = value;
      }
    });
  }

  // Set meta params
  if (fetchMultiple) {
    const { page, size } = metaParams || {};

    if (size) {
      configs.params.size = size;
      configs.params.page = page;
    } else {
      configs.params.size = 1000;
    }
  }

  // Additional functions
  if (metaParams) {
    const { newAbortSignal } = metaParams;

    if (newAbortSignal) configs.options!.newAbortSignal = newAbortSignal;
  }

  return configs;
};

export const parseArrayParam = (value: (number | string)[] | undefined) =>
  (value || []).length > 0 ? value?.join(',') : undefined;

type ParseMetaOptions = {
  defaultSort?: string;
};

export const parseMetaParams = (
  meta: MetaParams | undefined,
  options: ParseMetaOptions | undefined = {}
): MetaParams => {
  if (!meta) {
    return { size: 1000 };
  }

  const { page, size, sort = options.defaultSort } = meta || {};

  return {
    size: size || 1000,
    page,
    sort,
  };
};

export const parseFilterParams = (params: Obj) =>
  mapValues(params, param => {
    if (Array.isArray(param)) return parseArrayParam(param);
    return param;
  });

export const parseNestedObjectParams = (params: any): Record<string, string> => {
  if (isEmpty(params)) return {};

  const returnObj: Record<string, string> = {};

  if (Array.isArray(params)) {
    params.forEach((value, index) => {
      if (typeof value === 'object') {
        const isArray = Array.isArray(value);
        const nestedObj = parseNestedObjectParams(value);
        Object.keys(nestedObj).forEach(field => {
          returnObj[`[${index}]${!isArray ? '.' : ''}${field}`] = nestedObj[field];
        });
      } else {
        returnObj[`[${index}]`] = `${value}`;
      }
    });
  } else {
    Object.keys(params as Object).forEach(key => {
      const value = params[key];

      if (typeof value === 'object') {
        const isArray = Array.isArray(value);
        const nestedObj = parseNestedObjectParams(value);
        Object.keys(nestedObj).forEach(field => {
          returnObj[`${key}${!isArray ? '.' : ''}${field}`] = nestedObj[field];
        });
      } else {
        returnObj[key] = `${value}`;
      }
    });
  }

  return returnObj;
};
