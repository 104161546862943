import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

// COMPONENTS
import Box from '@mui/material/Box';
import { LicenseInfo, DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import DataTablePagination from './sections/DataTablePagination';
import DataTableToolbar from './sections/DataTableToolbar';
import GroupingCell from './cells/GroupingCell';

// SERVICES
import { getTableParams, setTableParams } from 'services/storageService';

// UTILS
import { transformColumn } from './helpers/columnHelpers';

// CONSTANTS
import { MUI_DATAGRID_PRO_LICENSE_KEY } from 'constants/environments';
import { PAGE_SIZE } from 'constants/tableConstants';
import { TableEnrichedColDef, TableActionColDef } from './constants/dataTableConstants';
import { Any } from 'constants/types';

LicenseInfo.setLicenseKey(MUI_DATAGRID_PRO_LICENSE_KEY);

export type TableParams = {
  columnsByField?: Record<
    string,
    {
      width: number;
    }
  >;
};

export type { TableEnrichedColDef, TableActionColDef };

export type DataTableProps<F extends string = string, R = Any> = Omit<
  DataGridProProps,
  'columns' | 'paginationMode' | 'sortingMode' | 'disableSelectionOnClick'
> & {
  tableId?: string;
  selectedRowId?: number | string;
  columns: TableEnrichedColDef<F, R>[];
  hideRowPerPageSelector?: boolean;
  defaultPageSize?: number;
  serverMode?: boolean;
  disableSelectAllCheckbox?: boolean;
  paginationRowsLabel?: string;
  enableToolbar?: boolean;
  selectAll?: boolean;
  clickSelectAll?: () => void;
};

const DataTablePro = <F extends string = string>({
  tableId,
  selectedRowId,
  columns,
  page,
  pageSize,
  defaultPageSize = PAGE_SIZE,
  sortModel,
  onPageChange,
  hideRowPerPageSelector,
  serverMode,
  treeData,
  groupingColDef,
  paginationRowsLabel,
  enableToolbar,
  selectAll = false,
  clickSelectAll,
  getRowClassName,
  onSortModelChange,
  onPageSizeChange,
  ...restProps
}: DataTableProps<F>) => {
  const [statePageSize, setStatePageSize] = useState(() => defaultPageSize);
  const [stateTableParams, setStateTableParams] = useState<TableParams>(() =>
    tableId ? getTableParams(tableId) : {}
  );

  useEffect(() => {
    if (tableId && !isEqual(stateTableParams, getTableParams(tableId))) {
      setTableParams(tableId, stateTableParams);
    }
  }, [tableId, stateTableParams]);

  const mode = serverMode ? 'server' : 'client';

  const transformedColumns = useMemo(() => {
    let nextColumns = [...columns];

    if (tableId) {
      nextColumns = nextColumns.map(cols => {
        if (stateTableParams?.columnsByField?.[cols.field]) {
          return {
            ...cols,
            ...stateTableParams?.columnsByField?.[cols.field],
            flex: undefined,
          };
        }

        return cols;
      });
    }

    if (treeData && !groupingColDef) {
      const [, ...restColumns] = nextColumns;
      return restColumns.map(transformColumn);
    }

    return nextColumns.map(transformColumn);
  }, [columns, groupingColDef, treeData, tableId, setStateTableParams]);

  const transformedGroupingColumn: DataGridProProps['groupingColDef'] = useMemo(() => {
    if (!treeData || columns.length === 0) return undefined;
    if (groupingColDef) return groupingColDef;

    const firstColumn = transformColumn(columns[0]);
    const { renderCell, ...restFirstColumn } = firstColumn;

    return {
      ...restFirstColumn,
      renderCell: params => <GroupingCell {...params} column={firstColumn} />,
    };
  }, [groupingColDef, columns, treeData]);

  const components: DataTableProps['components'] = useMemo(() => {
    const nextComponents: DataTableProps['components'] = {
      Pagination: () => (
        <DataTablePagination
          hideRowPerPageSelector={hideRowPerPageSelector}
          rowsLabel={paginationRowsLabel}
        />
      ),
      NoResultsOverlay: () => (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'center',
            justifyContent: 'center',
          }}
        >
          No data
        </Box>
      ),
      LoadingOverlay: () => (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000',
            opacity: '0.5',
            position: 'absolute',
            zIndex: 2,
          }}
        >
          <CircularProgress color="secondary" size={48} />
        </Box>
      ),
    };

    if (enableToolbar) {
      nextComponents.Toolbar = () => (
        <DataTableToolbar selectAll={selectAll} clickSelectAll={clickSelectAll} />
      );
    }

    return nextComponents;
  }, []);

  const handleColumnWidthChange: DataGridProProps['onColumnWidthChange'] = params => {
    if (!tableId) return;

    const {
      colDef: { field, width },
    } = params;

    if (field) {
      setStateTableParams(prev => ({
        ...prev,
        columnsByField: {
          ...(prev.columnsByField || {}),
          [field]: { width: width as number },
        },
      }));
    }
  };

  const handleGetRowClassName: DataTableProps['getRowClassName'] = params => {
    let className = '';

    if (getRowClassName) className += ' ' + getRowClassName(params);

    if (selectedRowId && params.row.id === selectedRowId) className += 'row-selected';

    return className;
  };

  return (
    <DataGridPro
      columns={transformedColumns}
      page={page && page - 1}
      pageSize={pageSize || statePageSize}
      onPageChange={(newPage, details) => onPageChange && onPageChange(newPage + 1, details)}
      disableSelectionOnClick
      paginationMode={mode}
      sortingMode={mode}
      sortModel={sortModel}
      treeData={treeData}
      onColumnWidthChange={handleColumnWidthChange}
      groupingColDef={transformedGroupingColumn}
      getRowClassName={getRowClassName || selectedRowId ? handleGetRowClassName : undefined}
      onPageSizeChange={(newPageSize, details) => {
        if (onPageSizeChange) {
          onPageSizeChange(newPageSize, details);
        } else {
          setStatePageSize(newPageSize);
        }
      }}
      onSortModelChange={(model, details) => onSortModelChange && onSortModelChange(model, details)}
      components={components}
      componentsProps={{
        filterPanel: {
          sx: {
            '& .MuiButton-text': {
              color: 'accent.main',
            },
          },
        },
      }}
      {...restProps}
    />
  );
};

DataTablePro.defaultProps = {
  keepNonExistentRowsSelected: true,
  checkboxSelectionVisibleOnly: true,
  pagination: true,
};

const StyledDataTablePro = styled(DataTablePro)`
  ${p =>
    p.disableSelectAllCheckbox &&
    css`
      .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
        display: none;
      }
    `}

  .row-selected {
    background-color: ${p => p.theme.palette.warning.light};
  }
`;

export default StyledDataTablePro;
