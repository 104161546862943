// COMPONENTS
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import SearchSelect, { SearchSelectProps } from 'components/inputs/SearchSelect';

// CONSTANTS
import { TableColFilter } from '../constants/dataTableConstants';

export type FilterSearchSelectProps = Pick<TableColFilter, 'options' | 'groupBy' | 'filterField'> &
  Pick<SearchSelectProps, 'noneLabel'>;

type Props = GridFilterInputValueProps & FilterSearchSelectProps;

const FilterSearchSelect = ({ item, filterField, applyValue, ...restProps }: Props) => {
  const handleChange: SearchSelectProps['onChange'] = (event, value) => {
    const nextItem: typeof item = { ...item, value: value ?? null };
    if (filterField) nextItem.filterField = filterField;

    applyValue(nextItem);
  };

  return (
    <Box display="inline-flex" alignItems="center" height={65}>
      <SearchSelect
        value={item?.value}
        onChange={handleChange}
        variant="standard"
        fullWidth
        autoWidth
        {...restProps}
      />
    </Box>
  );
};

export default FilterSearchSelect;
