import { useEffect } from 'react';

const useUnmount = (unmountFn: () => void) => {
  useEffect(
    () => () => unmountFn(),
    // eslint-disable-next-line
    []
  );
};

export default useUnmount;
