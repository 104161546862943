import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// COMPONENTS
import { Root, Header, Content } from '@mui-treasury/layout';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from 'components/navigation/Link';

// ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// ASSETS
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import logo from 'assets/img/icons/logo.svg';

// CONTEXTS
import { useAuth } from 'contexts/authContext';

// CONSTANTS
import {
  MAIN_MENU,
  PROFILE_MENU,
  TMenu,
  MenuFunctionKey,
  EXCEPT_MENU,
} from 'constants/layoutConstants';
import { routes } from 'constants/routeConstants';
import { allowed } from 'services/authService';

const MainLayout = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs(routes);
  const lastBreadcrumbIndex = breadcrumbs.length - 1;

  const { handleLogout } = useAuth();

  const [subMenu, setSubMenu] = useState<TMenu['subMenu'] | undefined>();

  const handleClickMenu = (menu: TMenu, onClose: Function) => {
    if (menu.subMenu) {
      return setSubMenu(menu.subMenu);
    }

    if (menu.path) {
      navigate(menu.path);
    }

    if (menu.functionKey) {
      switch (menu.functionKey) {
        case MenuFunctionKey.LogOut:
          handleLogout?.();
          break;
      }
    }

    if (subMenu) {
      setSubMenu(undefined);
    }
    onClose();
  };

  const editable = useMemo(() => allowed(), []);
  const MenuHeader = (menu: TMenu, onClose: Function) => {
    return (
      <MenuItem onClick={() => handleClickMenu(menu, onClose)} sx={{ height: '3rem' }}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText>{menu.label}</ListItemText>
        {menu.subMenu && <ArrowRightOutlinedIcon />}
      </MenuItem>
    );
  };

  return (
    <Root
      scheme={{
        header: {
          config: {
            xs: { position: 'sticky', height: '3.75rem' },
          },
        },
      }}
    >
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        bgcolor="primary.light"
      >
        <Header color="primary">
          <Toolbar variant="dense">
            <PopupState variant="popover" popupId="mainMenu">
              {popupState => (
                <>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="main-menu"
                    sx={{ mr: 2 }}
                    {...bindTrigger(popupState)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Popover
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    elevation={4}
                    marginThreshold={0}
                    {...bindPopover(popupState)}
                  >
                    <div style={{ display: 'flex' }}>
                      <MenuList sx={{ minWidth: '14.5rem' }}>
                        {MAIN_MENU.map(menu => (
                          <div key={menu.label}>
                            {!editable &&
                              !EXCEPT_MENU?.includes(menu.path!) &&
                              MenuHeader(menu, popupState.close)}
                            {editable && MenuHeader(menu, popupState.close)}
                          </div>
                        ))}
                      </MenuList>

                      {subMenu && (
                        <>
                          <Divider orientation="vertical" flexItem />
                          <MenuList sx={{ minWidth: '14.5rem' }}>
                            {subMenu.map(subItem => (
                              <MenuItem
                                key={subItem.label}
                                onClick={() => handleClickMenu(subItem, popupState.close)}
                                sx={{ height: '3rem' }}
                              >
                                {subItem.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </div>
                  </Popover>
                </>
              )}
            </PopupState>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <img src={logo} alt="logo" style={{ height: '1.25rem' }} loading="lazy" />
            </Typography>
            <div>
              <PopupState variant="popover" popupId="profileMenu">
                {popupState => (
                  <>
                    <IconButton
                      size="large"
                      color="inherit"
                      aria-label="profile-menu"
                      {...bindTrigger(popupState)}
                    >
                      <Avatar>U</Avatar>
                    </IconButton>
                    <Popover
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      elevation={4}
                      marginThreshold={0}
                      {...bindPopover(popupState)}
                    >
                      <MenuList sx={{ minWidth: '14.5rem' }}>
                        {PROFILE_MENU.map(menu => (
                          <MenuItem
                            key={menu.label}
                            onClick={() => handleClickMenu(menu, popupState.close)}
                            sx={{ height: '3rem' }}
                          >
                            <ListItemIcon>{menu.icon}</ListItemIcon>
                            <ListItemText>{menu.label}</ListItemText>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Popover>
                  </>
                )}
              </PopupState>
            </div>
          </Toolbar>
        </Header>

        <Box alignSelf="stretch" m={2}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs.map(({ match, breadcrumb }, index) =>
              index < lastBreadcrumbIndex ? (
                <Link key={match.pathname} underline="hover" color="inherit" to={match.pathname}>
                  {breadcrumb}
                </Link>
              ) : (
                <Typography color="text.primary" key={match.pathname}>
                  {breadcrumb}
                </Typography>
              )
            )}
          </Breadcrumbs>
        </Box>

        <Box
          overflow="auto"
          alignSelf="stretch"
          height="100%"
          mx={2}
          mb={2.5}
          bgcolor="primary.main"
        >
          <Content sx={{ height: '100%' }}>
            <Outlet />
          </Content>
        </Box>
      </Box>
    </Root>
  );
};

export default MainLayout;
