import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// COMPONENTS
import { DialogFormViewProps } from 'components/formViews/DialogFormView';

// CONTEXTS
import { useData } from 'contexts/dataContext';

// HOOKS
import { usePrevious } from 'hooks';

// CONSTANTS
import { FormQueryParam, FormMode, DataFormProps } from 'constants/formConstants';

type ReturnProps = {
  formMode?: DialogFormViewProps['mode'];
  dialogProps: Pick<DialogFormViewProps, 'onClose' | 'submitting'>;
  formProps: DataFormProps;
};

const useDialogFormView = (): ReturnProps => {
  const [queryParams, setQueryParams] = useSearchParams();

  const formMode = queryParams.get(FormQueryParam.FormMode) as FormMode;
  const prevFormMode = usePrevious(formMode);

  const { itemDetail, loadItemDetail, loadItemList, resetItemDetail, createItem, updateItem } =
    useData();

  const [itemDetailLoading, setItemDetailLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (formMode === 'edit' || formMode === 'view') {
      const id = queryParams.get(FormQueryParam.ResourceId);
      if (id) {
        setItemDetailLoading(true);
        loadItemDetail(id).finally(() => setItemDetailLoading(false));
      }
    }
  }, [formMode, queryParams, loadItemDetail]);

  useEffect(() => {
    if (!formMode && (prevFormMode === 'edit' || prevFormMode === 'view')) {
      resetItemDetail();
    }
  }, [formMode, prevFormMode, resetItemDetail]);

  const onClose = useCallback(() => setQueryParams({}), [setQueryParams]);

  const onSave: DataFormProps['onSave'] = useCallback(
    async params => {
      if (formSubmitting) return;

      try {
        setFormSubmitting(true);
        if (formMode === 'create') {
          if (createItem) {
            await createItem(params);
          }
        } else if (formMode === 'edit') {
          if (updateItem) {
            const { id = queryParams.get(FormQueryParam.ResourceId), ...updateParams } = params;

            if (id) {
              await updateItem(id, updateParams);
            }
          }
        }
      } catch (e) {
        console.error(e);
        setFormSubmitting(false);
      }

      // Wait for 500ms to make sure db is updated.
      await new Promise(resolve => setTimeout(resolve, 500));
      await loadItemList();
      setFormSubmitting(false);
      onClose();
    },
    // eslint-disable-next-line
    [formMode]
  );

  return {
    formMode,
    dialogProps: {
      onClose,
      submitting: formSubmitting,
    },
    formProps: {
      // createItem,
      // updateItem,
      loading: itemDetailLoading,
      itemDetail,
      onSave,
    },
  };
};

export default useDialogFormView;
