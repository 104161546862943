import Box from '@mui/material/Box';

type Props = {
  value: string;
};

const ColorCell = ({ value }: Props) => {
  if (!value) return null;

  return (
    <Box display="inline-flex" alignItems="center">
      <Box height={16} width={16} bgcolor={value} border="1px solid rgba(0, 0, 0, 0.1)" />
      &nbsp;&nbsp;
      {value}
    </Box>
  );
};

export default ColorCell;
