// COMPONENTS
import {
  GridFilterItem,
  GridFilterOperator,
  GridCellParams,
  getGridNumericOperators,
  getGridDateOperators,
  getGridBooleanOperators,
} from '@mui/x-data-grid-pro';
import FilterText, { FilterTextProps } from '../../filterInputs/FilterText';
import FilterSelect, { FilterSelectProps } from '../../filterInputs/FilterSelect';
import FilterSearchSelect, { FilterSearchSelectProps } from '../../filterInputs/FilterSearchSelect';
import FilterSearchMultiSelect, {
  FilterSearchMultiSelectProps,
} from '../../filterInputs/FilterSearchMultiSelect';

// CONSTANTS
import {
  TableColFilter,
  FilterOperatorValue,
  TableEnrichedColDef,
  TableColDef,
  TableColType,
  NumberFilterOperatorValue,
  TableStatusColDef,
} from '../../constants/dataTableConstants';
import { ValueOption } from 'constants/types';

type GenOperatorParams = Pick<TableColFilter, 'getApplyFilter'>;

const genTextOperator = (
  componentProps: FilterTextProps = {},
  operatorParams?: GenOperatorParams
): GridFilterOperator => ({
  label: 'equals',
  value: FilterOperatorValue.Equals,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    if (operatorParams?.getApplyFilter) {
      return operatorParams.getApplyFilter(filterItem);
    }

    const filterValue = `${filterItem.value}`.toLowerCase();

    return (params: GridCellParams): boolean => `${params.value}`.toLowerCase() === filterValue;
  },
  InputComponent: FilterText,
  InputComponentProps: componentProps,
});

const genTextLikeOperator = (
  componentProps: FilterTextProps = {},
  operatorParams?: GenOperatorParams
): GridFilterOperator => ({
  label: 'contains',
  value: FilterOperatorValue.Contains,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    if (operatorParams?.getApplyFilter) {
      return operatorParams.getApplyFilter(filterItem);
    }

    const filterValue = `${filterItem.value}`.toLowerCase();

    return (params: GridCellParams): boolean =>
      `${params.value}`.toLowerCase().includes(filterValue);
  },
  InputComponent: FilterText,
  InputComponentProps: componentProps,
});

const genSelectOperator = (
  componentProps: FilterSelectProps,
  operatorParams?: GenOperatorParams
): GridFilterOperator => ({
  label: 'is',
  value: FilterOperatorValue.Is,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    if (operatorParams?.getApplyFilter) {
      return operatorParams.getApplyFilter(filterItem);
    }

    const filterValue = `${filterItem.value}`.toLowerCase();

    return (params: GridCellParams): boolean => `${params.value}`.toLowerCase() === filterValue;
  },
  InputComponent: FilterSelect,
  InputComponentProps: componentProps,
});

const genSearchableSelectOperator = (
  componentProps: FilterSearchSelectProps,
  operatorParams?: GenOperatorParams
): GridFilterOperator => ({
  label: 'is',
  value: FilterOperatorValue.Is,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    if (operatorParams?.getApplyFilter) {
      return operatorParams.getApplyFilter(filterItem);
    }

    const filterValue = `${filterItem.value}`.toLowerCase();

    return (params: GridCellParams): boolean => `${params.value}`.toLowerCase() === filterValue;
  },
  InputComponent: FilterSearchSelect,
  InputComponentProps: componentProps,
});

const genSearchableMultiSelectOperator = (
  componentProps: FilterSearchMultiSelectProps,
  operatorParams?: GenOperatorParams
): GridFilterOperator => ({
  label: 'is any of',
  value: FilterOperatorValue.OneOf,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    if (operatorParams?.getApplyFilter) {
      return operatorParams.getApplyFilter(filterItem);
    }

    return (params: GridCellParams): boolean => params.value.includes(filterItem.value);
  },
  InputComponent: FilterSearchMultiSelect,
  InputComponentProps: componentProps,
});

export const transformFilters = (filters: TableColFilter[]): GridFilterOperator[] =>
  filters.map(filter => {
    const params: GenOperatorParams = {
      getApplyFilter: filter.getApplyFilter,
    };

    switch (filter.type) {
      case 'text':
        return genTextOperator();

      case 'textLike':
        return genTextLikeOperator();

      case 'select':
        return genSearchableSelectOperator(
          {
            filterField: filter.filterField,
            options: filter.options || [],
            groupBy: filter.groupBy,
          },
          params
        );

      case 'multiSelect':
        return genSearchableMultiSelectOperator(
          {
            filterField: filter.filterField,
            options: filter.options || [],
            groupBy: filter.groupBy,
          },
          params
        );

      case 'checkbox':
        return genSelectOperator(
          {
            filterField: filter.filterField,
            noneLabel: 'any',
            displayEmpty: true,
            options: [{ label: 'selected', value: 'true' }],
          },
          params
        );
    }
  });

const OPERATORS_MAP: { [type in TableColType]?: GridFilterOperator[] } = {
  string: [genTextLikeOperator()],
  id: [genTextOperator()],
  number: getGridNumericOperators().filter(({ value }) =>
    [
      NumberFilterOperatorValue.Equals,
      // NumberFilterOperatorValue.Greater,
      // NumberFilterOperatorValue.GreaterAndEquals,
      // NumberFilterOperatorValue.Less,
      // NumberFilterOperatorValue.LessAndEquals,
    ].includes(value as NumberFilterOperatorValue)
  ),
  date: getGridDateOperators().filter(({ value }) =>
    [FilterOperatorValue.Is].includes(value as FilterOperatorValue)
  ),
  boolean: getGridBooleanOperators(),
};

export const transformColumnFilter = (column: TableEnrichedColDef): TableEnrichedColDef => {
  if ((column as TableColDef).filters) {
    const { filters } = column as TableColDef;

    return {
      ...column,
      filterOperators: transformFilters(filters!),
    };
  }

  if (!column.filterOperators) {
    switch (column.type) {
      case 'id':
        return {
          ...column,
          filterOperators: OPERATORS_MAP.id,
        };

      case 'boolean':
      case 'booleanIcon':
        return {
          ...column,
          filterOperators: OPERATORS_MAP.boolean,
        };

      case 'date':
        return {
          ...column,
          filterOperators: OPERATORS_MAP.date,
        };

      case 'number':
      case 'currency':
      case 'percent':
      case 'rating':
        return {
          ...column,
          filterOperators: OPERATORS_MAP.number,
        };

      case 'status':
        return {
          ...column,
          filterOperators: [
            genSearchableSelectOperator({
              options: Object.keys((column as TableStatusColDef).statusMap || {}).map(status => ({
                value: status,
                label: (column as TableStatusColDef).statusTranslationMap?.[status] || status,
              })),
            }),
          ],
        };

      case 'singleSelect':
        return {
          ...column,
          filterOperators: [
            genSearchableSelectOperator({
              options: column.valueOptions as ValueOption[],
            }),
          ],
        };

      case 'multiSelect':
        return {
          ...column,
          filterOperators: [
            genSearchableSelectOperator(
              {
                options: column.valueOptions as ValueOption[],
              },
              {
                getApplyFilter:
                  filterItem =>
                  ({ value }) =>
                    (value || []).some(
                      (val: any) =>
                        (column.valueField ? val[column.valueField] : val) == filterItem.value
                    ),
              }
            ),
          ],
        };

      case 'string':
      default:
        return {
          ...column,
          filterOperators: OPERATORS_MAP.string,
        };
    }
  }

  return column;
};
