// APIS

// HOOKS
import { useData } from 'contexts/dataContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { Box } from '@mui/material';

// CONSTANTS
enum FormCollection {
  new = 'new',
  create = 'create',
  view = 'view',
  edit = 'edit',
}

// MODELS
import FormView from 'components/formViews/FormView';
import DesignCollection from 'models/designCollection';
import MasterCollectionForm from './collectionSections/MasterCollectionForm';
import { fetchMasterCollectionByIdApi } from 'actions/apis/designCollectionApis';

const MasterCollectionDetail = () => {
  const { editable } = useData();
  const [collection, setCollection] = useState<DesignCollection | undefined>();
  const { collectionId } = useParams();

  const loadCollection = async () => {
    try {
      const { data } = await fetchMasterCollectionByIdApi({ id: collectionId });
      if (data?.id) {
        setCollection(new DesignCollection().fromPayload(data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (collectionId) {
      if (collectionId !== 'new') {
        loadCollection();
      } else {
        setCollection(new DesignCollection());
      }
    }
    // eslint-disable-next-line
  }, [collectionId]);

  return (
    <FormView
      title={
        collectionId === FormCollection.new
          ? 'Create New Master Collection'
          : collection?.name || ''
      }
      mode={collectionId === FormCollection.new ? 'create' : 'view'}
      editable={editable}
      cancelUrl="./.."
    >
      <Box padding="2rem 1rem">
        <MasterCollectionForm collection={collection} isDisabled={editable} />
      </Box>
    </FormView>
  );
};

export default MasterCollectionDetail;
