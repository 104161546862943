import { useCallback, useEffect, useState } from 'react';

type UseTriggerRefreshReturn = [show: boolean, triggerRefresh: () => void];

const useTriggerRefresh = (delay = 0): UseTriggerRefreshReturn => {
  const [show, setShow] = useState(true);

  const triggerRefresh = useCallback(() => setShow(false), []);

  useEffect(() => {
    if (!show) {
      if (delay) {
        setTimeout(() => setShow(true), delay);
      } else {
        setShow(true);
      }
    }
  }, [show]);

  return [show, triggerRefresh];
};

export default useTriggerRefresh;
