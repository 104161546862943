import React from 'react';
import styled from 'styled-components';

import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type ButtonShape = 'square'; // default shape is circle

export type IconButtonProps = MuiIconButtonProps & {
  className?: string;
  shape?: ButtonShape;
  enterDelay?: number;
} & StyleProps;

const IconButton = ({
  children,
  className,
  shape,
  title,
  enterDelay = 500,
  ...restProps
}: IconButtonProps) => {
  const classNames: string[] = [];
  if (className) classNames.push(className);
  if (shape) classNames.push(`button-shape-${shape}`);

  if (title) {
    return (
      <Tooltip title={title} enterDelay={enterDelay}>
        <StyledIconButton className={classNames.join(' ')} {...restProps}>
          {children}
        </StyledIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledIconButton className={classNames.join(' ')} {...restProps}>
      {children}
    </StyledIconButton>
  );
};

type StyleProps = {
  bgcolor?: MuiIconButtonProps['color'];
  color?: MuiIconButtonProps['color'];
  width?: number | string;
  height?: number | string;
};

const StyledIconButton = styled(MuiIconButton)<StyleProps>`
  && {
    ${p => p.bgcolor && `background: ${p.theme.palette[p.bgcolor].main};`}

    &:hover {
      ${p =>
        p.bgcolor &&
        `background: ${p.theme.palette[p.bgcolor].mainHover || p.theme.palette[p.bgcolor].main};`}
    }

    &:focus {
      outline: none;
    }
  }

  &.button-shape-square {
    border-radius: 0;
  }
`;

export default IconButton;
