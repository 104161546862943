import styled from 'styled-components';
import { lighten } from 'polished';

// COMPONENTS
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
};

const Button = ({ loading, startIcon, disabled, ...props }: ButtonProps) => (
  <StyledButton
    startIcon={loading ? <CircularProgress size=".8em" /> : startIcon}
    disabled={loading || disabled}
    {...props}
  />
);

Button.defaultProps = {
  variant: 'contained',
};

const StyledButton = styled(MuiButton)`
  && {
    box-shadow: none;
    padding: 0.5rem 1rem;
    text-transform: capitalize;

    &.MuiButton-outlined {
      &Warning {
        color: #eec90b;
        border-color: #eec90b;
        background-color: #fcf7da;
      }

      &Success {
        color: #7cd986;
        border-color: #7cd986;
        background-color: #ebfeed;
      }
    }

    &.Mui-disabled {
      &.MuiButton-containedAccentLight {
        color: ${p => lighten(0.2, p.theme.palette.accentLight.contrastText)};
        background-color: ${p => lighten(0.04, p.theme.palette.accentLight.main)};
      }

      &.MuiButton-containedAccent {
        color: ${p => lighten(0.6, p.theme.palette.accent.contrastText)};
        background-color: ${p => lighten(0.1, p.theme.palette.accent.main)};
      }
    }
  }
`;

export default Button;
