import {
  GridNativeColTypes,
  GridColDef,
  GridActionsColDef,
  GridActionsCellItemProps,
  GridValueGetterParams,
  GridCellValue,
  GridRowParams,
  GridRowModel,
  GridCellParams,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { ChipProps } from '@mui/material/Chip';
import { Any, SelectOption } from 'constants/types';

declare module '@mui/x-data-grid/models/gridFilterItem' {
  interface GridFilterItem {
    filterField?: string;
  }
}

export type TableColFilter = {
  type: 'select' | 'multiSelect' | 'text' | 'textLike' | 'checkbox';
  filterField?: string;
  options?: SelectOption[];
  groupBy?: (option: SelectOption) => string;
  getApplyFilter?: (filterItem: GridFilterItem) => (params: GridCellParams) => boolean;
};

export enum FilterOperatorValue {
  Equals = 'equals',
  Contains = 'contains',
  Is = 'is',
  OneOf = 'oneOf',
  IsEmpty = 'isEmpty',
}

export enum NumberFilterOperatorValue {
  Equals = '=',
  NotEquals = '!=',
  GreaterAndEquals = '>=',
  LessAndEquals = '<=',
  Greater = '>',
  Less = '<',
}

export type TableColType =
  | GridNativeColTypes
  | 'id'
  | 'tooltip'
  | 'booleanIcon'
  | 'percent'
  | 'currency'
  | 'status'
  | 'image'
  | 'imageUrl'
  | 'rating'
  | 'color'
  | 'multiSelect';

export type TableColDef<F extends string = string, R = Any> = Omit<GridColDef, 'field'> & {
  field: F | 'actions';
  type?: TableColType;
  filterField?: string;
  valueField?: string;
  filters?: TableColFilter[];
  valueGetter?: (params: GridValueGetterParams<Any, R>) => GridCellValue;
};

export type TableIdColDef<F extends string = string, R = Any> = TableColDef<F, R> & {
  type: 'id';
  link?: string | false; // id will be replaced to :id, missing will redirect to ./:id
  onClick?: (row: GridRowModel) => void;
};

export type TableStatusColDef<F extends string = string, R = Any> = TableColDef<F, R> & {
  type: 'status';
  statusMap?: { [key: string | number]: ChipProps['color'] };
  statusTranslationMap?: { [key: string | number]: string | number };
};

export type TableTooltipColDef<F extends string = string, R = Any> = TableColDef<F, R> & {
  type: 'tooltip';
  tooltipContent: string;
};

export type TableActionColDef<R = Any> = GridActionsColDef & {
  getActionOptions?: (params: GridRowParams<R>) => GridActionsCellItemProps[];
};

export type TableEnrichedColDef<F extends string = string, R = Any> =
  | TableColDef<F, R>
  | TableIdColDef<F, R>
  | TableStatusColDef<F, R>
  | TableTooltipColDef<F, R>
  | TableActionColDef<R>;
