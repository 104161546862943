import React, { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { GridSortModel } from '@mui/x-data-grid-pro';

// UTILS
import { toApiSortQuery } from 'utils/dataMappers';

// CONSTANTS
import { MetaParams, SearchableParams } from 'constants/requestConstants';
import { Obj, Any, ID } from 'constants/types';
import { FormMode } from 'constants/formConstants';
import { PAGE_SIZE, FIRST_PAGE, SORT_BY_ID } from 'constants/tableConstants';

type DataState<M = Obj> = {
  // PERMISSION
  editable: boolean;
  // LIST state
  itemList: M[];
  searchParams: Obj;
  itemCount: number;
  pageSize: number;
  page: number;
  sortModel: GridSortModel;
  // FORM state
  detailUpdating: M | undefined;
  itemDetail: M;
  formSubmitting: boolean;
  formMode?: FormMode | null | undefined;
};

export type DataActions = {
  // LIST actions
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  setSearchParams: (searchParams: Obj) => void;
  setSearchableParams: (searchableParams: SearchableParams) => void;
  loadItemList: () => void;
  // FORM actions
  loadItemDetail: (id: ID) => Promise<void>;
  resetItemDetail: () => void;
  markFormSubmitting: (submitting: boolean) => void;
  createItem?: (item: Obj) => Promise<Any>;
  updateItem?: (id: ID, item: Any) => Promise<Any>;
  deleteItem?: (id: ID) => Promise<Any>;
  setDetailUpdating?: (data: Obj | undefined) => void;
};

const DataContext = createContext<DataState & DataActions>({
  editable: true,
  itemList: [],
  itemDetail: {},
  searchParams: {},
  itemCount: 0,
  pageSize: PAGE_SIZE,
  page: FIRST_PAGE,
  sortModel: [],
  formSubmitting: false,
  detailUpdating: {},
  setPage: () => {},
  setPageSize: () => {},
  setSortModel: () => {},
  setSearchParams: () => {},
  setSearchableParams: () => {},
  markFormSubmitting: () => {},
  loadItemList: () => {},
  loadItemDetail: async () => {},
  resetItemDetail: () => {},
  setDetailUpdating: () => {},
});

type Props = React.PropsWithChildren<{
  editable?: boolean;
  pageSize?: number;
  fetchItemList?: (
    filter?: Obj,
    meta?: MetaParams,
    searchableParams?: SearchableParams
  ) => Promise<{ data: Obj[]; metaData?: { count?: number } } | undefined>;
  fetchItemById?: (id: ID) => Promise<{ data: Obj }>;
  createItem?: DataActions['createItem'];
  updateItem?: DataActions['updateItem'];
  deleteItem?: DataActions['deleteItem'];
  setDetailUpdating?(data: Obj): void;
}>;

export const DataProvider = ({
  editable = true,
  children,
  fetchItemList,
  fetchItemById,
  createItem,
  updateItem,
  deleteItem,
}: Props) => {
  // For list
  const [itemList, setItemList] = useState<Obj[]>([]);
  const [itemCount, setItemCount] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [searchableParams, setSearchableParams] = useState<SearchableParams | undefined>();
  const [page, setPage] = useState(FIRST_PAGE);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [sortModel, setSortModel] = useState<GridSortModel>(SORT_BY_ID);
  const [formSubmitting, setFormSubmitting] = useState(false);

  // For create / update / view
  const [itemDetail, setItemDetail] = useState<Obj>({});
  const [detailUpdating, setUpdating] = useState<Obj | undefined>({});
  const params = useParams();

  const setDetailUpdating = (data: Obj | undefined) => {
    setUpdating(data);
  };

  const setSearchableParamsCallback = (newSearchableParams: SearchableParams) => {
    if (!isEqual(newSearchableParams, searchableParams)) {
      setSearchableParams(newSearchableParams);
    }
  };

  const formMode = useMemo<FormMode | null>(() => {
    const { id } = params;
    // if (id === 'new') return 'create';
    if (id) return 'edit';
    return null;
  }, [params]);

  const markFormSubmitting = useCallback(
    (submitting: boolean) => {
      if (submitting !== formSubmitting) {
        setFormSubmitting(submitting);
      }
    },
    [formSubmitting]
  );

  const loadItemList = useCallback<DataActions['loadItemList']>(async () => {
    if (!fetchItemList) return;

    const apiSortQuery = toApiSortQuery(sortModel);
    const rs = await fetchItemList(
      searchParams,
      { page, size: pageSize, sort: apiSortQuery },
      searchableParams
    );

    if (rs) {
      const { data, metaData } = rs;
      setItemList(data);
      if (metaData?.count) {
        setItemCount(metaData.count);
      }
    }
  }, [fetchItemList, page, pageSize, searchParams, sortModel, searchableParams]);

  const resetItemDetail = useCallback(() => setItemDetail({}), []);

  const loadItemDetail = useCallback<DataActions['loadItemDetail']>(
    async id => {
      if (fetchItemById) {
        try {
          const { data } = await fetchItemById(id);
          setItemDetail(data);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [fetchItemById]
  );

  useEffect(() => {
    (async () => {
      try {
        if (params?.id && params.id !== 'new') {
          await loadItemDetail(params?.id);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [params?.id, loadItemDetail]);

  return (
    <DataContext.Provider
      value={{
        editable,
        itemList,
        itemCount,
        itemDetail,
        pageSize,
        setPageSize,
        sortModel,
        setSortModel,
        searchParams,
        setSearchParams,
        setSearchableParams: setSearchableParamsCallback,
        page,
        formSubmitting,
        detailUpdating,
        setPage,
        loadItemList,
        loadItemDetail,
        resetItemDetail,
        markFormSubmitting,
        createItem,
        updateItem,
        deleteItem,
        setDetailUpdating,
        formMode,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);

export default DataContext;
