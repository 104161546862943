// COMPONENTS
import { VideoCard } from 'material-ui-player';
import Dialog from 'components/dialogs/Dialog';
import Image from 'components/images/Image';

// UTILS
import {
  extractFileNameFromUrl,
  extractFileExtensionFromDataUri,
  getFileType,
  isDataUri,
} from 'utils/uriUtils';

type Props = {
  title?: string;
  src?: string;
  onClose: () => void;
};

const ViewMediaDialog = ({ title, src, onClose }: Props) => {
  let fileName = '';
  let fileType = '';
  if (src) {
    if (isDataUri(src)) {
      const fileExt = extractFileExtensionFromDataUri(src);
      fileType = getFileType(`f.${fileExt}`);
      if (fileType === 'image') {
        fileName = 'Image';
      } else if (fileType === 'video') {
        fileName = 'Video';
      } else {
        fileName = 'File';
      }
    } else {
      fileName = extractFileNameFromUrl(src);
      if (fileName) {
        fileType = getFileType(fileName);
      }
    }
  }

  return (
    <Dialog
      title={title || fileName}
      maxWidth={fileType === 'video' ? 'lg' : 'md'}
      open={!!src}
      onClose={onClose}
    >
      {src && fileType && (
        <>
          {fileType === 'image' && <Image src={src} bgColor="warning.dark" duration={0} />}
          {fileType === 'video' && <VideoCard src={src} autoplay />}
        </>
      )}
      {src && !fileType && <Image src={src || ''} bgColor="warning.dark" duration={0} />}
      {/* <Image src={src || ''} bgColor="warning.dark" duration={0} /> */}
    </Dialog>
  );
};

export default ViewMediaDialog;
