import { useCallback, useEffect, useReducer } from 'react';

// UTILS
import { toOptions } from 'utils/dataMappers';

// CONSTANTS
import { Obj, Any, SelectOption } from 'constants/types';

type BaseFilterProps = {
  name: string;
  onValueChange?: (value: unknown) => void;
};

type TextFilterProps = BaseFilterProps;

type SelectFilterProps = BaseFilterProps & {
  options: SelectOption[];
};

type FilterProps = TextFilterProps | SelectFilterProps;

type FilterType = 'text' | 'select';

type BaseFilterParams = {
  name: string;
  defaultValue?: Any;
  type?: FilterType;
};

type TextFilterParams = BaseFilterParams;

type SelectFilterParams = TextFilterParams & {
  type: 'select';
  options?: SelectOption[];
};

type FilterParam = TextFilterParams | SelectFilterParams;

type FiltersState = {
  filterValues: Obj;
  defaultFilterValues: Obj;
  filtersProps: Record<string, FilterProps>;
};

type ChangeValueAction = {
  type: 'changeFilterValue';
  field: string;
  value: Any;
};

type ResetValuesAction = {
  type: 'resetFilterValues';
};

type ChangeOptionsAction = {
  type: 'changeOptions';
  field: string;
  options: SelectOption[];
};

type InitFiltersActions = {
  type: 'initFilters';
  filterParams: FilterParam[];
  changeFilterValue: (field: string, value: Any) => void;
};

type FilterAction =
  | ChangeValueAction
  | ResetValuesAction
  | ChangeOptionsAction
  | InitFiltersActions;

const initialState: FiltersState = {
  filterValues: {},
  defaultFilterValues: {},
  filtersProps: {},
};

const reducer = (state: FiltersState, action: FilterAction) => {
  switch (action.type) {
    case 'changeFilterValue': {
      const { field, value } = action;
      const nextValues = { ...state.filterValues };
      nextValues[field] = value;

      return {
        ...state,
        filterValues: nextValues,
      };
    }

    case 'resetFilterValues':
      return {
        ...state,
        filterValues: {
          ...state.defaultFilterValues,
        },
      };

    case 'changeOptions': {
      const { field, options } = action;

      const { options: stateOptions } = state.filtersProps[field] as SelectFilterProps;

      if (stateOptions === undefined) {
        return state;
      }

      return {
        ...state,
        filtersProps: {
          ...state.filtersProps,
          [field]: {
            ...state.filtersProps[field],
            options,
          },
        },
      };
    }

    case 'initFilters': {
      const { filterParams, changeFilterValue } = action;

      let nextFilterProps: typeof state.filtersProps = {};
      let nextFilterValues: typeof state.filterValues = {};

      filterParams.forEach(params => {
        switch (params.type) {
          case 'select': {
            const { options } = params as SelectFilterParams;
            nextFilterProps[params.name] = {
              name: params.name,
              options: options || [],
              onValueChange: value => changeFilterValue(params.name, value),
            } as SelectFilterProps;
            break;
          }

          default:
            nextFilterProps[params.name] = {
              name: params.name,
              onValueChange: value => changeFilterValue(params.name, value),
            } as TextFilterProps;
        }

        if (params.defaultValue !== undefined) {
          nextFilterValues[params.name] = params.defaultValue;
        }
      });

      return {
        ...state,
        filtersProps: nextFilterProps,
        filterValues: nextFilterValues,
        defaultFilterValues: nextFilterValues,
      };
    }

    default:
      return state;
  }
};

const useFilters = (filterParams: FilterParam[]) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeFilterValue = useCallback(
    (field: string, value: Any) =>
      dispatch({
        type: 'changeFilterValue',
        field,
        value,
      }),
    []
  );

  const resetFilterValues = useCallback(() => dispatch({ type: 'resetFilterValues' }), []);

  const changeOptions = useCallback((field: string, options: SelectOption[]) => {
    dispatch({
      type: 'changeOptions',
      field,
      options,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'initFilters',
      filterParams,
      changeFilterValue,
    });
    // eslint-disable-next-line
  }, []);

  return { changeFilterValue, resetFilterValues, changeOptions, toOptions, ...state };
};

export default useFilters;
