// UTILS
import { parseMetaParams, parseNestedObjectParams } from 'utils/queryParamsUtils';

// CONSTANTS
import {
  DetailResponsePromise,
  FetchListFunc,
  ListResponsePromise,
  MetaParams,
  SearchableParams,
} from 'constants/requestConstants';
import { Obj } from 'constants/types';
import { pmsRequest, pmsRequestV3, pmsRequestV3Origin } from '../../apis/base';

export type FetchCollectionParams = {
  id?: string;
  name?: string;
  tag?: string;
  isSignature?: boolean;
  code?: string;
  layoutId?: string;
  backgroundModuleImageUri?: string;
  thumbnailImageUri?: string;
  description?: string;
  descriptionTitle?: string;
};

// V2
export const fetchDesignCollectionsApi: FetchListFunc<{}> = (params, meta) =>
  pmsRequest.get('/personality-properties', { params: { ...params, ...parseMetaParams(meta) } });

export const fetchDesignCollectionByIdApi = (id: number | string): DetailResponsePromise<Obj> =>
  pmsRequest.get(`/personality-properties/${id}`);

export const createDesignCollectionApi = (params: Obj) =>
  pmsRequest.post('/personality-properties', params);

// V3
export const updateDesignCollectionByIdApi = (id: number, params: Obj) =>
  pmsRequestV3.put(`/personality-properties/${id}`, params);

export const fetchMasterCollectionsByApi = (
  searchableParams: SearchableParams,
  { sort, ...meta }: MetaParams = {}
): ListResponsePromise<Obj> =>
  pmsRequestV3Origin.get(`/personality-properties`, {
    params: {
      ...parseNestedObjectParams(searchableParams),
      ...parseMetaParams(meta),
    },
  });

export const fetchMasterCollectionCreateByApi = async ({ ...params }: FetchCollectionParams) =>
  pmsRequestV3.post(`/personality-properties/create`, params);

export const fetchMasterCollectionByIdApi = async ({ id }: FetchCollectionParams) =>
  pmsRequestV3.get(`/personality-properties/${id}`);

export const fetchDesignCollectionUpdateByIdApi = async ({
  id,
  ...params
}: FetchCollectionParams) => pmsRequestV3.put(`/personality-properties/${id}`, params);

export const fetchMasterCollectionSoftDeleteByApi = async ({ id }: FetchCollectionParams) =>
  pmsRequestV3.put(`/personality-properties/soft-delete/${id}`);

export const fetchMasterCollectionApplyByApi = async ({ id }: FetchCollectionParams) =>
  pmsRequestV3.post(`/personality-properties/apply/${id}`);

export const fetchMasterCollectionDefaultByApi = async ({ id }: FetchCollectionParams) =>
  pmsRequestV3.post(`/personality-properties/make-default/${id}`);
