import moment from 'moment';

type NowDateTimeType = 'veryShortDateTime';

const NOW_TYPE_MAP: Record<NowDateTimeType, string> = {
  veryShortDateTime: 'YYYYMMDDHHmmSS',
};

export const now = (format: NowDateTimeType | string = 'veryShortDateTime') => {
  const formatStr = NOW_TYPE_MAP[format as NowDateTimeType] || format;
  return moment().utc().format(formatStr);
};
