import { useCallback, useMemo, useState } from 'react';
import { Obj } from 'constants/types';

const useDialog = <S = Obj>() => {
  const [state, setState] = useState<{ open: boolean; dialogState?: S }>({ open: false });

  const openDialog: (newState?: S | undefined) => void = useCallback(
    newState => setState(newState ? { open: true, dialogState: newState } : { open: true }),
    []
  );

  const closeDialog: () => void = useCallback(() => setState({ open: false }), []);

  const dialogProps = useMemo(
    () => ({
      open: state.open,
      onClose: () => closeDialog(),
    }),
    [closeDialog, state.open]
  );

  return {
    dialogProps,
    openDialog,
    closeDialog,
    dialogState: state.dialogState,
  };
};

export default useDialog;
