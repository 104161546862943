import React, { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

// COMPONENTS
import AuthLayout from 'components/layouts/AuthLayout';
import MainLayout from 'components/layouts/MainLayout';

// CONTEXTS
import { AuthProvider } from 'contexts/authContext';
import { GlobalProvider } from 'contexts/globalContext';

// ASSETS
import './App.scss';

// PAGES
const LoginPage = lazy(() => import('pages/auth/Login'));
const ForgotPasswordPage = lazy(() => import('pages/auth/ForgotPassword'));
const SetNewPassword = lazy(() => import('pages/auth/SetNewPassword'));
const NotFound = lazy(() => import('pages/errors/NotFound'));
const HomePage = lazy(() => import('pages/home/Home'));

// PROJECTS
const Projects = lazy(() => import('pages/projects/Projects'));
const ProjectList = lazy(() => import('pages/projects/ProjectList'));
const NewProject = lazy(() => import('pages/projects/NewProject'));
const ProjectDetail = lazy(() => import('pages/projects/ProjectDetail'));
const BlockFloor = lazy(() => import('pages/projects/blockFloors/BlockFloor'));
const SegmentDetails = lazy(() => import('pages/projects/segments/SegmentDetails'));
const LayoutDetail = lazy(() => import('pages/projects/layouts/EditLayoutForm'));
const PlanDetail = lazy(() => import('pages/projects/floorPlans/EditFloorPlanForm'));
const HomeNumberDetail = lazy(() => import('pages/projects/homeNumbers/EditHomeNumberForm'));
const OrderDetail = lazy(() => import('pages/projects/order/OrderDetail'));
const ProductListDetails = lazy(() => import('pages/projects/productLists/ProductListDetails'));
const ProjectTranslationDetail = lazy(
  () => import('pages/projects/translation/ProjectTranslationDetail')
);

const SegmentPriceViewDetail = lazy(
  () => import('pages/projects/priceView/SegmentPriceViewDetail')
);

// ARCHITECTS
const Architects = lazy(() => import('pages/architects/Architects'));
const ArchitectList = lazy(() => import('pages/architects/ArchitectList'));

// BANKS
const Banks = lazy(() => import('pages/banks/Banks'));
const BankList = lazy(() => import('pages/banks/BankList'));

// MORTGAGES
const Mortgages = lazy(() => import('pages/mortgages/Mortgages'));
const MortgageList = lazy(() => import('pages/mortgages/MortgageList'));
const SystemTranslationManagement = lazy(
  () => import('pages/translation/SystemTranslationManagement')
);

// DESIGN HUBS
const DesignHub = lazy(() => import('pages/designHubs/DesignHub'));
const DesignHubList = lazy(() => import('pages/designHubs/DesignHubList'));
const DesignHubCreate = lazy(() => import('pages/designHubs/DesignHubCreate'));
const DesignHubDetail = lazy(() => import('pages/designHubs/DesignHubDetail'));
const SystemTranslationDetail = lazy(() => import('pages/translation/SystemTranslationDetail'));
const AgentManagement = lazy(() => import('pages/agents/AgentList'));
const AgentDetail = lazy(() => import('pages/agents/AgentDetail'));

// USERS
const Users = lazy(() => import('pages/users/Users'));
const UserList = lazy(() => import('pages/users/UserList'));
const UserDetail = lazy(() => import('pages/users/UserDetail'));

// COUNTRIES
const Countries = lazy(() => import('pages/countries/Countries'));
const CountriesList = lazy(() => import('pages/countries/CountriesList'));

// WHITE LABEL
const WhiteLabel = lazy(() => import('pages/whiteLabel/WhiteLabel'));
const WhiteLabelDetail = lazy(() => import('pages/whiteLabel/WhiteLabelDetail'));

// MASTER LAYOUT
const MasterLayout = lazy(() => import('pages/masterLayouts/MasterLayout'));
const MasterLayoutList = lazy(() => import('pages/masterLayouts/MasterLayoutList'));
const MasterLayoutDetail = lazy(() => import('pages/masterLayouts/MasterLayoutDetail'));

// MASTER LAYOUT
const MasterProduct = lazy(() => import('pages/masterProducts/MasterProduct'));
const MasterProductList = lazy(() => import('pages/masterProducts/MasterProductList'));
const MasterProductDetail = lazy(() => import('pages/masterProducts/MasterProductDetail'));

// OurStory
const OurStory = lazy(() => import('pages/landingPages/OurStory'));
const OurStoryDetail = lazy(() => import('pages/landingPages/OurStoryDetail'));

// InTheSpotLight
const InTheSpotlight = lazy(() => import('pages/landingPages/InTheSpotlight'));
const InTheSpotlightDetail = lazy(() => import('pages/landingPages/InTheSpotlightDetail'));

// OdooSyncLog
const OdooSyncLog = lazy(() => import('pages/odooSyncLog/OdooSyncLog'));
const OdooSyncLogList = lazy(() => import('pages/odooSyncLog/OdooSyncLogList'));

// BoqCalculationLog
const BoqCalculatitonLog = lazy(() => import('pages/boqCalculationLog/BoqCalculatitonLog'));
const BoqCalculatitonLogList = lazy(() => import('pages/boqCalculationLog/BoqCalculatitonLogList'));
const BoqCalculatitonLogDetail = lazy(
  () => import('pages/boqCalculationLog/BoqCalculatitonLogDetail')
);

// CONSTANTS
import { ProjectTabKey } from 'constants/routeConstants';
import MasterCollection from 'pages/masterCollections/MasterCollection';
import MasterCollectionList from 'pages/masterCollections/MasterCollectionList';
import MasterCollectionDetail from 'pages/masterCollections/MasterCollectionDetail';

const appRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      { index: true, element: <LoginPage /> },
      { path: 'forgot-password', element: <ForgotPasswordPage /> },
      { path: 'reset-password', element: <SetNewPassword /> },
    ],
  },
  {
    path: '/',
    element: (
      <AuthProvider>
        <GlobalProvider>
          <MainLayout />
        </GlobalProvider>
      </AuthProvider>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'projects',
        element: <Projects />,
        children: [
          { index: true, element: <ProjectList /> },
          { path: 'new', element: <NewProject /> },
          {
            path: ':id',
            children: [
              { path: `${ProjectTabKey.Block}/:blockId`, element: <BlockFloor /> },
              { path: `${ProjectTabKey.Segment}/:floorPlanId`, element: <SegmentDetails /> },
              { path: `${ProjectTabKey.Layout}/:layoutId`, element: <LayoutDetail /> },
              { path: `${ProjectTabKey.Order}/:orderId`, element: <OrderDetail /> },
              { path: `${ProjectTabKey.FloorPlan}/:planId`, element: <PlanDetail /> },
              { path: `${ProjectTabKey.HomeNumber}/:homeNumberId`, element: <HomeNumberDetail /> },
              {
                path: `${ProjectTabKey.ProductList}/:productListId`,
                element: <ProductListDetails />,
              },
              {
                path: `${ProjectTabKey.PriceView}/:segmentId`,
                element: <SegmentPriceViewDetail />,
              },
              {
                path: `${ProjectTabKey.Translation}/:translationId`,
                element: <ProjectTranslationDetail />,
              },
              { path: ':tab', element: <ProjectDetail /> },
              { index: true, element: <ProjectDetail /> },
            ],
          },
        ],
      },
      {
        path: 'architects',
        element: <Architects />,
        children: [{ index: true, element: <ArchitectList /> }],
      },
      {
        path: 'banks',
        element: <Banks />,
        children: [{ index: true, element: <BankList /> }],
      },
      {
        path: 'mortgages',
        element: <Mortgages />,
        children: [{ index: true, element: <MortgageList /> }],
      },
      {
        path: 'languages',
        children: [
          { index: true, element: <SystemTranslationManagement /> },
          { path: ':id', element: <SystemTranslationDetail /> },
        ],
      },
      {
        path: 'users',
        element: <Users />,
        children: [
          { index: true, element: <UserList /> },
          { path: ':id', element: <UserDetail /> },
        ],
      },
      {
        path: 'agents',
        children: [
          { index: true, element: <AgentManagement /> },
          { path: ':id', element: <AgentDetail /> },
        ],
      },
      {
        path: 'countries',
        element: <Countries />,
        children: [{ index: true, element: <CountriesList /> }],
      },
      {
        path: 'design-hub',
        element: <DesignHub />,
        children: [
          { index: true, element: <DesignHubList /> },
          { path: 'new', element: <DesignHubCreate /> },
          { path: ':id', element: <DesignHubDetail /> },
        ],
      },
      {
        path: 'white-label',
        element: <WhiteLabel />,
        children: [{ index: true, element: <WhiteLabelDetail /> }],
      },
      {
        path: 'master-layout',
        element: <MasterLayout />,
        children: [
          { index: true, element: <MasterLayoutList /> },
          { path: ':layoutId', element: <MasterLayoutDetail /> },
        ],
      },
      {
        path: 'master-collection',
        element: <MasterCollection />,
        children: [
          { index: true, element: <MasterCollectionList /> },
          { path: ':collectionId', element: <MasterCollectionDetail /> },
        ],
      },
      {
        path: 'master-product',
        element: <MasterProduct />,
        children: [
          { index: true, element: <MasterProductList /> },
          { path: ':productListId', element: <MasterProductDetail /> },
        ],
      },
      {
        path: 'our-story',
        element: <OurStory />,
        children: [{ index: true, element: <OurStoryDetail /> }],
      },
      {
        path: 'in-the-spotlight',
        element: <InTheSpotlight />,
        children: [{ index: true, element: <InTheSpotlightDetail /> }],
      },
      {
        path: 'sync-log',
        element: <OdooSyncLog />,
        children: [{ index: true, element: <OdooSyncLogList /> }],
      },
      {
        path: 'boq-log',
        element: <BoqCalculatitonLog />,
        children: [
          { index: true, element: <BoqCalculatitonLogList /> },
          { path: ':id', element: <BoqCalculatitonLogDetail /> },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={<>...</>}>
        <Outlet />
      </Suspense>
    ),
    children: appRoutes,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
