import { Controller, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

// COMPONENTS
import InputTextField, {
  TextFieldProps as InputTextFieldProps,
  InputProps,
} from 'components/inputs/TextField';

// CONTEXTS
import { useFormContext } from './Form';

export type TextFieldProps = InputTextFieldProps & {
  name: string;
  control?: UseControllerProps['control'];
  defaultValue?: string | number | boolean;
  messageErr?: string;
} & InputProps;

const TextField = ({ id, control, defaultValue, name, disabled, ...rest }: TextFieldProps) => {
  const { formControl, formDisabled } = useFormContext();
  return (
    <Controller
      name={name}
      control={control || formControl}
      defaultValue={defaultValue}
      render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
        <StyledTextField
          id={id || name}
          value={value ?? ''}
          onChange={onChange}
          error={!!error}
          helperText={!!error ? error.message : null}
          disabled={disabled || formDisabled}
          {...rest}
        />
      )}
    />
  );
};

TextField.defaultProps = {
  fullWidth: true,
};

const StyledTextField = styled(InputTextField)`
  // For multiline TextFields
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .MuiOutlinedInput-input {
    &::placeholder {
      font-size: 13px;
    }
  }
`;

export default TextField;
